import request from "@/requests/request";

export const getMetrics = ({
  signal,
}: {
  signal: IGenericHookOptions["signal"];
}): Promise<IMetricsRS> =>
  request({
    url: `/v1/admin/metrics?page=1&pageSize=50`,
    method: "GET",
    signal,
    timeout: 10000,
  }).then(res => res.data);

export const getMetric = ({
  params,
  signal,
}: {
  params: IMetricRQ;
  signal: IGenericHookOptions["signal"];
}): Promise<IMetricRS> =>
  request({
    url: !params?.customerId
      ? `/v1/admin/metrics/drilldown/${params?.metric}`
      : `/v1/admin/${params?.customerId}/metrics/drilldown/${params?.metric}`,
    params: {
      page: params?.page,
      pageSize: params?.pageSize,
      sortBy: params?.sortBy,
      sortOrder: params?.sortOrder,
    },
    method: "GET",
    signal,
    timeout: 10000,
  }).then(res => res.data);
