import { Chip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";

const RenderStatus = (params: GridRenderCellParams) => {
  const { value } = params;

  const getChipColor = (): IColorOptions => {
    switch (value as ISubscriptionStatus) {
      case "ACTIVE":
        return "success";
      case "PENDING":
        return "info";
      case "EXPIRED":
        return "warning";
      default:
        return "error";
    }
  };

  return <Chip label={value} color={getChipColor()} />;
};

export default RenderStatus;
