import { appConfig, appEnvironments } from "@/config";

export const getAppConfig = (): IAppEnvironmentConfig => {
  const environment = getCurrentEnvironment();
  const config: IAppConfig = appConfig;

  return config[environment];
};

export const getCurrentEnvironment = (): IAppEnvironments => {
  const environmentMapping = appEnvironments;
  const currentHostname = window.location.hostname;

  return environmentMapping[currentHostname] || "dev";
};
