import { Grid } from "@mui/material";
import { StatTile } from "@/components/Tiles";
import { ErrorJoke } from "@/components/Error";
import { PageLoader } from "@/components/Loaders";
import { useMetrics } from "@/requests/hooks/metrics.ts";
import { generateUuid } from "@/utils/helpers.ts";

const TopLevelStatsGrid = () => {
  const {
    data: metricsData,
    isLoading: isMetricsLoading,
    isError: isMetricsError,
    error: metricsError,
  } = useMetrics();

  const renderStatGrid = () =>
    metricsData?.results?.map(stat => (
      <Grid key={generateUuid()} item sm={12} md={6} lg={4}>
        <StatTile stat={stat} />
      </Grid>
    ));

  const renderError = () => <ErrorJoke error={metricsError} />;

  const renderGrid = () => (
    <Grid container spacing={3} direction="row">
      {renderStatGrid()}
    </Grid>
  );

  const renderContent = () =>
    isMetricsLoading ? (
      <PageLoader title="stats" />
    ) : isMetricsError ? (
      renderError()
    ) : (
      renderGrid()
    );

  return renderContent();
};

export default TopLevelStatsGrid;
