interface IMapping {
  [key: string]: string;
}

export const translateEnumToPrettyPrint = (string: string): string => {
  const mapping: IMapping = {
    BRANDED: "Branded",
    NON_BRANDED: "Non-branded",
    DOUBLE_OPT_IN: "Double Opt-in",
    SINGLE_OPT_IN: "Single Opt-in",
  };

  return mapping[string] || string;
};
