import { getMetric, getMetrics } from "@/requests/api/metrics";
import { useQuery } from "@tanstack/react-query";

export const useMetrics = () =>
  useQuery({
    queryKey: ["metrics", "overview"],
    queryFn: ({ signal }) => getMetrics({ signal }),
  });

export const useMetric = (params: IMetricRQ) =>
  useQuery({
    queryKey: ["metrics", "drilldown", JSON.stringify(params)],
    queryFn: ({ signal }) => getMetric({ params, signal }),
  });
