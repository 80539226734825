import {
  useCustomer,
  usePostDelinquentStatus,
} from "@/requests/hooks/customer";
import { CheckCircleTwoTone, WarningTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface IProps {
  open: boolean;
  onClose: () => void;
  customerId: string;
  delinquentStatus?: IPreference;
}

const DelinquentModal = ({
  open,
  onClose,
  customerId,
  delinquentStatus,
}: IProps) => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;
  const { refetch, data: customerData } = useCustomer(id);
  const {
    mutate: mutatePostDelinquentStatus,
    isError: isPostDelinquentError,
    isSuccess: isPostDelinquentSuccess,
  } = usePostDelinquentStatus();

  const delinquent = delinquentStatus?.booleanValue;
  const version = delinquentStatus?.version!;
  const companyName = customerData?.companyName;

  useEffect(() => {
    if (isPostDelinquentSuccess) {
      toast("Successfully updated the delinquent status", { type: "success" });
      onClose();
      refetch();
    }

    if (isPostDelinquentError) {
      toast("Failed to update delinquent status", { type: "error" });
    }
  }, [isPostDelinquentError, isPostDelinquentSuccess]);

  const updateDelinquentStatus = () => {
    mutatePostDelinquentStatus({
      customerId,
      body: {
        delinquent: !delinquent,
        version,
      },
    });
  };
  const renderDialogTitle = () => (
    <DialogTitle>Update Customer Delinquent Status</DialogTitle>
  );

  const renderDialogDescription = () => (
    <>
      <DialogContentText mb={2} display="flex" gap={2}>
        <WarningTwoTone color="warning" />
        <b>
          {delinquent
            ? `By clicking confirm you are restoring full access to the Control Panel and restarting the sync to Klaviyo for ${companyName}'s contacts.`
            : `By clicking confirm you are restricting access to the Control Panel as well as stopping the sync to Klaviyo for ${companyName}s contacts.`}
        </b>
      </DialogContentText>
      <DialogContentText>
        Clicking cancel will exit without any changes being made.
      </DialogContentText>
    </>
  );

  const renderDialogButtons = () => (
    <DialogActions>
      <Button color="error" onClick={onClose}>
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        color="success"
        loading={false}
        loadingPosition="start"
        startIcon={<CheckCircleTwoTone />}
        type="submit"
        onClick={updateDelinquentStatus}>
        Mark as {delinquent ? "Engaged" : "Delinquent"}
      </LoadingButton>
    </DialogActions>
  );

  return (
    <Dialog open={open} onClose={onClose}>
      {renderDialogTitle()}
      <DialogContent>{renderDialogDescription()}</DialogContent>
      {renderDialogButtons()}
    </Dialog>
  );
};

export default DelinquentModal;
