import {
  useCreateOrUpdateAbTest,
  useStartAbTest,
  useStopAbTest,
} from "@/requests/hooks/abTest";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PlayCircleTwoTone,
  SaveTwoTone,
  StopCircleTwoTone,
  WarningTwoTone,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Stack,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Alert,
  Tooltip,
  Autocomplete,
  Chip,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { array, boolean, InferType, mixed, object, string } from "yup";

dayjs.extend(relativeTime);

interface IProps {
  data: IGetLatestAbTestRS;
  refetchLatest: () => void;
  isRefetching: boolean;
  countries: ICountriesRS;
}

const createAbTestConfigForm = object({
  abTestType: mixed<IAbTestType>()
    .oneOf(["OPT_IN", "OPT_IN_PRE_TICKED", "OPT_IN_PRE_TICKED_SELECTION"])
    .required()
    .label("Existing tickbox setup"),
  preTickedCountries: array().nullable(),
  widgetConfigText: string().required().label("Consent collection wording"),
  listType: mixed<IKlaviyoListType>()
    .oneOf(["SINGLE_OPT_IN", "DOUBLE_OPT_IN"])
    .required()
    .label("List Type"),
  privacyPolicyLinkPresent: boolean()
    .required()
    .label("Privacy Policy Link Present"),
});

export type ICreateAbTestConfigForm = InferType<typeof createAbTestConfigForm>;

const AbTestForm = ({
  data,
  refetchLatest,
  isRefetching,
  countries,
}: IProps) => {
  const queryClient = useQueryClient();

  const { id: customerId } = useParams<
    keyof ICustomerPageParams
  >() as ICustomerPageParams;

  const {
    id,
    active,
    widgetConfigText,
    abTestType,
    preTickedCountries,
    listType,
    privacyPolicyLinkPresent,
    startDate,
    endDate,
    version,
  } = data;

  const {
    mutate: createOrUpdateAbTest,
    data: createOrUpdateAbTestData,
    isPending: isCreateOrUpdateAbTestPending,
    isSuccess: isCreateOrUpdateAbTestSuccess,
    isError: isCreateOrUpdateAbTestError,
  } = useCreateOrUpdateAbTest();

  const {
    mutate: startAbTest,
    isPending: isStartAbTestPending,
    isSuccess: isStartAbTestSuccess,
    isError: isStartAbTestError,
  } = useStartAbTest();

  const {
    mutate: stopAbTest,
    isPending: isStopAbTestPending,
    isSuccess: isStopAbTestSuccess,
    isError: isStopAbTestError,
  } = useStopAbTest();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty: formHasBeenUpdatedWithoutSave },
    watch,
  } = useForm<ICreateAbTestConfigForm>({
    defaultValues: {
      abTestType: abTestType || "OPT_IN",
      preTickedCountries: preTickedCountries || [],
      widgetConfigText: widgetConfigText || "",
      listType: listType || "DOUBLE_OPT_IN",
      privacyPolicyLinkPresent: privacyPolicyLinkPresent || false,
    },
    resolver: yupResolver(createAbTestConfigForm),
  });

  const clearAllAbTestCache = () => {
    /**
     * Clear the "all A/B tests" cache so it's refetched if it's
     * already been loaded
     */
    queryClient.removeQueries({
      queryKey: ["ab-test", customerId, "all"],
    });
  };

  useEffect(() => {
    if (isCreateOrUpdateAbTestSuccess) {
      toast.success(`${!!version ? "Updated" : "Created"} A/B test`);
      toast.info("This has not started the A/B test");
      refetchLatest();
      clearAllAbTestCache();
    }

    if (isCreateOrUpdateAbTestError) {
      toast.error("Failed to create A/B test");
    }
  }, [isCreateOrUpdateAbTestSuccess, isCreateOrUpdateAbTestError]);

  useEffect(() => {
    /**
     * When we reset on save, we can use the returned values to populate
     * the defaultValues of the form again. This allows us to use the isDirty
     * property from the form to check if changes have been made but not
     * saved (so we can disable the start button)
     */
    reset({
      abTestType: createOrUpdateAbTestData?.abTestType,
      widgetConfigText: createOrUpdateAbTestData?.widgetConfig?.text,
      listType: createOrUpdateAbTestData?.klaviyoListType,
      privacyPolicyLinkPresent:
        createOrUpdateAbTestData?.privacyPolicyLinkPresent,
      preTickedCountries: createOrUpdateAbTestData?.preTickedCountries,
    });
  }, [createOrUpdateAbTestData]);

  useEffect(() => {
    if (isStartAbTestSuccess) {
      toast.success("Started A/B test");
      refetchLatest();
      clearAllAbTestCache();
    }

    if (isStartAbTestError) {
      toast.error("Failed to start A/B test");
    }
  }, [isStartAbTestSuccess, isStartAbTestError]);

  useEffect(() => {
    if (isStopAbTestSuccess) {
      toast.success("Stopped A/B test");
      refetchLatest();
      clearAllAbTestCache();
    }

    if (isStopAbTestError) {
      toast.error("Failed to stop A/B test");
    }
  }, [isStopAbTestSuccess, isStopAbTestError]);

  const submitForm = (data: ICreateAbTestConfigForm) => {
    const preTickedCountriesData: string[] =
      data?.abTestType === "OPT_IN_PRE_TICKED_SELECTION"
        ? data?.preTickedCountries?.map(country => country.alpha2) || []
        : [];

    createOrUpdateAbTest({
      customerId,
      id,
      version: !!endDate ? undefined : version,
      listType: data?.listType,
      widgetConfigText: data?.widgetConfigText,
      abTestType: data?.abTestType,
      preTickedCountries: preTickedCountriesData,
      privacyPolicyLinkPresent: data?.privacyPolicyLinkPresent,
    });
  };

  const startTest = () => startAbTest({ abTestId: id!, version: version! });

  const stopTest = () => stopAbTest({ abTestId: id!, version: version! });

  const getLatestAbTestStatus = () => {
    const today = dayjs();
    const parsedStartDate = dayjs(startDate);
    if (active) {
      return (
        <Tooltip
          title={`Start Date: ${parsedStartDate.format("YYYY-MM-DD HH:mm")}`}>
          <span>Started {parsedStartDate.from(today)}</span>
        </Tooltip>
      );
    }

    return "Not started";
  };

  const renderStatusButtons = () => {
    const shouldDisableSaveButton =
      watch("abTestType") === "OPT_IN_PRE_TICKED_SELECTION" &&
      !watch("preTickedCountries")?.length;

    return (
      <Box display="flex" gap={3}>
        <LoadingButton
          loading={
            isCreateOrUpdateAbTestPending ||
            isStartAbTestPending ||
            isStopAbTestPending ||
            isRefetching
          }
          loadingPosition="start"
          variant="outlined"
          size="large"
          type="submit"
          disabled={active || shouldDisableSaveButton}
          startIcon={<SaveTwoTone />}
          sx={{
            borderColor: "white",
            color: "white",
            "&:hover": {
              borderColor: "#F3F3F3",
            },
          }}
          data-testid="save-ab-test-button">
          Save
        </LoadingButton>

        <LoadingButton
          loading={
            isCreateOrUpdateAbTestPending ||
            isStartAbTestPending ||
            isStopAbTestPending ||
            isRefetching
          }
          loadingPosition="start"
          color="secondary"
          variant="contained"
          size="large"
          type="button"
          disabled={!id || formHasBeenUpdatedWithoutSave || !!endDate}
          startIcon={active ? <StopCircleTwoTone /> : <PlayCircleTwoTone />}
          onClick={active ? stopTest : startTest}
          data-testid="start-stop-ab-test-button">
          {active ? "Stop" : "Start"}
        </LoadingButton>
      </Box>
    );
  };

  const renderStatusHeader = () => (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        sx={{
          backgroundColor: active ? "success.main" : "primary.main",
          color: active ? "success.contrastText" : "secondary.contrastText",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}>
        <Typography variant="h6">{getLatestAbTestStatus()}</Typography>
        {renderStatusButtons()}
      </Box>
      <Box
        p={3}
        sx={{
          backgroundColor: "#FFF",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
        }}>
        <Typography variant="subtitle2">
          In order to start an A/B test, you must provide and save the
          information required below. Once this information is saved, the{" "}
          <strong>Start</strong> button will be available. You can save this
          information and start an A/B test now, or at a later date.{" "}
          <strong>However</strong>, you cannot edit the below information when
          an A/B test is already in progress.
        </Typography>
      </Box>
    </Box>
  );

  const renderTickboxRadioGroup = () => (
    <FormControl>
      <FormLabel>What was the default state of the tickbox?</FormLabel>
      <Controller
        control={control}
        name="abTestType"
        defaultValue="OPT_IN"
        render={({ field }) => (
          <RadioGroup row {...field}>
            <FormControlLabel
              value="OPT_IN"
              control={<Radio />}
              label="Unticked"
            />
            <FormControlLabel
              value="OPT_IN_PRE_TICKED"
              control={<Radio />}
              label="Pre-ticked"
            />
            <FormControlLabel
              value="OPT_IN_PRE_TICKED_SELECTION"
              control={<Radio />}
              label="Pre-ticked Selection"
            />
          </RadioGroup>
        )}
      />
    </FormControl>
  );

  const renderCountrySelector = () =>
    (watch("abTestType") ?? abTestType) === "OPT_IN_PRE_TICKED_SELECTION" && (
      <FormControl>
        <FormLabel sx={{ mb: 1 }}>
          Which countries should have a pre-ticked checkbox?
        </FormLabel>
        <Controller
          control={control}
          name="preTickedCountries"
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                multiple
                options={countries}
                getOptionLabel={({ name }) => name}
                isOptionEqualToValue={(option, value) =>
                  option.alpha2 === value.alpha2
                }
                value={value!}
                onChange={(e, items) => {
                  e.preventDefault();
                  onChange(items);
                }}
                renderOption={(props, { alpha2, name }) => (
                  <Box
                    component="li"
                    display="flex"
                    alignItems="center"
                    gap={2}
                    {...props}>
                    <ReactCountryFlag countryCode={alpha2} />
                    {name}
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Countries"
                    error={!!errors?.preTickedCountries}
                    helperText={errors?.preTickedCountries?.message}
                  />
                )}
                renderTags={(value: readonly ICountry[], getTagProps) =>
                  value.map((option: ICountry, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        label={
                          <Box display="flex" alignItems="center" gap={1}>
                            <ReactCountryFlag countryCode={option?.alpha2} />
                            {option?.name}
                          </Box>
                        }
                        key={key}
                        {...tagProps}
                      />
                    );
                  })
                }
              />
            );
          }}
        />
      </FormControl>
    );

  const renderConsentCollectionWordingInput = () => (
    <FormControl>
      <FormLabel sx={{ mb: 1 }}>What was the accompanying wording?</FormLabel>
      <TextField
        id="text"
        label="Wording"
        type="text"
        variant="outlined"
        fullWidth
        {...register("widgetConfigText")}
        error={!!errors.widgetConfigText}
        helperText={errors.widgetConfigText?.message}
      />
    </FormControl>
  );

  const renderPreDatashipsConsentSetup = () => (
    <Paper elevation={1} sx={{ p: 3, position: "relative" }}>
      <>
        {renderDisabledState()}
        <Stack direction="column" gap={3}>
          <Typography variant="subtitle1">
            <strong>Customer's pre-Dataships consent collection setup</strong>
          </Typography>
          {renderTickboxRadioGroup()}
          {renderCountrySelector()}
          {renderConsentCollectionWordingInput()}
        </Stack>
      </>
    </Paper>
  );

  const renderSelectField = () => (
    <Controller
      name="listType"
      control={control}
      defaultValue="SINGLE_OPT_IN"
      render={({ field }) => (
        <FormControl>
          <InputLabel id="personas-label">List Type</InputLabel>
          <Select
            {...field}
            labelId="list-type-label"
            id="list-type-select"
            defaultValue={"SINGLE_OPT_IN"}
            input={<OutlinedInput label="List Type" />}>
            <MenuItem key="SINGLE_OPT_IN" value="SINGLE_OPT_IN">
              Single Opt-in
            </MenuItem>
            <MenuItem key="DOUBLE_OPT_IN" value="DOUBLE_OPT_IN">
              Double Opt-in
            </MenuItem>
          </Select>
        </FormControl>
      )}
    />
  );

  const renderKlaviyoListSetup = () => (
    <Paper elevation={1} sx={{ p: 3, position: "relative" }}>
      <>
        {renderDisabledState()}
        <FormControl sx={{ width: "100%" }}>
          <FormLabel sx={{ mb: 2 }}>
            What is the opt-in setting of the Klaviyo list linked to Shopify?
          </FormLabel>
          {renderSelectField()}
        </FormControl>
      </>
    </Paper>
  );

  const renderNoteToCustomerSuccess = () => (
    <Alert icon={<WarningTwoTone />} color="warning" variant="filled">
      <strong>Note to Customer Success:</strong> Check the customer's Klaviyo to
      ensure the Shopify integration is set up correctly.
    </Alert>
  );

  const renderPrivacyPolicyTickboxGroup = () => (
    <FormControl sx={{ width: "100%" }}>
      <FormLabel>
        Is there a valid link to the customers privacy policy on the Checkout
        page?
      </FormLabel>
      <Controller
        control={control}
        name="privacyPolicyLinkPresent"
        defaultValue={true}
        render={({ field }) => (
          <RadioGroup row {...field}>
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        )}
      />
    </FormControl>
  );

  const renderPrivacyPolicyLinkSetup = () => (
    <Paper elevation={1} sx={{ p: 3, position: "relative" }}>
      <>
        {renderDisabledState()}
        {renderPrivacyPolicyTickboxGroup()}
      </>
    </Paper>
  );

  const renderDisabledState = () => {
    const shouldBeDisabled =
      !!active ||
      isRefetching ||
      isCreateOrUpdateAbTestPending ||
      isStartAbTestPending ||
      isStopAbTestPending;

    if (!shouldBeDisabled) return;

    return (
      <Box
        data-testid="form-overlay"
        position="absolute"
        top={0}
        right={0}
        left={0}
        bottom={0}
        borderRadius="4px"
        sx={{ backgroundColor: "#FFFFFF99", zIndex: 100 }}
      />
    );
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Stack direction="column" gap={3}>
        {renderStatusHeader()}
        {renderPreDatashipsConsentSetup()}
        {renderKlaviyoListSetup()}
        {renderNoteToCustomerSuccess()}
        {renderPrivacyPolicyLinkSetup()}
      </Stack>
    </form>
  );
};

export default AbTestForm;
