import {
  usePostComplianceChecks,
  useSmsOnboardingProgress,
} from "@/requests/hooks/customer";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider } from "@mui/material";
import { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

interface IProps {
  currentUrl: string;
  previousStepUrl: string;
  nextStepUrl: string;
}

const SmsOnboardingProgressButtons = ({
  currentUrl,
  previousStepUrl,
  nextStepUrl,
}: IProps) => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { refetch: refetchSmsOnboardingData } = useSmsOnboardingProgress(id);

  const {
    mutate: sendComplianceCheck,
    isPending: isSendComplianceCheckPending,
    isSuccess: isSendComplianceCheckSuccess,
  } = usePostComplianceChecks();

  useEffect(() => {
    if (isSendComplianceCheckSuccess) {
      refetchSmsOnboardingData();
      navigateToNextStep();
    }
  }, [isSendComplianceCheckSuccess]);

  const navigateToNextStep = () =>
    navigate(pathname.replace(currentUrl, nextStepUrl));

  const getProgressValue = () => {
    switch (currentUrl) {
      case "edit-policies":
        return "EDIT_POLICIES_STEP";
      case "add-extension":
        return "ADD_EXTENSION_STEP";
      case "disable-shopify-sms":
        return "DISABLE_SHOPIFY_SMS_STEP";
      default:
        return null;
    }
  };

  const handleStepProgress = () => {
    const progressValue = getProgressValue();

    if (!progressValue) {
      navigateToNextStep();
      return;
    }

    sendComplianceCheck({
      id,
      body: {
        items: [
          {
            item: progressValue,
            checked: true,
          },
        ],
      },
    });
  };

  return (
    <Box>
      <Divider sx={{ mb: 3 }} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={3}>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to={pathname.replace(currentUrl, previousStepUrl)}
          startIcon={<ChevronLeft />}>
          Previous Step
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          endIcon={<ChevronRight />}
          loadingPosition="end"
          loading={isSendComplianceCheckPending}
          onClick={handleStepProgress}>
          Complete Step
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default SmsOnboardingProgressButtons;
