import { boolean, InferType, object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Paper,
  Box,
  Typography,
  Switch,
} from "@mui/material";
import { useSaveIntegrationSettings } from "@/requests/hooks/customer";
import { toast } from "react-toastify";
import IntegrationSettingsModal from "../Modals/IntegrationSettingsModal";

const formSchema = object({
  syncToShopifyEnabled: boolean()
});

export type IIntegrationSettingsFormData = InferType<typeof formSchema>;

interface IProps {
  customer: ICustomer;
  integrationSettingsData: IIntegrationSettingsFormData & { version: number };
  refetchIntegrationSettings: () => void;
}

const IntegrationSettingsForm = ({
  customer,
  integrationSettingsData,
  refetchIntegrationSettings,
}: IProps) => {

  const {
    mutate: saveWidgetSettings,
    isPending: isSaveIntegrationSettingsPending,
    isSuccess: isSaveIntegrationSettingsSuccess,
    isError: isSaveIntegrationSettingsError,
  } = useSaveIntegrationSettings();

  useEffect(() => {
    if (isSaveIntegrationSettingsSuccess) {
      refetchIntegrationSettings();
      toggleConfirmationOpen();
      toast(`Updated integration settings for ${customer?.companyName}`, {
        type: "success",
      });
    }

    if (isSaveIntegrationSettingsError) {
      toast("Unable to save integration settings", { type: "error" });
    }
  }, [isSaveIntegrationSettingsSuccess, isSaveIntegrationSettingsError]);

  const methods = useForm<IIntegrationSettingsFormData>({
    defaultValues: integrationSettingsData,
    resolver: yupResolver(formSchema),
  });

  const {
    register,
    handleSubmit,
    control,
    getValues,
  } = methods;
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  const formatFormData = (data: IIntegrationSettingsFormData) =>
    JSON.parse(JSON.stringify(data), (key, value) => {
      if (key === "version") return integrationSettingsData?.version;
      return value === "" ? null : value;
    });

  const submitForm = (data: IIntegrationSettingsFormData) =>
    saveWidgetSettings({
      id: customer?.id.toString(),
      body: formatFormData(data),
    });

  const toggleConfirmationOpen = () => {
    setConfirmationOpen(confirmationOpen => !confirmationOpen);
  };

  const renderSyncToShopifySwitch = () => (
    <Box mb={3}>
      <Typography variant="h6" color="secondary">
        Enable Sync to Shopify
      </Typography>
      <Typography variant="body2" maxWidth={1024} mb={3}>
        Use the toggle below to enable the shopify contact syncronisation.
      </Typography>
      <Controller
        control={control}
        name="syncToShopifyEnabled"
        render={({ field }) => (
          <Typography variant="body1">
            Off{" "}
            <Switch
              {...register("syncToShopifyEnabled")}
              {...field}
              checked={field?.value}
              inputProps={{ "aria-label": "controlled" }}
              color="primary"
            />{" "}
            On
          </Typography>
        )}
      />
    </Box>
  );

  const renderConfirmationDialog = () => (
    <IntegrationSettingsModal
      integrationSettingsData={getValues()}
      open={confirmationOpen}
      onClose={toggleConfirmationOpen}
      customer={customer}
      isSaveIntegrationSettingsPending={isSaveIntegrationSettingsPending}
    />
  );

  return (
    <>
      <Paper elevation={1}>
        <Box p={3}>
          <FormProvider {...methods}>
            <form id="integration-settings-form" onSubmit={handleSubmit(submitForm)}>
              {renderSyncToShopifySwitch()}
            </form>
          </FormProvider>
        </Box>
      </Paper>

      <Box display="flex" justifyContent="end" mt={3}>
        <LoadingButton
          variant="contained"
          color="secondary"
          loadingPosition="start"
          startIcon={<Save />}
          onClick={toggleConfirmationOpen}>
          Save Changes
        </LoadingButton>
      </Box>
      {renderConfirmationDialog()}
    </>
  );
};

export default IntegrationSettingsForm;