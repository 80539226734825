import { Instructions } from "@/components/Typography";
import {
  usePostComplianceChecks,
  usePostVerifyKlaviyoApiKey,
} from "@/requests/hooks/customer";
import useDebounce from "@/utils/useDebounce";
import {
  BusinessTwoTone,
  CheckBoxTwoTone,
  ReportProblemTwoTone,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState, FocusEvent } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface IProps {
  complianceChecks?: IGetSmsOnboardingProgressRS["complianceChecks"];
}

const KlaviyoApiKeyForm = ({ complianceChecks }: IProps) => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const [localApiKey, setLocalApiKey] = useState<string>(
    complianceChecks?.klaviyoApiKey || "",
  );
  const [localApiKeyError, setLocalApiKeyError] = useState<string>("");
  const debouncedApiKey = useDebounce(localApiKey, 500);

  useEffect(() => {
    const isError =
      !complianceChecks?.klaviyoApiKey?.startsWith("****") &&
      !debouncedApiKey.toLowerCase().startsWith("pk_");

    setLocalApiKeyError(
      isError ? "Invalid API key. Must begin with 'pk_'." : "",
    );
  }, [debouncedApiKey]);

  const {
    data: verifyKlaviyoApiKey,
    error: verifyKlaviyoApiKeyError,
    isLoading: isVerifyKlaviyoApiKeyLoading,
  } = usePostVerifyKlaviyoApiKey({
    id,
    privateKey: debouncedApiKey,
    enabled: debouncedApiKey.toLowerCase().startsWith("pk_"),
  });

  const {
    mutate: sendComplianceCheck,
    isPending: isSendComplianceCheckPending,
    isSuccess: isSendComplianceCheckSuccess,
    isError: isSendComplianceCheckError,
  } = usePostComplianceChecks();

  useEffect(() => {
    if (isSendComplianceCheckSuccess) {
      toast("Saved Klaviyo API Key", { type: "success" });
    }

    if (isSendComplianceCheckError) {
      toast("Failed to save Klaviyo API Key", { type: "error" });
    }
  }, [isSendComplianceCheckSuccess, isSendComplianceCheckError]);

  const getKlaviyoOrganizationName = () => ({
    isLoading: isVerifyKlaviyoApiKeyLoading,
    isError: verifyKlaviyoApiKeyError,
    organizationName:
      verifyKlaviyoApiKey?.organizationName ||
      complianceChecks?.klaviyoOrganizationName,
  });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) =>
    setLocalApiKey(e.target.value);

  const handleFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => e.target.select();

  const handleSaveKlaviyoApiKey = () =>
    sendComplianceCheck({
      id,
      body: {
        klaviyoApiKey: localApiKey,
        items: [{ item: "KLAVIYO_API_KEY", checked: true }],
      },
    });

  const renderHeading = () => (
    <Box mb={3}>
      <Typography variant="h6" color="secondary" mb={1}>
        Klaviyo API Key
      </Typography>

      <Box mb={1}>
        <Alert color="warning" icon={<ReportProblemTwoTone />}>
          <strong>Note to Customer Success:</strong> This step is only needed if
          the customer onboarded to Email Collect before 1st January 2024
        </Alert>
      </Box>

      <Instructions
        instructions={[
          "Please go to your Dataships Support page and click the link ('Klaviyo Setup Instructions' step 6).",
          "Check if your existing Dataships API Key has Full Access to everything by clicking the arrow beside the name",
          "If it doesn't have Full Access, Create a new API Key",
          "Please paste the new API Key into the chat and we will update it on our side",
        ]}
      />
    </Box>
  );

  const renderApiKeyInput = () => (
    <TextField
      fullWidth
      name="klaviyoApiKey"
      label="Klaviyo API Key"
      value={localApiKey}
      onChange={handleOnChange}
      onFocus={handleFocus}
      error={!!localApiKeyError}
      helperText={localApiKeyError}
    />
  );

  const renderAlertAction = () => {
    const { isLoading, organizationName } = getKlaviyoOrganizationName();

    if (isLoading || !organizationName || !!verifyKlaviyoApiKeyError) return;

    const isDisabled =
      complianceChecks?.klaviyoApiKey?.startsWith("***") &&
      localApiKey.startsWith("****");

    return (
      <Box display="flex" alignItems="center" height="100%">
        <LoadingButton
          disabled={isSendComplianceCheckPending || isDisabled}
          loading={isSendComplianceCheckPending}
          color="secondary"
          variant="contained"
          startIcon={<CheckBoxTwoTone />}
          onClick={handleSaveKlaviyoApiKey}>
          Confirm
        </LoadingButton>
      </Box>
    );
  };

  const renderOrganizationName = () => {
    const { isLoading, isError, organizationName } =
      getKlaviyoOrganizationName();

    if (isLoading) return "Fetching organization name...";

    if (isError) return "Invalid API key. Please try again.";

    return organizationName || "No API key provided.";
  };

  const renderConfirmation = () => {
    const { isLoading, isError, organizationName } =
      getKlaviyoOrganizationName();

    return (
      <Alert
        icon={<BusinessTwoTone />}
        severity={
          isLoading
            ? "info"
            : !!organizationName && !isError
            ? "success"
            : "error"
        }
        action={renderAlertAction()}>
        <Typography variant="subtitle2" mb={1}>
          Klaviyo Organization Name
        </Typography>
        {renderOrganizationName()}
      </Alert>
    );
  };

  return (
    <>
      {renderHeading()}

      <Stack direction="column" gap={3}>
        {renderApiKeyInput()}
        {renderConfirmation()}
      </Stack>
    </>
  );
};

export default KlaviyoApiKeyForm;
