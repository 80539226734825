import {
  deleteSmsCountrySetting,
  getCustomer,
  getCustomerMetrics,
  getCustomerPersonas,
  getCustomerTimeline,
  getCustomers,
  getIntegrationSettings,
  getSmsOnboardingFirstOptInCheck,
  getSmsOnboardingProgress,
  getSmsOnboardingSecondOptInCheck,
  getWidgetSettings,
  postCheckKlaviyoLists,
  postCookiebotDetails,
  postCreateKlaviyoLists,
  postCreateSmsCountrySetting,
  postCreateUser,
  postCustomPricing,
  postCustomer,
  postDelinquentStatus,
  postDiscount,
  postExtendTrial,
  postIntegrationSettings,
  postSmsCompanyPrefix,
  postSmsOnboardingCompletionEmail,
  postSmsOnboardingComplianceCheck,
  postSmsOnboardingFirstOptInCheck,
  postSmsOnboardingQuestionnaire,
  postSmsOnboardingSecondOptInCheck,
  postSmsOnboardingSetupEmail,
  postUpdateMarketableRate,
  postUpdateSmsCountrySetting,
  postUserPersonas,
  postVerifyKlaviyoPrivateKey,
  postWidgetSettings,
} from "@/requests/api/customer";
import { UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

export const useCustomers = (
  params: ICustomersRQ,
): UseQueryResult<ICustomersRS> =>
  useQuery({
    queryKey: ["customers", JSON.stringify(params)],
    queryFn: ({ signal }) => getCustomers({ params, signal }),
  });

export const useCustomerPersonas = () =>
  useQuery({
    queryKey: ["customer", "personas"],
    queryFn: getCustomerPersonas,
  });

export const useCreateCustomer = () =>
  useMutation({
    mutationFn: postCustomer,
  });

export const useCustomer = (id: string) =>
  useQuery({
    queryKey: ["customer", id],
    queryFn: ({ signal }) => getCustomer({ id, signal }),
  });

export const useCustomerMetrics = (id: string) =>
  useQuery({
    queryKey: ["customer", id, "metrics"],
    queryFn: ({ signal }) => getCustomerMetrics({ id, signal }),
  });

export const useUpdateMarketableRate = () =>
  useMutation({
    mutationFn: postUpdateMarketableRate,
  });

export const useExtendTrial = () =>
  useMutation({
    mutationFn: postExtendTrial,
  });

export const useCustomerTimeline = ({
  customerId,
  params,
}: {
  customerId: string;
  params: ITimelineRQ;
}) =>
  useQuery({
    queryKey: ["customer", customerId, "timeline", JSON.stringify(params)],
    queryFn: ({ signal }) =>
      getCustomerTimeline({ customerId, params, signal }),
  });

export const useCustomPricing = () =>
  useMutation({
    mutationFn: postCustomPricing,
  });

export const useDiscount = () =>
  useMutation({
    mutationFn: postDiscount,
  });

export const useCookiebot = () =>
  useMutation({
    mutationFn: postCookiebotDetails,
  });

export const useWidgetSettings = (id: string) =>
  useQuery({
    queryKey: ["customer", id, "widget-settings"],
    queryFn: ({ signal }) => getWidgetSettings({ id, signal }),
  });

export const useIntegrationSettings = (id: string) =>
  useQuery({
    queryKey: ["customer", id, "integration-settings"],
    queryFn: ({ signal }) => getIntegrationSettings({ id, signal }),
  });

export const useSaveWidgetSettings = () =>
  useMutation({
    mutationFn: postWidgetSettings,
  });

export const useSaveIntegrationSettings = () =>
  useMutation({
    mutationFn: postIntegrationSettings,
  });

export const useCreateUser = () =>
  useMutation({
    mutationFn: postCreateUser,
  });

export const useUpdateUserPersonas = () =>
  useMutation({
    mutationFn: postUserPersonas,
  });

export const useSmsOnboardingProgress = (id: string) =>
  useQuery({
    queryKey: ["customer", id, "sms-onboarding"],
    queryFn: ({ signal }) => getSmsOnboardingProgress({ id, signal }),
  });

export const useSendSmsOnboardingSetupEmail = () =>
  useMutation({
    mutationFn: postSmsOnboardingSetupEmail,
  });

export const useSaveSmsOnboardingQuestionnaire = () =>
  useMutation({
    mutationFn: postSmsOnboardingQuestionnaire,
  });

export const useCreateSmsOnboardingCountrySetting = () =>
  useMutation({
    mutationFn: postCreateSmsCountrySetting,
  });

export const useUpdateSmsOnboardingCountrySetting = () =>
  useMutation({
    mutationFn: postUpdateSmsCountrySetting,
  });

export const useDeleteSmsOnboardingCountrySetting = () =>
  useMutation({
    mutationFn: deleteSmsCountrySetting,
  });

export const usePostSmsCompanyPrefix = () =>
  useMutation({
    mutationFn: postSmsCompanyPrefix,
  });

export const useSendSmsOnboardingCompletionEmail = () =>
  useMutation({
    mutationFn: postSmsOnboardingCompletionEmail,
  });

export const useTriggerFirstOptInCheck = () =>
  useMutation({
    mutationFn: postSmsOnboardingFirstOptInCheck,
  });

export const usePostDelinquentStatus = () =>
  useMutation({
    mutationFn: postDelinquentStatus,
  });

export const useGetFirstOptInCheck = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}): UseQueryResult<IOptInCheckResult> =>
  useQuery({
    enabled,
    queryKey: ["customer", id, "first-opt-in"],
    queryFn: ({ signal }) => getSmsOnboardingFirstOptInCheck({ id, signal }),
    refetchInterval: query => {
      const {
        state: { data },
      } = query;

      if (!!data && data?.status !== "PENDING") return false;

      return 5000;
    },
  });

export const useTriggerSecondOptInCheck = () =>
  useMutation({
    mutationFn: postSmsOnboardingSecondOptInCheck,
  });

export const useGetSecondOptInCheck = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}): UseQueryResult<IOptInCheckResult> =>
  useQuery({
    enabled,
    queryKey: ["customer", id, "second-opt-in"],
    queryFn: ({ signal }) => getSmsOnboardingSecondOptInCheck({ id, signal }),
    refetchInterval: query => {
      const {
        state: { data },
      } = query;

      if (!!data && data?.status !== "PENDING") return false;

      return 5000;
    },
  });

export const usePostComplianceChecks = () =>
  useMutation({
    mutationFn: postSmsOnboardingComplianceCheck,
  });

export const usePostVerifyKlaviyoApiKey = ({
  id,
  privateKey,
  enabled,
}: {
  id: string;
  privateKey: string;
  enabled: boolean;
}) =>
  useQuery({
    enabled,
    queryKey: [
      "customer",
      id,
      "klaviyoOrganizationName",
      privateKey?.slice(-4),
    ],
    queryFn: ({ signal }) =>
      postVerifyKlaviyoPrivateKey({ id, privateKey, signal }),
  });

export const useCreateKlaviyoLists = () =>
  useMutation({
    mutationFn: postCreateKlaviyoLists,
  });

export const usePostCheckKlaviyoLists = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}): UseQueryResult<ICheckKlaviyoListsRS> =>
  useQuery({
    enabled,
    queryKey: ["customer", id, "klaviyo-lists"],
    queryFn: ({ signal }) => postCheckKlaviyoLists({ id, signal }),
    refetchInterval: query => {
      const {
        state: { data },
      } = query;

      if (!!data && !!data?.singleOptInListChecked) return false;

      return 5000;
    },
  });
