import SmsOnboardingProgressButtons from "@/components/Buttons/SmsOnboardingProgressButtons";
import { KlaviyoApiKeyForm } from "@/components/Forms";
import { ChecklistItem } from "@/components/SmsOnboarding";
import { useSmsOnboardingProgress } from "@/requests/hooks/customer";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const ComplianceChecks = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const { data: smsOnboardingData } = useSmsOnboardingProgress(id);

  const checklistItemNames: ISmsOnboardingChecklistItem[] = [
    "COMPANY_INFORMATION_AND_SMS_MESSAGE_ADD_ONS",
    "SMART_SENDING",
    "QUIET_HOURS",
    "UNSUBSCRIBE_KNOWN_LITIGATORS",
    "CONTENT_OF_SECOND_OPT_IN_CONFIRMATION_MESSAGE",
    "MOBILE_TERMS_OF_SERVICE",
    "SMS_DISCLAIMER_WORDING",
  ];

  const renderHeading = () => (
    <Box mb={3}>
      <Typography variant="h6" color="secondary">
        SMS Compliance Checks in Klaviyo
      </Typography>
      <Typography variant="body2">
        We are now going to check a number of settings in Klaviyo to ensure they
        are set up in a compliant manner.
      </Typography>
    </Box>
  );

  const renderChecklistItems = () =>
    checklistItemNames.map((checklistItemName, index) => {
      const matchingItem = smsOnboardingData?.complianceChecks?.items?.find(
        item => item.item === checklistItemName,
      );

      return <ChecklistItem key={index} checklistItem={matchingItem} />;
    });

  const renderKlaviyoApiKeyForm = () => (
    <Box mb={3}>
      <KlaviyoApiKeyForm
        complianceChecks={smsOnboardingData?.complianceChecks}
      />
    </Box>
  );

  const renderStepButtons = () => (
    <SmsOnboardingProgressButtons
      currentUrl="compliance-checks"
      previousStepUrl="company-details"
      nextStepUrl="create-klaviyo-lists"
    />
  );

  return (
      <Paper elevation={1} sx={{ p: 3, minHeight: "100%"}}>
        {renderHeading()}
        {renderChecklistItems()}
        <Divider sx={{ my: 3 }} />
        {renderKlaviyoApiKeyForm()}
        {renderStepButtons()}
      </Paper>
  );
};

export default ComplianceChecks;
