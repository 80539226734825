import { CheckCircleTwoTone, InventoryTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Stack,
  InputAdornment,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Collapse,
  Box,
  Alert,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { string, number, InferType, object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCustomPricing } from "@/requests/hooks/customer";
import { toast } from "react-toastify";
import { DiscountForm } from "@/components/Forms";

const customPricingFormSchema = object({
  note: string().required().label("Note"),
  price: number()
    .transform(value => (isNaN(value) ? undefined : value))
    .min(0)
    .required()
    .label("Price"),
  annualBilling: string().required(),
  discountNote: string().label("Discount Note"),
  discountPercentage: number()
    .transform(value => (isNaN(value) ? undefined : value))
    .min(1)
    .max(100)
    .label("Discount Percentage"),
  billingCycles: number()
    .transform(value => (isNaN(value) ? undefined : value))
    .min(1)
    .label("Billing Cycles"),
  endOnSubscriptionChange: string(),
});

type ICustomPricingFormData = InferType<typeof customPricingFormSchema>;

interface IProps {
  open: boolean;
  onClose: () => void;
  customerId: string;
  isEnterpriseCustomer: boolean;
  reloadSubscriptions: () => void;
}

const CustomPricingModal = ({
  open,
  onClose,
  customerId,
  isEnterpriseCustomer,
  reloadSubscriptions,
}: IProps) => {
  const {
    mutate: customPricing,
    isPending: isCustomPricingPending,
    isSuccess: isCustomPricingSuccess,
    isError: isCustomPricingError,
  } = useCustomPricing();

  useEffect(() => {
    if (isCustomPricingSuccess) {
      toast(
        isEnterpriseCustomer
          ? "Updated custom pricing"
          : "Switched to custom pricing",
        {
          type: "success",
        },
      );

      reloadSubscriptions();
      handleOnClose();
      toggleConfirmationOpen();
    }

    if (isCustomPricingError) {
      toast("Unable to create custom pricing", {
        type: "error",
      });
    }
  }, [isCustomPricingSuccess, isCustomPricingError]);

  const methods = useForm<ICustomPricingFormData>({
    defaultValues: {
      price: 0,
      annualBilling: "false",
    },
    resolver: yupResolver(customPricingFormSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
    trigger: formTrigger,
    getValues,
  } = methods;

  const [discountToggle, setDiscountToggle] = useState<boolean>(false);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  const handleDiscountToggle = () =>
    setDiscountToggle(discountToggle => !discountToggle);

  const submitForm = (data: ICustomPricingFormData) => {
    const {
      note,
      price,
      annualBilling,
      discountNote,
      discountPercentage,
      billingCycles,
    } = data;

    const customPricingData: ICustomPricingRQ = {
      note,
      price,
      interval: annualBilling === "true" ? "ANNUAL" : "EVERY_30_DAYS",
      discount: discountToggle
        ? {
            note: discountNote!,
            percentage: discountPercentage!,
            maxBillingCycles: billingCycles!,
            endOnSubscriptionChange: true,
            products: ["COLLECT"],
          }
        : null,
    };

    customPricing({
      id: customerId,
      body: customPricingData,
    });
  };

  const handleOnClose = () => {
    onClose();
    setDiscountToggle(false);
    reset();
  };

  const validateAndOpenConfirmation = async () => {
    const validated = await formTrigger();
    if (validated) toggleConfirmationOpen();
  };

  const toggleConfirmationOpen = () => {
    setConfirmationOpen(confirmationOpen => !confirmationOpen);
  };

  const renderDialogTitle = () => (
    <DialogTitle>
      {isEnterpriseCustomer ? "Update" : "Switch to"} Custom Pricing
    </DialogTitle>
  );

  const renderDialogDescription = () => (
    <>
      <DialogContentText mb={2}>
        Here you can switch a customer to Custom Pricing. This must be done
        before a subscription charge has already been issued. You can also apply
        a discount to this Custom price by using the Apply Discount toggle
        below.
      </DialogContentText>
      <DialogContentText mb={2}>
        <b>Any existing configured discounts will be terminated.</b>
      </DialogContentText>
    </>
  );

  const renderCustomPricingForm = () => (
    <Stack spacing={2} direction="column">
      <TextField
        autoFocus
        id="note"
        label="Note"
        type="text"
        variant="outlined"
        fullWidth
        {...register("note")}
        error={!!errors.note}
        helperText={errors.note?.message}
        inputProps={{
          "data-testid": "note-input",
        }}
      />

      <FormControl>
        <FormLabel>Billing Frequency</FormLabel>
        <Controller
          control={control}
          name="annualBilling"
          render={({ field }) => (
            <RadioGroup row {...field}>
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Annual"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Monthly"
              />
            </RadioGroup>
          )}
        />
      </FormControl>

      <TextField
        type="number"
        variant="outlined"
        id="price"
        label="Price"
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: (
            <InputAdornment position="end">
              per {watch("annualBilling") === "true" ? "year" : "month"}
            </InputAdornment>
          ),
        }}
        {...register("price")}
        error={!!errors.price}
        helperText={errors.price?.message}
        inputProps={{
          "data-testid": "price-input",
        }}
      />
    </Stack>
  );

  const renderDiscountToggle = () => (
    <Box mt={2}>
      <FormControlLabel
        control={
          <Switch
            checked={discountToggle}
            onChange={handleDiscountToggle}
            inputProps={{ "aria-label": "controlled" }}
            color="primary"
          />
        }
        label="Apply Discount"
      />
    </Box>
  );

  const renderDiscountForm = () => (
    <Collapse in={discountToggle}>
      <Box mt={2}>
        <DiscountForm
          annualBillingSelected={watch("annualBilling") === "true"}
          showEndOnSubscriptionChange={false}
        />
      </Box>
    </Collapse>
  );

  const renderFullForm = () => (
    <FormProvider {...methods}>
      <form id="custom-pricing-form" onSubmit={handleSubmit(submitForm)}>
        {renderCustomPricingForm()}
        {renderDiscountToggle()}
        {renderDiscountForm()}
      </form>
    </FormProvider>
  );

  const renderDialogButtons = () => (
    <DialogActions>
      <Button color="error" onClick={handleOnClose}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<InventoryTwoTone />}
        type="button"
        onClick={validateAndOpenConfirmation}
        data-testid="submit-button">
        Save custom price
      </Button>
    </DialogActions>
  );

  const renderConfirmationInfoRow = (
    label: string,
    value?: string | number,
  ) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <span>{label}</span>
        <span>{value}</span>
      </Box>
    );
  };

  const renderConfirmationInformation = () => (
    <List>
      <ListItem>
        {renderConfirmationInfoRow("Note", getValues("note"))}
      </ListItem>
      <Divider />
      <ListItem>
        {renderConfirmationInfoRow(
          "Billing Frequency",
          getValues("annualBilling") === "true" ? "Annual" : "Monthly",
        )}
      </ListItem>
      <Divider />
      <ListItem>
        {renderConfirmationInfoRow("Price", `$${getValues("price")}`)}
      </ListItem>
      {discountToggle && (
        <>
          <Divider />
          <ListItem>
            {renderConfirmationInfoRow(
              "Discount Note",
              getValues("discountNote"),
            )}
          </ListItem>
          <Divider />
          <ListItem>
            {renderConfirmationInfoRow(
              "Discount Percentage",
              `${getValues("discountPercentage")}%`,
            )}
          </ListItem>
          <Divider />
          <ListItem>
            {renderConfirmationInfoRow(
              "Num. Billing Cycles",
              getValues("billingCycles"),
            )}
          </ListItem>
        </>
      )}
    </List>
  );

  const renderConfirmationDialog = () => (
    <Dialog
      open={confirmationOpen}
      onClose={toggleConfirmationOpen}
      fullWidth
      maxWidth="xs">
      <DialogTitle>Confirm Custom Pricing</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Clicking confirm will enable custom pricing and this customer will
          receive a new subscription link. All existing discounts will be
          cancelled.
        </DialogContentText>

        <DialogContentText>
          Clicking cancel will exit without any changes being made.
        </DialogContentText>
      </DialogContent>
      <Alert severity="warning">This action cannot be undone.</Alert>
      {renderConfirmationInformation()}
      <DialogActions>
        <Button color="error" onClick={toggleConfirmationOpen}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={isCustomPricingPending}
          loadingPosition="start"
          startIcon={<CheckCircleTwoTone />}
          type="submit"
          form="custom-pricing-form">
          Confirm Custom Pricing
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Dialog open={open} onClose={handleOnClose}>
        {renderDialogTitle()}
        <DialogContent>
          {renderDialogDescription()}
          {renderFullForm()}
        </DialogContent>
        {renderDialogButtons()}
      </Dialog>
      {renderConfirmationDialog()}
    </>
  );
};

export default CustomPricingModal;
