import { LoginAsCustomerButton } from "@/components/Buttons";
import { CreateCustomerModal } from "@/components/Modals";
import { RenderDate } from "@/components/Table";
import { useCustomers } from "@/requests/hooks/customer";
import { updateSearchParams } from "@/utils/updateSearchParams";
import useDebounce from "@/utils/useDebounce";
import { DomainAddTwoTone, SearchTwoTone } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { useFlags } from "flagsmith/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams, Link as RouterLink } from "react-router-dom";
import renderBooleanIcon from "@/components/Table/RenderBooleanIcon.tsx";
import renderProducts from "@/components/Table/RenderProducts.tsx";
import { abbreviateNumber } from "@/utils/helpers.ts";
import renderExtensibilityStatus from "@/components/Table/RenderExtensibilityStatus.tsx";

const Home = () => {
  const flags = useFlags(["create-customer-enabled"]);
  const isCreateCustomerEnabled =
    flags?.["create-customer-enabled"]?.enabled || false;

  const [searchParams, setSearchParams] = useSearchParams({});
  const [searchString, setSearchString] = useState<string>(
    searchParams.get("q") || "",
  );

  const debouncedSearch = useDebounce(searchString, 500);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [sortModel, setSortModel] = useState({
    sortBy: searchParams.get("sortBy") || "",
    sortOrder: searchParams.get("sortOrder") || "",
  });

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (!sortModel.length) {
      setSortModel({
        sortBy: "",
        sortOrder: "",
      });
      return;
    }

    const sortFields = sortModel[0];
    if (sortFields) {
      setSortModel({
        sortBy: sortFields.field,
        sortOrder: sortFields.sort || "",
      });
    }
  };

  const [rowCount, setRowCount] = useState<number>(0);

  const [createCustomerModalOpen, setCreateCustomerModalOpen] =
    useState<boolean>(false);

  const {
    data: customers,
    isLoading: isCustomersLoading,
    refetch: refetchCustomers,
  } = useCustomers({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    searchString: debouncedSearch,
    sortBy: sortModel.sortBy || "id",
    sortOrder: sortModel.sortOrder || "desc",
  });

  useEffect(() => {
    updateSearchParams(
      {
        q: searchString,
        sortBy: sortModel.sortBy,
        sortOrder: sortModel.sortOrder,
      },
      setSearchParams,
    );
  }, [sortModel, searchString]);

  useEffect(() => {
    setRowCount(prevRowCountState =>
      customers?.totalResults !== undefined
        ? customers?.totalResults
        : prevRowCountState,
    );
  }, [customers]);

  const handleSearchStringChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    setSearchParams({ q: event.target.value });
  };

  const toggleModal = () =>
    setCreateCustomerModalOpen(isModalOpen => !isModalOpen);

  const renderSearchInput = () => (
    <Box sx={{ py: 2, px: 3, display: "flex", justifyContent: "end" }}>
      <TextField
        variant="standard"
        placeholder="Search customers..."
        inputProps={{
          role: "search",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchTwoTone />
            </InputAdornment>
          ),
        }}
        color="primary"
        value={searchString}
        onChange={handleSearchStringChange}
      />
    </Box>
  );

  const renderCompanyName = ({ row }: GridRenderCellParams<ICustomer>) => (
    <Link component={RouterLink} to={`/customer/${row.id}`}>
      {row.companyName}
    </Link>
  );

  const renderActionButtons = ({
    row: { id },
  }: GridRenderCellParams<ICustomer>) => (
    <LoginAsCustomerButton customerId={id} />
  );

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1, maxWidth: 75 },
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      renderCell: renderCompanyName,
    },
    {
      field: "features",
      headerName: "Products",
      flex: 1,
      sortable: false,
      renderCell: renderProducts,
      minWidth: 180,
    },
    {
      field: "trialEndDate",
      headerName: "Trial End Date",
      flex: 1,
      renderCell: RenderDate,
      sortable: false,
    },
    {
      field: "verifiedContacts",
      headerName: "Verified Contacts",
      flex: 1,
      valueFormatter: value => abbreviateNumber(value),
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "isAbTestActive",
      headerName: "A/B",
      description: "A/B Test Active",
      flex: 1,
      renderCell: renderBooleanIcon,
      headerAlign: "center",
      sortable: false,
      maxWidth: 100,
    },
    {
      field: "isOnboardingActive",
      headerName: "O/B",
      flex: 1,
      renderCell: renderBooleanIcon,
      headerAlign: "center",
      description: "Onboarding Active",
      maxWidth: 100,
    },
    {
      field: "shopifyExtensibilityStatus",
      headerName: "Extensibility",
      flex: 1,
      renderCell: renderExtensibilityStatus,
      maxWidth: 130,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: renderActionButtons,
      sortable: false,
    },
  ];

  const renderCreateCustomerButton = () =>
    isCreateCustomerEnabled && (
      <Button
        variant="contained"
        color="success"
        startIcon={<DomainAddTwoTone />}
        onClick={toggleModal}>
        Create Customer
      </Button>
    );

  const renderCreateCustomerModal = () => (
    <CreateCustomerModal
      open={createCustomerModalOpen}
      onClose={toggleModal}
      refetchCustomers={refetchCustomers}
    />
  );

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}>
        <Typography variant="h4">Customer List</Typography>
        {renderCreateCustomerButton()}
      </Box>

      <Divider sx={{ my: 3 }} />

      <Paper elevation={1}>
        {renderSearchInput()}

        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }],
            },
          }}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          loading={isCustomersLoading}
          columns={columns}
          rows={customers?.results || []}
          rowCount={rowCount}
          pagination
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50, 100]}
          autoHeight
        />
      </Paper>
      {renderCreateCustomerModal()}
    </>
  );
};

export default Home;
