import { Dispatch, SetStateAction, useEffect } from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { SaveTwoTone } from "@mui/icons-material";
import {
  replaceUnderScoresWithSpaces,
  stringToTitleCase,
} from "@/utils/helpers";
import { useUpdateUserPersonas } from "@/requests/hooks/customer";
import { toast } from "react-toastify";

interface IProps {
  user: IUser;
  availablePersonas: string[];
  selectedPersonas: string[];
  setSelectedPersonas: Dispatch<SetStateAction<string[]>>;
  refetchCustomer: () => void;
}

const UserRoles = ({
  user,
  availablePersonas,
  selectedPersonas,
  setSelectedPersonas,
  refetchCustomer,
}: IProps) => {
  const { userCustomerId, personas, version } = user;

  const {
    mutate: updatePersonas,
    isPending: isUpdatePersonasPending,
    isSuccess: isUpdatePersonasSuccess,
    isError: isUpdatePersonasError,
  } = useUpdateUserPersonas();

  useEffect(() => {
    if (isUpdatePersonasSuccess) {
      refetchCustomer();
      toast("Updated roles", {
        type: "success",
      });
    }

    if (isUpdatePersonasError) {
      toast("Unable to update roles", {
        type: "error",
      });
    }
  }, [isUpdatePersonasSuccess, isUpdatePersonasError]);

  useEffect(() => {
    setSelectedPersonas(personas);
  }, [personas, setSelectedPersonas]);

  const prettifyPersonaName = (persona: string) =>
    stringToTitleCase(replaceUnderScoresWithSpaces(persona));

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    if (selectedPersonas.includes(value)) {
      setSelectedPersonas(selectedPersonas.filter(item => item !== value));
    } else {
      setSelectedPersonas([value, ...selectedPersonas]);
    }
  };

  const submit = () =>
    updatePersonas({
      id: userCustomerId,
      body: {
        version,
        personas: selectedPersonas,
      },
    });

  const renderPersonas = () => {
    const options = availablePersonas?.map((persona: string, index: number) => (
      <Grid key={index} item xs={4}>
        <FormControlLabel
          label={prettifyPersonaName(persona)}
          control={
            <Checkbox
              checked={selectedPersonas.includes(persona)}
              onChange={handleCheckboxChange}
              value={persona}
            />
          }
        />
      </Grid>
    ));

    return (
      <Grid container direction="row">
        {options}
      </Grid>
    );
  };

  return (
    <>
      <Typography variant="h6">Roles</Typography>
      <Box sx={{ mt: 1, mb: 3 }}>{renderPersonas()}</Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <LoadingButton
          loading={isUpdatePersonasPending}
          loadingPosition="start"
          variant="contained"
          color="secondary"
          startIcon={<SaveTwoTone />}
          onClick={submit}>
          Save Changes
        </LoadingButton>
      </Box>
    </>
  );
};

export default UserRoles;
