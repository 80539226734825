import {
  ArrowDropDownTwoTone,
  CancelTwoTone,
  CheckCircleTwoTone,
} from "@mui/icons-material";
import { Box, Button, Divider, Popover, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ReactNode, useState } from "react";

const RenderAbTestConfig = (params: GridRenderCellParams) => {
  const {
    value: widgetConfig,
    row: { listType, abTestType, privacyPolicyLinkPresent },
  } = params;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const renderSection = ({
    label,
    value,
  }: {
    label: string;
    value: ReactNode;
  }) => (
    <Box p={2}>
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );

  const renderButton = () => (
    <Button
      aria-describedby={id}
      color="secondary"
      variant="outlined"
      onClick={handleClick}
      size="small"
      endIcon={<ArrowDropDownTwoTone />}>
      Test Config
    </Button>
  );

  const renderPopover = () => (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}>
      <Box minWidth={300}>
        {renderSection({
          label: "Widget Config Text",
          value: widgetConfig.text,
        })}
        <Divider />
        {renderSection({
          label: "A/B Test Type",
          value: abTestType,
        })}
        <Divider />
        {renderSection({
          label: "Klaviyo List Type",
          value: listType,
        })}
        <Divider />
        {renderSection({
          label: "Privacy Policy Link Present",
          value: privacyPolicyLinkPresent ? (
            <CheckCircleTwoTone color="success" />
          ) : (
            <CancelTwoTone color="error" />
          ),
        })}
      </Box>
    </Popover>
  );

  return (
    <>
      {renderButton()}
      {renderPopover()}
    </>
  );
};

export default RenderAbTestConfig;
