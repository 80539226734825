import dayjs from "dayjs";

export const replaceUnderScoresWithSpaces = (string: string) => {
  return string.replace("_", " ");
};

export const stringToTitleCase = (string: string) => {
  return string
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");
};

export const abbreviateNumber = (number: number): string =>
  new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 1,
    notation: "compact",
    compactDisplay: "short",
  }).format(number);

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const generateUuid = () => window.crypto.randomUUID();

export const formatValue = ({
  value,
  format,
}: {
  value: string;
  format: IMetricFormat;
}) => {
  if (format === "NONE") return value;

  let floatValue = parseFloat(value);
  if (isNaN(floatValue)) {
    floatValue = 0.0;
  }

  switch (format) {
    case "INTEGER": {
      return Intl.NumberFormat("en-US", {
        style: "decimal",
        maximumFractionDigits: 0,
      }).format(floatValue);
    }

    case "DECIMAL": {
      return Intl.NumberFormat("en-US", {
        style: "decimal",
        maximumFractionDigits: 2,
      }).format(floatValue);
    }

    case "DATE": {
      if (value === null) return "N/A";

      return dayjs(value).format("YYYY-MM-DD");
    }

    case "DATETIME": {
      if (value === null) return "N/A";

      return dayjs(value).format("YYYY-MM-DD HH:mm");
    }

    case "MONETARY": {
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      }).format(floatValue);
    }

    case "MONETARY_CENTS": {
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
      }).format(floatValue);
    }

    case "PERCENTAGE": {
      return `${Intl.NumberFormat("en-US").format(floatValue)}%`;
    }

    default:
      return value;
  }
};

export const getContentAlignment = (format: IMetricFormat) =>
  format === "CUSTOMER_LINK" || format === "NONE" || format === "DATE"
    ? "left"
    : "right";

/**
 * List of all countries where Klaviyo SMS is available and supported.
 *
 * This list can be referenced here: https://help.klaviyo.com/hc/en-us/articles/4402914866843
 */
export const smsKlaviyoSupportedCountries = (): ICountry[] => {
  /*
   * This acts as the dropdown group name and will be the
   * same for every country in this list
   */
  const supportedInKlaviyo = "Supported by Klaviyo";

  return [
    {
      id: 14,
      alpha2: "AU",
      name: "Australia",
      phoneCode: "61",
      currency: "AUD",
      longitude: 133,
      latitude: -27,
      supportedInKlaviyo,
    },
    {
      id: 15,
      alpha2: "AT",
      name: "Austria",
      phoneCode: "43",
      currency: "EUR",
      longitude: 13,
      latitude: 47,
      supportedInKlaviyo,
    },
    {
      id: 39,
      alpha2: "CA",
      name: "Canada",
      phoneCode: "1",
      currency: "CAD",
      longitude: -95,
      latitude: 60,
      supportedInKlaviyo,
    },
    {
      id: 59,
      alpha2: "DK",
      name: "Denmark",
      phoneCode: "45",
      currency: "DKK",
      longitude: 10,
      latitude: 56,
      supportedInKlaviyo,
    },
    {
      id: 74,
      alpha2: "FI",
      name: "Finland",
      phoneCode: "358",
      currency: "EUR",
      longitude: 26,
      latitude: 64,
      supportedInKlaviyo,
    },
    {
      id: 75,
      alpha2: "FR",
      name: "France",
      phoneCode: "33",
      currency: "EUR",
      longitude: 2,
      latitude: 46,
      supportedInKlaviyo,
    },
    {
      id: 82,
      alpha2: "DE",
      name: "Germany",
      phoneCode: "49",
      currency: "EUR",
      longitude: 9,
      latitude: 51,
      supportedInKlaviyo,
    },
    {
      id: 105,
      alpha2: "IE",
      name: "Ireland",
      phoneCode: "353",
      currency: "EUR",
      longitude: -28,
      latitude: 53,
      supportedInKlaviyo,
    },
    {
      id: 107,
      alpha2: "IT",
      name: "Italy",
      phoneCode: "39",
      currency: "EUR",
      longitude: 12,
      latitude: 42,
      supportedInKlaviyo,
    },
    {
      id: 156,
      alpha2: "NL",
      name: "Netherlands",
      phoneCode: "31",
      currency: "EUR",
      longitude: 5.75,
      latitude: 52.5,
      supportedInKlaviyo,
    },
    {
      id: 158,
      alpha2: "NZ",
      name: "New Zealand",
      phoneCode: "64",
      currency: "NZD",
      longitude: 174,
      latitude: -41,
      supportedInKlaviyo,
    },
    {
      id: 165,
      alpha2: "NO",
      name: "Norway",
      phoneCode: "47",
      currency: "NZD",
      longitude: 10,
      latitude: 62,
      supportedInKlaviyo,
    },
    {
      id: 177,
      alpha2: "PT",
      name: "Portugal",
      phoneCode: "351",
      currency: "EUR",
      longitude: -8,
      latitude: 39,
      supportedInKlaviyo,
    },
    {
      id: 207,
      alpha2: "ES",
      name: "Spain",
      phoneCode: "34",
      currency: "EUR",
      longitude: -4,
      latitude: 40,
      supportedInKlaviyo,
    },
    {
      id: 213,
      alpha2: "SE",
      name: "Sweden",
      phoneCode: "46",
      currency: "SEK",
      longitude: 15,
      latitude: 62,
      supportedInKlaviyo,
    },
    {
      id: 214,
      alpha2: "CH",
      name: "Switzerland",
      phoneCode: "41",
      currency: "CHF",
      longitude: 8,
      latitude: 47,
      supportedInKlaviyo,
    },
    {
      id: 232,
      alpha2: "GB",
      name: "United Kingdom",
      phoneCode: "44",
      currency: "GBP",
      longitude: -2,
      latitude: 54,
      supportedInKlaviyo,
    },
    {
      id: 233,
      alpha2: "US",
      name: "United States",
      phoneCode: "1",
      currency: "USD",
      longitude: -97,
      latitude: 38,
      supportedInKlaviyo,
    },
  ];
};
