import { Divider, Grid, Typography } from "@mui/material";
import { StatTile } from "@/components/Tiles";
import { ErrorJoke } from "@/components/Error";
import { PageLoader } from "@/components/Loaders";
import { generateUuid } from "@/utils/helpers";
import { useCustomerMetrics } from "@/requests/hooks/customer";
import { useParams } from "react-router-dom";

const CustomerStatsGrid = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const {
    data: metricsData,
    isLoading: isMetricsLoading,
    isError: isMetricsError,
    error: metricsError,
  } = useCustomerMetrics(id);

  const renderStatGrid = () =>
    metricsData?.results?.map(stat => (
      <Grid key={generateUuid()} item sm={12} md={6} lg={4}>
        <StatTile stat={stat} />
      </Grid>
    ));

  const renderError = () => <ErrorJoke error={metricsError} />;

  const renderGrid = () => (
    <>
      <Typography variant="h5" mb={1} mt={3}>
        Customer Metrics
      </Typography>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={3} direction="row">
        {renderStatGrid()}
      </Grid>
    </>
  );

  const renderContent = () =>
    isMetricsLoading ? (
      <PageLoader title="stats" />
    ) : isMetricsError ? (
      renderError()
    ) : (
      renderGrid()
    );

  return renderContent();
};

export default CustomerStatsGrid;
