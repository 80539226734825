import { useCustomer, useCustomerPersonas } from "@/requests/hooks/customer";
import { useFlags } from "flagsmith/react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Error } from "@/components/Error";
import { PageLoader } from "@/components/Loaders";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  replaceUnderScoresWithSpaces,
  stringToColor,
  stringToTitleCase,
} from "@/utils/helpers";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Edit, PersonAddTwoTone } from "@mui/icons-material";
import { CreateUserModal } from "@/components/Modals";

const Users = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const flags = useFlags(["invite-user-to-customer-enabled"]);
  const isInviteUserToCustomerEnabled =
    flags?.["invite-user-to-customer-enabled"]?.enabled || false;

  const [createUserModalOpen, setCreateUserModalOpen] =
    useState<boolean>(false);

  const {
    data: customerData,
    isLoading: isCustomerLoading,
    error: customerError,
    refetch: refetchCustomer,
  } = useCustomer(id);

  const {
    data: personasData,
    isLoading: isPersonasLoading,
    error: personasError,
  } = useCustomerPersonas();

  if (isCustomerLoading || isPersonasLoading)
    return <PageLoader title="users" />;
  if (customerError || personasError || !customerData)
    return (
      <Error
        error={customerError || personasError || "Customer data unavailable."}
      />
    );

  const { users } = customerData;

  const toggleModal = () => setCreateUserModalOpen(isModalOpen => !isModalOpen);

  const renderPersonas = ({ row }: GridRenderCellParams<IUser>) => {
    const { personas } = row;

    const badges = personasData.map((persona: string, index: number) => {
      const splitPersona = replaceUnderScoresWithSpaces(persona);
      const backgroundColor = personas.includes(persona)
        ? stringToColor(splitPersona)
        : "#ececec";

      return (
        <Tooltip
          key={index}
          title={stringToTitleCase(replaceUnderScoresWithSpaces(persona))}>
          <Avatar sx={{ backgroundColor }}>
            {splitPersona.split(" ")[0][0]}
            {splitPersona.split(" ")[1][0]}
          </Avatar>
        </Tooltip>
      );
    });

    return (
      <AvatarGroup max={8} sx={{ justifyContent: "start" }}>
        {badges}
      </AvatarGroup>
    );
  };

  const renderActionButtons = ({ row }: GridRenderCellParams<IUser>) => (
    <Tooltip title="Edit user">
      <Button
        component={Link}
        to={`${row.userId}`}
        sx={{ mx: 1 }}
        size="small"
        variant="contained"
        color="secondary">
        <Edit />
      </Button>
    </Tooltip>
  );

  const userColumns: GridColDef[] = [
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "firstName",
      headerName: "Name",
      flex: 1,
      renderCell: params => {
        const { firstName, lastName } = params.row;
        return `${firstName} ${lastName}`;
      },
    },
    {
      field: "personas",
      headerName: "Roles",
      flex: 1,
      renderCell: renderPersonas,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: renderActionButtons,
    },
  ];

  const renderUsersTable = () => (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        mb={1}>
        <Typography variant="h5">Users</Typography>
        {isInviteUserToCustomerEnabled && (
          <Button
            variant="contained"
            color="success"
            size="small"
            startIcon={<PersonAddTwoTone />}
            onClick={toggleModal}>
            Create User
          </Button>
        )}
      </Box>

      <Paper elevation={1}>
        <DataGrid
          getRowId={row => row.userId}
          loading={isCustomerLoading}
          rows={users || []}
          columns={userColumns}
          autoHeight
        />
      </Paper>
    </>
  );

  const renderCreateUserModal = () => (
    <CreateUserModal
      open={createUserModalOpen}
      onClose={toggleModal}
      customerId={id}
      refetchCustomer={refetchCustomer}
    />
  );

  return (
    <>
      {renderUsersTable()}
      {renderCreateUserModal()}
    </>
  );
};

export default Users;
