import { PageLoader } from "@/components/Loaders";
import { Error } from "@/components/Error";
import { useCustomer, useIntegrationSettings } from "@/requests/hooks/customer";
import { useParams } from "react-router-dom";
import IntegrationSettingsForm from "@/components/Forms/IntegrationSettingsForm";


const IntegrationSettings = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const {
    data: customerData,
    isLoading: isCustomerLoading,
    error: customerError,
  } = useCustomer(id);

  const {
    data: integationSettingsData,
    isLoading: isIntegrationSettingsLoading,
    error: integationSettingsError,
    refetch: refetchIntegrationSettings,
  } = useIntegrationSettings(id);

  if (isCustomerLoading || isIntegrationSettingsLoading)
    return <PageLoader title="Integation settings" />;
  if (customerError || integationSettingsError || !customerData)
    return (
      <Error
        error={
          customerError || integationSettingsError || "Customer data unavailable."
        }
      />
    );

  return (
    <IntegrationSettingsForm
      customer={customerData}
      integrationSettingsData={integationSettingsData}
      refetchIntegrationSettings={refetchIntegrationSettings}
    />
  );
};

export default IntegrationSettings;
