import { PersonAddTwoTone } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import CreateUserForm, {
  ICreateUserFormData,
  createUserFormSchema,
} from "@/components/Forms/CreateUserForm";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateUser } from "@/requests/hooks/customer";
import { toast } from "react-toastify";

interface IProps {
  open: boolean;
  onClose: () => void;
  customerId: string;
  refetchCustomer: () => void;
}

const CreateUserModal = ({
  open,
  onClose,
  customerId,
  refetchCustomer,
}: IProps) => {
  const {
    mutate: createUser,
    isPending: isCreateUserPending,
    isSuccess: isCreateUserSuccess,
  } = useCreateUser();

  useEffect(() => {
    if (isCreateUserSuccess) {
      toast("Created user", {
        type: "success",
      });

      refetchCustomer();
      handleOnClose();
    }
  }, [isCreateUserSuccess]);

  const methods = useForm<ICreateUserFormData>({
    resolver: yupResolver(createUserFormSchema),
  });

  const { handleSubmit, reset } = methods;

  const [errorCodes, setErrorCodes] = useState<ErrorCode[] | undefined>(
    undefined,
  );

  const submitForm = (data: ICreateUserFormData) =>
    createUser({ id: customerId, body: data });

  const handleOnClose = () => {
    setErrorCodes(undefined);
    onClose();
    reset();
  };

  const renderDialogTitle = () => <DialogTitle>Create User</DialogTitle>;

  const renderDialogDescription = () => (
    <DialogContentText mb={2}>
      This will create a user for this customer and send them a password reset
      link. They will then be able to set a password and sign in themselves.
    </DialogContentText>
  );

  const renderDialogForm = () => (
    <FormProvider {...methods}>
      <form id="create-user-form" onSubmit={handleSubmit(submitForm)}>
        <CreateUserForm errorCodes={errorCodes} />
      </form>
    </FormProvider>
  );

  const renderDialogButtons = () => (
    <DialogActions>
      <Button color="error" onClick={handleOnClose}>
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        color="secondary"
        loading={isCreateUserPending}
        loadingPosition="start"
        startIcon={<PersonAddTwoTone />}
        type="submit"
        form="create-user-form">
        Create User
      </LoadingButton>
    </DialogActions>
  );

  return (
    <Dialog open={open} onClose={handleOnClose}>
      {renderDialogTitle()}
      <DialogContent>
        {renderDialogDescription()}
        {renderDialogForm()}
      </DialogContent>
      {renderDialogButtons()}
    </Dialog>
  );
};

export default CreateUserModal;
