import { Chip, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { abbreviateNumber } from "@/utils/helpers";

const RenderTier = (params: GridRenderCellParams) => {
  const {
    row: { tier, maxVerifiedContacts },
  } = params;

  const renderTier = () => <Typography variant="body2">{tier}</Typography>;

  const renderTooltipAndChip = () => (
    <Tooltip
      title={`${maxVerifiedContacts} Max Verified Contacts`}
      placement="top">
      <Chip label={abbreviateNumber(maxVerifiedContacts)} color="info" />
    </Tooltip>
  );

  return (
    <Stack height="100%" direction="row" spacing={1} alignItems="center">
      {renderTier()}
      {renderTooltipAndChip()}
    </Stack>
  );
};

export default RenderTier;
