import { usePostSmsCompanyPrefix } from "@/requests/hooks/customer";
import { yupResolver } from "@hookform/resolvers/yup";
import { SaveTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { InputAdornment, TextField } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { InferType, object, string } from "yup";

const formSchema = object({
  companyPrefix: string().required().label("Company Prefix"),
});

type ISmsOnboardingCompanyPrefix = InferType<typeof formSchema>;

interface IProps {
  companyPrefix: string | undefined;
  customerVersion: number;
  refetch: () => void;
}

const CompanyPrefixForm = ({
  companyPrefix,
  customerVersion,
  refetch,
}: IProps) => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISmsOnboardingCompanyPrefix>({
    defaultValues: {
      companyPrefix,
    },
    resolver: yupResolver(formSchema),
  });

  const {
    mutate: saveCompanyPrefix,
    isPending: isSaveCompanyPrefixPending,
    isSuccess: isSaveCompanyPrefixSuccess,
    isError: isSaveCompanyPrefixError,
  } = usePostSmsCompanyPrefix();

  useEffect(() => {
    if (isSaveCompanyPrefixSuccess) {
      toast("Saved company prefix", { type: "success" });
      refetch();
    }

    if (isSaveCompanyPrefixError) {
      toast("Failed to save company prefix", { type: "error" });
    }
  }, [isSaveCompanyPrefixSuccess, isSaveCompanyPrefixError]);

  const submitForm = (data: ISmsOnboardingCompanyPrefix) =>
    saveCompanyPrefix({
      id,
      body: { customerVersion, ...data },
    });

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <TextField
        margin="dense"
        id="companyPrefix"
        label="Company Prefix"
        type="text"
        fullWidth
        variant="outlined"
        multiline={false}
        {...register("companyPrefix")}
        error={!!errors?.companyPrefix}
        helperText={errors?.companyPrefix?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <LoadingButton
                loading={isSaveCompanyPrefixPending}
                loadingPosition="start"
                variant="contained"
                color="secondary"
                startIcon={<SaveTwoTone />}
                type="submit">
                Save Prefix
              </LoadingButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default CompanyPrefixForm;
