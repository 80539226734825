import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from "react-hook-form";
import { createUserFormSchema } from "@/components/Forms/CreateUserForm";
import { string, InferType } from "yup";
import { URL_REGEX } from "@/utils/const";

export const createCustomerFormSchema = createUserFormSchema.shape({
  companyName: string().required().label("Company Name"),
  companyWebsiteUrl: string()
    .matches(URL_REGEX, {
      message: "Not a valid URL",
    })
    .required()
    .label("Company Website URL"),
  companyIndustry: string().required().label("Company Industry"),
  customerType: string().required().label("Customer Type"),
});

export type ICreateCustomerFormData = InferType<
  typeof createCustomerFormSchema
>;

const CreateCustomerForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ICreateCustomerFormData>();

  const renderCompanyIndustryRadioGroup = ({
    field,
  }: {
    field: ControllerRenderProps<ICreateCustomerFormData, "companyIndustry">;
  }) => (
    <RadioGroup row {...field}>
      <FormControlLabel value="B2C" control={<Radio />} label="B2C" />
      <FormControlLabel value="B2B" control={<Radio />} label="B2B" />
      <FormControlLabel value="B2B2C" control={<Radio />} label="B2B2C" />
    </RadioGroup>
  );

  const renderCustomerTypeRadioGroup = ({
    field,
  }: {
    field: ControllerRenderProps<ICreateCustomerFormData, "customerType">;
  }) => (
    <RadioGroup row {...field}>
      <FormControlLabel value="CUSTOMER" control={<Radio />} label="Customer" />
      <FormControlLabel value="DEMO" control={<Radio />} label="Demo" />
      <FormControlLabel
        value="INTERNAL_TEST"
        control={<Radio />}
        label="Internal Test"
      />
      <FormControlLabel
        value="PRIVACY_POLICY"
        control={<Radio />}
        label="Privacy Policy"
      />
    </RadioGroup>
  );

  return (
    <Stack spacing={2} direction="column">
      <TextField
        autoFocus
        id="companyName"
        label="Company Name"
        type="text"
        variant="outlined"
        fullWidth
        {...register("companyName")}
        error={!!errors.companyName}
        helperText={errors.companyName?.message}
      />

      <TextField
        id="companyWebsiteUrl"
        label="Company Website URL"
        type="text"
        variant="outlined"
        fullWidth
        {...register("companyWebsiteUrl")}
        error={!!errors.companyWebsiteUrl}
        helperText={errors.companyWebsiteUrl?.message}
      />

      <FormControl>
        <FormLabel>Company Industry</FormLabel>
        <Controller
          control={control}
          name="companyIndustry"
          defaultValue="B2C"
          render={renderCompanyIndustryRadioGroup}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Customer Type</FormLabel>
        <Controller
          control={control}
          name="customerType"
          defaultValue="CUSTOMER"
          render={renderCustomerTypeRadioGroup}
        />
      </FormControl>
    </Stack>
  );
};

export default CreateCustomerForm;
