export const appConfig: IAppConfig = {
  prod: {
    coreApiHost: "https://core-api.dataships.io",
    controlPanelHost: "https://app.dataships.io/admin",
    webAppHost: "https://web-app.dataships.io",
    googleClientId:
      "456261783090-4ujflluapeoleg4lj33joiu7vk9at305.apps.googleusercontent.com",
    flagsmithId: "Bajv6H4etYqeFPS5z92jjm",
    flagsmithHost: "https://edge.api.flagsmith.com/api/v1/",
  },
  staging: {
    coreApiHost: "https://core-api.dataships.eu",
    controlPanelHost: "https://app.dataships.eu/admin",
    webAppHost: "https://web-app.dataships.eu",
    googleClientId:
      "456261783090-1fud21n5bb18hmctf5euskpjb2j40c17.apps.googleusercontent.com",
    flagsmithId: "Hy3fX5HrL9gXMdqqrKeBxX",
    flagsmithHost: "https://edge.api.flagsmith.com/api/v1/",
  },
  dev: {
    coreApiHost: "https://core-api.datashipslocal.com",
    controlPanelHost: "https://app.datashipslocal.com/admin",
    webAppHost: "https://web-app.datashipslocal.com",
    googleClientId:
      "456261783090-7321sd8jmkv3dabanlpd7erv5eks42vc.apps.googleusercontent.com",
    flagsmithId: "hRyXbduWiSQ2aRYHhVp2nR",
    flagsmithHost: "https://edge.api.flagsmith.com/api/v1/",
  },
};

export const appEnvironments: IAppEnvironmentMap = {
  "admin.dataships.io": "prod",
  "admin.dataships.eu": "staging",
  "admin.datashipslocal.com": "dev",
};
