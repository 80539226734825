import SmsOnboardingProgressButtons from "@/components/Buttons/SmsOnboardingProgressButtons";
import { Instructions } from "@/components/Typography";
import { Box, Paper, Stack, Typography } from "@mui/material";

const EditPolicies = () => {
  const renderHeading = () => (
    <Box>
      <Typography variant="h6" color="secondary">
        Update Privacy Policy and Terms of Service in Shopify
      </Typography>
      <Typography variant="body2">
        We are now going to update your Privacy Policy and Terms of Service in
        Shopify with SMS marketing information.
      </Typography>
    </Box>
  );

  const privacyPolicyInstructions = [
    "Please go back to your Dataships Support page ('Shopify Setup Instructions' step 2 & 3). Please copy and paste the text into the editor at the end of your existing document.",
    "Copy and paste the first snippet of text into the editor in an appropriate location in the policy. This is usually in the section that explains what the personal data is used for, such as 'How We Use The Information'.",
    "Copy and paste the second snippet of text into the editor in an appropriate location in the policy. This could be a new paragraph just below where the first snippet of text was pasted.",
    "Let's read through the privacy policy together to ensure it doesn’t have any other sections that contradict the snippets, such as statements that say emails/SMS will only be sent with consent.",
    "When finished, please click Save.",
  ];

  const renderPrivacyPolicyInstructions = () => (
    <Box>
      <Instructions
        title="Update Privacy Policy in Shopify with SMS marketing information"
        instructions={privacyPolicyInstructions}
        ordered
      />
    </Box>
  );


  const termsOfServiceInstructions = [
    <>
      <span>Let's check if you have an existing Terms of Service document. Click on Terms of Service</span>
      <Box component="ul" sx={{ listStyleType: "lower-alpha" }} mt={1}>
        <li>
          (Only if there is an existing Terms of Service there) Let's remove any
          section that relate to SMS or Mobile Terms of Service if they exist.
          Once that is done, click Save.
        </li>
      </Box>
    </>,
    "Please go back to your Dataships Support page ('Shopify Setup Instructions' step 4).  Please copy and paste the text into the editor at the end of your existing document.",
    "If you don't, then please copy and paste the provided text into the editor",
    "If you do, then let's remove any section that relates to SMS or Mobile Terms of Service if it's there",
    "Please copy and paste the text into the editor at the end of your existing document.",
    "When finished, please click Save.",
  ];

  const renderTermsOfServiceInstructions = () => (
    <Box>
      <Instructions
        title="Update Terms of Service in Shopify with SMS marketing information"
        instructions={termsOfServiceInstructions}
        ordered
      />
    </Box>
  );

  const renderStepButtons = () => (
    <SmsOnboardingProgressButtons
      currentUrl="edit-policies"
      previousStepUrl="create-klaviyo-lists"
      nextStepUrl="add-extension"
    />
  );

  return (
    <Paper elevation={1} sx={{ p: 3, minHeight: "100%" }}>
      <Stack direction="column" gap={3}>
        {renderHeading()}
        {renderPrivacyPolicyInstructions()}
        {renderTermsOfServiceInstructions()}
        {renderStepButtons()}
      </Stack>
    </Paper>
  );
};

export default EditPolicies;
