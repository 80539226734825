import { ProtectedRoute, UnprotectedRoute } from "@/navigation";

interface IProps {
  isProtected: boolean;
  children: React.ReactNode;
}

const AppMiddleware = ({ isProtected = true, children }: IProps) => {
  if (isProtected) return <ProtectedRoute>{children}</ProtectedRoute>;

  return <UnprotectedRoute>{children}</UnprotectedRoute>;
};

export default AppMiddleware;
