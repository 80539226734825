import {
  RenderAbTestStatus,
  RenderAbTestConfig,
  RenderAuditableDate,
  RenderAbTestDownload,
} from "@/components/Table";
import { useAllAbTest } from "@/requests/hooks/abTest";
import { updateSearchParams } from "@/utils/updateSearchParams";
import { Box, Typography, Link, Stack, Paper } from "@mui/material";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom";

const AbTestAll = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;
  const [searchParams, setSearchParams] = useSearchParams({});
  const [rowCount, setRowCount] = useState<number>(0);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [sortModel, setSortModel] = useState({
    sortBy: searchParams.get("sortBy") || "",
    sortOrder: searchParams.get("sortOrder") || "",
  });

  const { data: allAbTestsData, isLoading: isAllAbTestsLoading } = useAllAbTest(
    {
      customerId: id,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      sortBy: sortModel.sortBy || "id",
      sortOrder: sortModel.sortOrder || "desc",
    },
  );

  useEffect(() => {
    updateSearchParams(
      {
        sortBy: sortModel.sortBy,
        sortOrder: sortModel.sortOrder,
      },
      setSearchParams,
    );
  }, [sortModel]);

  useEffect(() => {
    setRowCount(prevRowCountState =>
      allAbTestsData?.totalResults !== undefined
        ? allAbTestsData?.totalResults
        : prevRowCountState,
    );
  }, [allAbTestsData]);

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (!sortModel.length) {
      setSortModel({
        sortBy: "",
        sortOrder: "",
      });
      return;
    }

    const sortFields = sortModel[0];
    if (sortFields) {
      setSortModel({
        sortBy: sortFields.field,
        sortOrder: sortFields.sort || "",
      });
    }
  };

  const renderPageHeader = () => (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h6">A/B Testing (All)</Typography>
      <Typography
        variant="h6"
        color="primary"
        display="flex"
        alignItems="center">
        <Link component={RouterLink} to={`/customer/${id}/ab-testing`}>
          Latest A/B Test
        </Link>
      </Typography>
    </Box>
  );

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Status",
      flex: 1,
      renderCell: RenderAbTestStatus,
      maxWidth: 150,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      renderCell: params =>
        RenderAuditableDate({ date: params.value, user: params.row.startedBy }),
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      renderCell: params =>
        RenderAuditableDate({ date: params.value, user: params.row.stoppedBy }),
    },
    {
      field: "widgetConfig",
      headerName: "Config",
      flex: 1,
      renderCell: RenderAbTestConfig,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Created",
      flex: 1,
      renderCell: params =>
        RenderAuditableDate({ date: params.value, user: params.row.createdBy }),
      sortable: false,
    },
    {
      field: "privacyPolicyLinkPresent", // this just needs to be an actual field returned
      headerName: "Download",
      flex: 1,
      renderCell: RenderAbTestDownload,
      sortable: false,
    },
  ];

  const renderTable = () => (
    <DataGrid
      initialState={{
        sorting: {
          sortModel: [{ field: "id", sort: "desc" }],
        },
      }}
      rowHeight={60}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      loading={isAllAbTestsLoading}
      columns={columns}
      rows={allAbTestsData?.results || []}
      rowCount={rowCount}
      pagination
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={[10, 25, 50, 100]}
      autoHeight
    />
  );

  return (
    <Stack direction="column" gap={3}>
      {renderPageHeader()}
      <Paper elevation={1}>{renderTable()}</Paper>
    </Stack>
  );
};

export default AbTestAll;
