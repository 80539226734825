export const updateSearchParams = (
  fields: ISearchParamsFields,
  callbackFn: (params: ISearchParamsFields) => void,
) => {
  const newSearchParams: ISearchParamsFields = {};

  for (const [key, value] of Object.entries(fields)) {
    if (!!value) newSearchParams[key] = value;
  }

  callbackFn(newSearchParams);
};
