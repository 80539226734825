import { OptInCheckProgress } from "@/components/SmsOnboarding";
import {
  useGetFirstOptInCheck,
  useGetSecondOptInCheck,
  useSendSmsOnboardingCompletionEmail,
  useSmsOnboardingProgress,
  useTriggerFirstOptInCheck,
  useTriggerSecondOptInCheck,
} from "@/requests/hooks/customer";
import formatError from "@/utils/formatError";
import { ErrorTwoTone, SendTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, List, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Complete = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const [isFirstOptInCheckEnabled, setIsFirstOptInCheckEnabled] =
    useState<boolean>(false);
  const [isSecondOptInCheckEnabled, setIsSecondOptInCheckEnabled] =
    useState<boolean>(false);

  const {
    data: smsOnboardingData,
    refetch: refetchSmsOnboardingData,
    isLoading: isSmsOnboardingDataLoading,
  } = useSmsOnboardingProgress(id);

  const {
    mutate: triggerFirstOptInCheck,
    isSuccess: isTriggerFirstOptInCheckSuccess,
  } = useTriggerFirstOptInCheck();

  const { data: firstOptInCheckData, isLoading: isFirstOptInCheckLoading } =
    useGetFirstOptInCheck({
      id,
      enabled: isFirstOptInCheckEnabled,
    });

  const {
    mutate: triggerSecondOptInCheck,
    isSuccess: isTriggerSecondOptInCheckSuccess,
  } = useTriggerSecondOptInCheck();

  const { data: secondOptInCheckData, isLoading: isSecondOptInCheckLoading } =
    useGetSecondOptInCheck({
      id,
      enabled: isSecondOptInCheckEnabled,
    });

  const {
    mutate: sendCompletionEmail,
    isSuccess: isSendCompletionEmailSuccess,
    isError: isSendCompletionEmailError,
    error: sendCompletionEmailError,
    isPending: isSendCompletionEmailPending,
  } = useSendSmsOnboardingCompletionEmail();

  useEffect(() => {
    if (isSendCompletionEmailSuccess) {
      toast("Successfully sent email", { type: "success" });
      refetchSmsOnboardingData();
    }

    if (isSendCompletionEmailError) {
      const errors = formatError(sendCompletionEmailError);
      if (errors?.codes?.includes("COMPLIANCE_CHECKS_NOT_ALL_PASSED")) {
        toast("Failed to send email. Compliance checks not completed.", {
          type: "error",
        });
      } else {
        toast("Failed to send email", { type: "error" });
      }
    }
  }, [isSendCompletionEmailSuccess, isSendCompletionEmailError]);

  useEffect(() => {
    if (isTriggerFirstOptInCheckSuccess) setIsFirstOptInCheckEnabled(true);
    if (isTriggerSecondOptInCheckSuccess) setIsSecondOptInCheckEnabled(true);
  }, [isTriggerFirstOptInCheckSuccess, isTriggerSecondOptInCheckSuccess]);

  const handleTriggerFirstOptInCheck = () => triggerFirstOptInCheck({ id });

  const handleTriggerSecondOptInCheck = () => triggerSecondOptInCheck({ id });

  const handleSendEmail = () => sendCompletionEmail({ id });

  const getIsEmailDisabled = () => {
    return (
      isSmsOnboardingDataLoading || // Uber get isn't finished loading
      smsOnboardingData?.questionnaire?.status !== "PASS" || // Questionnaire hasn't been filled out
      smsOnboardingData?.companyDetails?.status !== "PASS" || // Company details haven't been filled out
      smsOnboardingData?.complianceChecks?.status !== "PASS" || // Not all compliance checks have been confirmed
      smsOnboardingData?.klaviyoLists?.status !== "PASS" || // Klaviyo lists haven't been created and/or haven't been updated to Single opt-in
      smsOnboardingData?.shopifyPolicies?.status !== "PASS" || // Policies step hasn't been completed
      smsOnboardingData?.shopifyExtension?.status !== "PASS" || // Extension step hasn't been completed
      smsOnboardingData?.shopifySms?.status !== "PASS" || // Disable Shopify SMS step hasn't been completed
      /**
       * Opt in checks not being passed and/or finished should block the button
       */
      smsOnboardingData?.shopifySms?.firstOptInCheck !== "PASS" || // First opt in check hasn't passed or is running
      smsOnboardingData?.klaviyoLists?.secondOptInCheck !== "PASS" || // Second opt in check hasn't passed or is running
      /**
       * We need to check specifically if the complete step is PASS here
       */
      smsOnboardingData?.complete?.status === "PASS" // SMS Onboarding is already completed
    );
  };

  const getSendEmailButtonText = () => {
    if (smsOnboardingData?.complete?.status === "PASS")
      return "Email already sent";
    if (getIsEmailDisabled()) return "Onboarding steps not complete";
    return "Send Welcome Email";
  };

  const getFirstOptInCheckStatus = () =>
    firstOptInCheckData?.status ||
    smsOnboardingData?.shopifySms?.firstOptInCheck ||
    "FAIL";

  const getSecondOptInCheckStatus = () =>
    secondOptInCheckData?.status ||
    smsOnboardingData?.klaviyoLists?.secondOptInCheck ||
    "FAIL";

  const renderHeading = () => (
    <Typography variant="h6" color="secondary">
      Completion Status
    </Typography>
  );

  const renderChecks = () => (
    <List>
      <OptInCheckProgress
        title="First Opt-in Check"
        isLoading={isFirstOptInCheckLoading}
        status={getFirstOptInCheckStatus()}
        buttonAction={handleTriggerFirstOptInCheck}
        buttonText={"Refresh"}
      />
      <OptInCheckProgress
        title="Second Opt-in Check"
        isLoading={isSecondOptInCheckLoading}
        status={getSecondOptInCheckStatus()}
        buttonAction={handleTriggerSecondOptInCheck}
        buttonText={"Refresh"}
      />
    </List>
  );

  const renderEmailButton = () => {
    const isDisabled = getIsEmailDisabled();
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gap={3}>
        <Alert color="warning" icon={<ErrorTwoTone />}>
          Once all checks have passed, and you haven't already sent the welcome
          email, you can click the button below to send the welcome email to all
          Setup Contacts associated with this customer.{" "}
          <strong>
            This final step is required to enable the Dataships SMS widget on
            the customers store.
          </strong>
        </Alert>

        <LoadingButton
          disabled={isDisabled}
          loading={isSendCompletionEmailPending}
          variant="contained"
          color="secondary"
          size="large"
          endIcon={!isDisabled && <SendTwoTone />}
          onClick={handleSendEmail}>
          {getSendEmailButtonText()}
        </LoadingButton>
      </Box>
    );
  };

  return (
    <Paper elevation={1} sx={{ p: 3, minHeight: "100%" }}>
      {renderHeading()}
      {renderChecks()}
      {renderEmailButton()}
    </Paper>
  );
};

export default Complete;
