import {
  useCustomer,
  useSendSmsOnboardingSetupEmail,
} from "@/requests/hooks/customer";
import {
  EmailTwoTone,
  PersonOffTwoTone,
  SendTwoTone,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface IProps {
  open: boolean;
  onClose: () => void;
}

const SendSmsOnboardingLinkModal = ({ open, onClose }: IProps) => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;
  const { data: customerData } = useCustomer(id);
  const {
    mutate: sendSetupEmail,
    isPending: isSendSetupEmailPending,
    isSuccess: isSendSetupEmailSuccess,
    isError: isSendSetupEmailError,
  } = useSendSmsOnboardingSetupEmail();

  useEffect(() => {
    if (isSendSetupEmailSuccess) {
      toast("Successfully send setup email", {
        type: "success",
      });
      onClose();
    }

    if (isSendSetupEmailError) {
      toast("Failed to send setup email", { type: "error" });
    }
  }, [isSendSetupEmailSuccess, isSendSetupEmailError]);

  const handleSendSetupEmail = () => sendSetupEmail({ id });

  const renderDialogTitle = () => (
    <DialogTitle>
      Send Setup Email to {customerData?.companyName} Users
    </DialogTitle>
  );

  const renderDialogDescription = () => (
    <DialogContentText mb={2}>
      This will send the unique SMS onboarding link to all{" "}
      {customerData?.companyName} users with the <strong>Setup Contact</strong>{" "}
      role.
    </DialogContentText>
  );

  const renderSetupContactUsers = () => {
    const setupContacts = customerData?.users?.filter(user =>
      user.personas.includes("SETUP_CONTACT"),
    );

    const renderList = () =>
      setupContacts?.map(({ firstName, lastName, email }, index) => (
        <ListItem key={index}>
          <ListItemIcon>
            <EmailTwoTone />
          </ListItemIcon>
          <ListItemText primary={`${firstName} ${lastName} (${email})`} />
        </ListItem>
      ));

    return (
      <>
        <Typography variant="body2">Setup Contact Users</Typography>
        <List>
          {setupContacts?.length ? (
            renderList()
          ) : (
            <Alert
              icon={<PersonOffTwoTone fontSize="inherit" />}
              severity="info">
              No setup contact users found. You can update users{" "}
              <Link to={`/customer/${id}/users`}>here.</Link>
            </Alert>
          )}
        </List>
      </>
    );
  };

  const renderDialogButtons = () => (
    <DialogActions>
      <Button color="error" onClick={onClose}>
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        color="secondary"
        loading={isSendSetupEmailPending}
        disabled={isSendSetupEmailPending}
        onClick={handleSendSetupEmail}
        loadingPosition="end"
        endIcon={<SendTwoTone />}>
        Send Email
      </LoadingButton>
    </DialogActions>
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      {renderDialogTitle()}
      <DialogContent>
        {renderDialogDescription()}
        {renderSetupContactUsers()}
      </DialogContent>
      {renderDialogButtons()}
    </Dialog>
  );
};

export default SendSmsOnboardingLinkModal;
