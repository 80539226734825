import { useEffect, useState } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useExtendTrial } from "@/requests/hooks/customer";

const RenderExtendTrial = ({
  params,
  triggerRefresh,
}: {
  params: GridRenderCellParams;
  triggerRefresh: () => void;
}) => {
  const {
    row: { id, product, endDate, version, cancelled },
  } = params;

  const {
    mutate: extendTrial,
    isPending: isExtendTrialPending,
    isSuccess: isExtendTrialSuccess,
    error: extendTrialError,
  } = useExtendTrial();

  useEffect(() => {
    if (isExtendTrialSuccess) {
      handleClickClose();
      triggerRefresh();
    }
  }, [isExtendTrialSuccess]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [extendDate, setExtendDate] = useState<Dayjs | null>(dayjs(endDate));

  const maxDate = dayjs(endDate).add(1, "year");

  const handleClickOpen = () => setIsDialogOpen(true);
  const handleClickClose = () => setIsDialogOpen(false);

  const getExtensionDifference = () => {
    const originalDate = dayjs(endDate);
    const extensionDate = dayjs(extendDate);

    return extensionDate.diff(originalDate, "days");
  };

  const isButtonDisabled = () =>
    dayjs(endDate).format("YYYY-MM-DD") ===
    dayjs(extendDate).format("YYYY-MM-DD");

  const handleExtendtrial = async () => {
    extendTrial({
      id,
      body: {
        endDate: extendDate?.toISOString()!,
        version,
      },
    });
  };

  const renderCalendar = () => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        openTo="day"
        value={extendDate}
        onChange={setExtendDate}
        minDate={dayjs(endDate)}
        maxDate={maxDate}
        format="YYYY-MM-DD"
      />
    </LocalizationProvider>
  );

  const renderCellButton = () => (
    <Tooltip
      title={cancelled ? "Cannot extend cancelled trial" : "Extend Trial"}>
      <span>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={handleClickOpen}
          disabled={cancelled}>
          Extend
        </Button>
      </span>
    </Tooltip>
  );

  const renderDialogTitle = () => (
    <DialogTitle sx={{ textAlign: "center" }}>
      Extend {product} Trial
    </DialogTitle>
  );

  const renderDialogContent = () => (
    <DialogContent sx={{ textAlign: "center" }}>
      {!!extendTrialError?.message && (
        <Alert severity="error">{extendTrialError.message}</Alert>
      )}
      <DialogContentText>
        Please select a new end date for the trial using the calendar below.
      </DialogContentText>

      {renderCalendar()}

      <DialogContentText>
        You are extending the trial by{" "}
        <span style={{ fontWeight: 700 }}>{getExtensionDifference()}</span>{" "}
        days.
      </DialogContentText>
    </DialogContent>
  );

  const renderDialogActions = () => (
    <DialogActions>
      <Button onClick={handleClickClose}>Cancel</Button>
      <LoadingButton
        loading={isExtendTrialPending}
        disabled={isButtonDisabled()}
        onClick={handleExtendtrial}>
        Confirm
      </LoadingButton>
    </DialogActions>
  );

  return (
    <>
      {renderCellButton()}

      <Dialog
        open={isDialogOpen}
        onClose={handleClickClose}
        fullWidth
        maxWidth="sm">
        {renderDialogTitle()}
        {renderDialogContent()}
        {renderDialogActions()}
      </Dialog>
    </>
  );
};

export default RenderExtendTrial;
