import { HelpOutlineTwoTone, HistoryTwoTone } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { ReactNode } from "react";
import { formatValue } from "@/utils/helpers";

interface IProps {
  stat: IMetric;
  trendingValue?: number | string;
  trendingTimeFrame?: string;
  timeFrameOverride?: ReactNode;
  onClickOverride?: () => void;
}

const StatTile = ({
  stat,
  trendingValue,
  trendingTimeFrame,
  timeFrameOverride,
  onClickOverride,
}: IProps) => {
  const {
    title,
    value,
    description,
    metricType,
    format,
    drillDown,
    timeFrame,
    lastUpdatedAt,
  } = stat;

  const renderValue = () =>
    formatValue({
      value,
      format,
    });

  const renderCardHeading = () => (
    <Box
      display="flex"
      alignItems="start"
      justifyContent="space-between"
      gap={1}>
      <Typography
        variant="h6"
        height={64}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        }}>
        {title}
      </Typography>
      <Tooltip title={description} placement="top" sx={{ marginTop: "4px" }}>
        <HelpOutlineTwoTone color="info" />
      </Tooltip>
    </Box>
  );

  const renderTrendingValue = () =>
    trendingValue !== null &&
    !!trendingTimeFrame && (
      <Typography variant="caption" component={"div"}>
        {trendingValue} over {trendingTimeFrame}
      </Typography>
    );

  const renderCardValue = () => (
    <Typography
      variant="h2"
      component="div"
      fontWeight={500}
      height={92}
      data-testid="statistics-panel-value"
      textAlign="right">
      <>
        {renderValue()}
        {renderTrendingValue()}
      </>
    </Typography>
  );

  const renderTimeframe = () =>
    !!timeFrame && (
      <>
        <Typography variant="body2" fontWeight={500} color="gray">
          {timeFrame}
        </Typography>
        <Tooltip
          title={`Last updated: ${dayjs(lastUpdatedAt).format(
            "YYYY-MM-DD HH:mm",
          )}`}
          placement="top">
          <HistoryTwoTone color="info" />
        </Tooltip>
      </>
    );

  const renderCardFooter = () => (
    <Box
      display="flex"
      alignItems="end"
      justifyContent="space-between"
      mt={1}
      gap={1}
      height={31}>
      {!!timeFrameOverride ? timeFrameOverride : renderTimeframe()}
    </Box>
  );

  const renderCardContent = () => (
      <CardContent>
          {renderCardHeading()}
          {renderCardValue()}
          {renderCardFooter()}
      </CardContent>
  );

  const renderCardActionArea = () => {
    if (hasDrilldown) {
      return (
        <CardActionArea onClick={onClickOverride} component={cardActionComponent} to={cardActionTarget}>
          {renderCardContent()}
        </CardActionArea>
      );
    }

    return renderCardContent();
  };

  const hasDrilldown = onClickOverride || drillDown;
  const cardActionComponent = drillDown ? Link : 'div';
  const cardActionTarget = drillDown ? `drilldown/${metricType}` : undefined;

  return (
    <Card variant="outlined">
      {renderCardActionArea()}
    </Card>
  );
};

export default StatTile;
