import {
  ChangeEvent,
  FormEvent,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import { LoginTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useLoginAsCustomer } from "@/requests/hooks/user";
import { getAppConfig } from "@/utils/appManager";

interface IProps {
  customerId: number;
}

const LoginAsCustomerButton = ({ customerId }: IProps) => {
  const { webAppHost } = getAppConfig();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [reason, setReason] = useState("");

  const {
    mutate: loginAsCustomer,
    isPending: isLoginAsCustomerPending,
    isSuccess: isLoginAsCustomerSuccess,
  } = useLoginAsCustomer();

  useEffect(() => {
    if (isLoginAsCustomerSuccess) {
      window.location.replace(`${webAppHost}/settings/user-management`);
    }
  }, [isLoginAsCustomerSuccess]);

  const handleLoginAsCustomer = async (e: FormEvent) => {
    e.preventDefault();
    loginAsCustomer({ customerId, reason });
  };

  const onTextChange = (event: ChangeEvent<HTMLInputElement>) =>
    setReason(event.target.value);

  const toggleDialog = () => setIsOpen(isOpen => !isOpen);

  const renderDialog = () => (
    <Dialog open={isOpen} onClose={toggleDialog}>
      <form onSubmit={handleLoginAsCustomer}>
        <DialogTitle>Reason for Admin Login</DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>
            Please enter the reason why you want to log in to this customer as
            an admin.
          </DialogContentText>
          <TextField
            autoFocus
            id="reason"
            label="Reason"
            type="text"
            variant="outlined"
            fullWidth
            multiline={false}
            value={reason}
            onChange={onTextChange}
            disabled={isLoginAsCustomerPending}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog} color="error">
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            disabled={!reason}
            loading={isLoginAsCustomerPending}
            loadingPosition="start"
            startIcon={<LoginTwoTone />}
            variant="contained"
            color="secondary">
            Log in
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );

  return (
    <>
      <Tooltip title="Log in as Customer">
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={toggleDialog}>
          <LoginTwoTone />
        </Button>
      </Tooltip>

      {renderDialog()}
    </>
  );
};

export default LoginAsCustomerButton;
