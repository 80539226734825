import { Error } from "@/components/Error";
import { SmsOnboardingLoader } from "@/components/Loaders";
import { SendSmsOnboardingLinkModal } from "@/components/Modals";
import steps, { IOnboardingStep } from "@/navigation/smsOnboardingSteps";
import { useSmsOnboardingProgress } from "@/requests/hooks/customer";
import { checkStepStatus } from "@/utils/smsOnboarding/validation";
import { ContentCopyTwoTone, EmailTwoTone } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  SvgIcon,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const SmsOnboardingLayout = () => {
  const { pathname } = useLocation();
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;
  const { palette } = useTheme();

  const [
    isSendSmsOnboardingLinkModalOpen,
    setIsSendSmsOnboardingLinkModalOpen,
  ] = useState<boolean>(false);

  const {
    data: smsOnboardingData,
    isLoading: isSmsOnboardingLoading,
    isRefetching: isSmsOnboardingRefetching,
    isError: isSmsOnboardingError,
    error: smsOnboardingError,
  } = useSmsOnboardingProgress(id);

  const toggleModal = () =>
    setIsSendSmsOnboardingLinkModalOpen(
      isSendSmsOnboardingLinkModalOpen => !isSendSmsOnboardingLinkModalOpen,
    );

  const handleCopyToClipboard = () => {
    if (smsOnboardingData?.smsOnboardingContentUrl) {
      navigator.clipboard.writeText(smsOnboardingData?.smsOnboardingContentUrl);
      toast("Copied link to clipboard", { type: "success" });
    }
  };

  const getStepIsActive = (url: IOnboardingStep["url"]) =>
    pathname.endsWith(url);

  const renderCopyUrl = () => {
    const questionnaireStep = steps.find(
      step => step.identifier === "questionnaire",
    );
    const isQuestionnaireStepComplete =
      checkStepStatus({
        data: smsOnboardingData!,
        step: questionnaireStep!,
        isRefetching: isSmsOnboardingRefetching,
        activePath: pathname,
      })?.calculatedStatus === "PASS";

    return (
      <Paper
        elevation={1}
        sx={{
          mb: 3,
        }}>
        <Box display="flex" alignItems="center">
          <Box
            flex={1}
            width={100}
            whiteSpace="nowrap"
            overflow="scroll"
            textOverflow="ellipsis"
            px={3}
            fontFamily="monospace">
            {isQuestionnaireStepComplete
              ? smsOnboardingData?.smsOnboardingContentUrl
              : "Please fill out the Questionnaire step to enable the customer facing onboarding URL."}
          </Box>
          <Box display="flex">
            <Divider orientation="vertical" flexItem />
            <Tooltip title="Email link to Customer" placement="left">
              <span>
                <IconButton
                  aria-label="email link to customer"
                  size="large"
                  disabled={!isQuestionnaireStepComplete}
                  onClick={toggleModal}>
                  <EmailTwoTone />
                </IconButton>
              </span>
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            <Tooltip title="Copy URL to Clipboard" placement="left">
              <span>
                <IconButton
                  aria-label="copy to clipboard"
                  size="large"
                  disabled={!isQuestionnaireStepComplete}
                  onClick={handleCopyToClipboard}>
                  <ContentCopyTwoTone />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Paper>
    );
  };

  const renderSteps = () =>
    steps.map((step, index) => {
      const { label, url } = step;

      const {
        icon: dynamicIcon,
        color,
        sx,
        calculatedStatus,
      } = checkStepStatus({
        data: smsOnboardingData!,
        step,
        isRefetching: isSmsOnboardingRefetching,
        activePath: pathname,
      });

      const descriptionMapping = {
        PASS: "Complete",
        PENDING: "Pending...",
        NEEDS_ATTENTION: "Needs attention",
        FAIL: "An error has occurred",
        NOT_STARTED: "Not started",
      };

      return (
        <ListItem key={index} disablePadding>
          <ListItemButton
            component={Link}
            to={url}
            selected={getStepIsActive(url)}>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: palette[color]?.main }}>
                <SvgIcon component={dynamicIcon} sx={sx} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={label}
              secondary={descriptionMapping[calculatedStatus]}
            />
          </ListItemButton>
        </ListItem>
      );
    });

  const renderSendSmsOnboardingLinkModal = () => (
    <SendSmsOnboardingLinkModal
      open={isSendSmsOnboardingLinkModalOpen}
      onClose={toggleModal}
    />
  );

  if (isSmsOnboardingLoading && !isSmsOnboardingRefetching)
    return <SmsOnboardingLoader />;

  if (isSmsOnboardingError) return <Error error={smsOnboardingError} />;

  return (
    <>
      {renderCopyUrl()}
      <Box display="flex" gap={3}>
        <Box flexShrink={0}>
          <Paper
            elevation={1}
            sx={{ minWidth: 300, position: "sticky", top: 88 }}>
            {renderSteps()}
          </Paper>
        </Box>
        <Box flexGrow={1} alignItems="strech">
          <Outlet />
        </Box>
      </Box>
      {renderSendSmsOnboardingLinkModal()}
    </>
  );
};

export default SmsOnboardingLayout;
