import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Delete } from "@mui/icons-material";
import { useDeleteSmsOnboardingCountrySetting } from "@/requests/hooks/customer";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { translateEnumToPrettyPrint } from "@/utils/translationMapping";

interface IProps {
  activeNumber: ISmsCountrySetting;
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}

const SmsDeletePhoneNumberModal = ({
  activeNumber,
  open,
  onClose,
  refetch,
}: IProps) => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const {
    mutate: deleteCountrySetting,
    isPending: isDeleteCountrySettingPending,
    isSuccess: isDeleteCountrySettingSuccess,
    isError: isDeleteCountrySettingError,
  } = useDeleteSmsOnboardingCountrySetting();

  useEffect(() => {
    if (isDeleteCountrySettingSuccess) {
      toast("Deleted SMS country setting", { type: "success" });
      refetch();
      onClose();
    }

    if (isDeleteCountrySettingError) {
      toast("Failed to delete SMS country setting", { type: "error" });
    }
  }, [isDeleteCountrySettingSuccess, isDeleteCountrySettingError]);

  const handleDelete = () =>
    deleteCountrySetting({
      id,
      settingId: activeNumber.id!,
      body: {
        version: activeNumber.version!,
      },
    });

  const renderDialogTitle = () => (
    <DialogTitle>Delete Phone Number</DialogTitle>
  );

  const renderDialogDescription = () => (
    <DialogContentText>
      Are you sure you want to delete this phone number?
    </DialogContentText>
  );

  const renderConfirmationTable = () => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Property</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Country</TableCell>
          <TableCell>{activeNumber?.countryName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Phone Number</TableCell>
          <TableCell>{activeNumber?.phoneNumber}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Phone Number Type</TableCell>
          <TableCell>
            {translateEnumToPrettyPrint(activeNumber?.phoneNumberType)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  const renderDialogButtons = () => (
    <DialogActions sx={{ mt: 2 }}>
      <Button color="primary" onClick={onClose}>
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        color="error"
        loading={isDeleteCountrySettingPending}
        loadingPosition="start"
        startIcon={<Delete />}
        onClick={handleDelete}>
        Delete
      </LoadingButton>
    </DialogActions>
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      {renderDialogTitle()}
      <DialogContent>{renderDialogDescription()}</DialogContent>
      {renderConfirmationTable()}
      {renderDialogButtons()}
    </Dialog>
  );
};

export default SmsDeletePhoneNumberModal;
