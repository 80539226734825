import {
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { object, string, number, InferType } from "yup";

export const discountFormSchema = object({
  discountNote: string().required().label("Discount Note"),
  discountPercentage: number()
    .transform(value => (isNaN(value) ? undefined : value))
    .min(1)
    .max(100)
    .required()
    .label("Discount Percentage"),
  billingCycles: number()
    .transform(value => (isNaN(value) ? undefined : value))
    .min(1)
    .required()
    .label("Billing Cycles"),
  endOnSubscriptionChange: string().required(),
});

export type IDiscountFormData = InferType<typeof discountFormSchema>;

interface IProps {
  showEndOnSubscriptionChange?: boolean;
  annualBillingSelected?: boolean;
}

const DiscountForm = ({
  annualBillingSelected = false,
  showEndOnSubscriptionChange = true,
}: IProps) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<IDiscountFormData>();

  return (
    <Stack spacing={2} direction="column">
      <TextField
        autoFocus
        id="discountNote"
        label="Discount Note"
        type="text"
        variant="outlined"
        fullWidth
        {...register("discountNote")}
        error={!!errors.discountNote}
        helperText={errors?.discountNote?.message}
      />

      <TextField
        type="number"
        variant="outlined"
        id="percentage"
        label="Discount Percentage"
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        {...register("discountPercentage")}
        error={!!errors.discountPercentage}
        helperText={errors?.discountPercentage?.message}
      />

      <TextField
        type="number"
        variant="outlined"
        id="billingCycles"
        label="Number of Billing Cycles"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {annualBillingSelected ? "years" : "months"}
            </InputAdornment>
          ),
        }}
        {...register("billingCycles")}
        error={!!errors.billingCycles}
        helperText={errors?.billingCycles?.message}
      />

      {showEndOnSubscriptionChange && (
        <>
          <FormControl>
            <FormLabel>End on Subscription Change</FormLabel>
            <Controller
              control={control}
              name="endOnSubscriptionChange"
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          <DialogContentText mt={2}>
            If Yes is selected, the next tier of the customers subscription will
            not be discounted if they reach that tier within this discounts
            billing cycle.
          </DialogContentText>
        </>
      )}
    </Stack>
  );
};

export default DiscountForm;
