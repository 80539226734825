import { Box, LinearProgress, Typography } from "@mui/material";

const SplashScreen = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background:
          "linear-gradient(to bottom right, #10A4FF 0%, #4ACE96 100%)",
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "33%",
          color: "white",
          textAlign: "center",
        }}>
        <Typography variant="subtitle1" fontWeight="bold">
          Loading Admin Panel...
        </Typography>
        <LinearProgress color="inherit" />
      </Box>
    </Box>
  );
};

export default SplashScreen;
