import { Divider, Typography } from "@mui/material";
import { TopLevelStatsGrid } from "@/components/StatsGrid";

const Overview = () => (
  <>
    <Typography variant="h4">Overview</Typography>
    <Divider sx={{ my: 3 }} />
    <TopLevelStatsGrid />
  </>
);

export default Overview;
