import SmsOnboardingProgressButtons from "@/components/Buttons/SmsOnboardingProgressButtons";
import { OptInCheckProgress } from "@/components/SmsOnboarding";
import { Instructions } from "@/components/Typography";
import {
  useCreateKlaviyoLists,
  useGetSecondOptInCheck,
  usePostCheckKlaviyoLists,
  useSmsOnboardingProgress,
  useTriggerSecondOptInCheck,
} from "@/requests/hooks/customer";
import { muiExtraStylesSpin } from "@/utils/muiExtraStyles";
import {
  ChangeCircleTwoTone,
  CheckCircleTwoTone,
  HighlightOffTwoTone,
  PlaylistAddTwoTone,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  List,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";

const KlaviyoLists = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;
  const {
    palette: { error, success },
  } = useTheme();

  const { data: smsOnboardingData } = useSmsOnboardingProgress(id);

  const {
    mutate: createKlaviyoLists,
    isSuccess: isCreateKlaviyoListsSuccess,
    isPending: isCreateKlaviyoListsPending,
  } = useCreateKlaviyoLists();

  const {
    data: checkKlaviyoListsData,
    isLoading: isCheckKlaviyoListsLoading,
    isRefetching: isCheckKlaviyoListsRefetching,
  } = usePostCheckKlaviyoLists({
    id,
    enabled:
      isCreateKlaviyoListsSuccess ||
      (!smsOnboardingData?.klaviyoLists?.listTypeCheck &&
        !!smsOnboardingData?.klaviyoLists?.lists),
  });

  const {
    mutate: triggerSecondOptInCheck,
    isSuccess: isTriggerSecondOptInSuccess,
    isPending: isTriggerSecondOptInCheckPending,
    error: triggerSecondOptInCheckError,
  } = useTriggerSecondOptInCheck();

  const handleCreateKlaviyoLists = () => createKlaviyoLists({ id });

  const handleSecondOptInCheckButtonAction = () =>
    triggerSecondOptInCheck({ id });

  const isGetSecondOptInCheckEnabled = () =>
    isTriggerSecondOptInSuccess ||
    // We trigger on a 409 error because this means processing is already in flight
    (triggerSecondOptInCheckError as AxiosError)?.response?.status === 409;

  const { data: secondOptInCheckData, isLoading: isSecondOptInCheckLoading } =
    useGetSecondOptInCheck({
      id,
      enabled: isGetSecondOptInCheckEnabled(),
    });

  const getSecondOptInCheckStatus = () =>
    secondOptInCheckData?.status ||
    smsOnboardingData?.klaviyoLists?.secondOptInCheck ||
    null;

  const instructions = [
    "Please go back to your Dataships Support page ('Klaviyo Setup Instructions' step 7)",
    "Search your lists to find the 'Dataships Marketable SMS (Single Opt-In)' list",
    "Click on the three dots menu, then click List settings, and then click Consent",
    "Select the Single opt-in radio button",
    "Click Save",
  ];

  const renderHeading = () => (
    <Box>
      <Typography variant="h6" color="secondary">
        Create Dataships SMS Lists in Klaviyo
      </Typography>
      <Typography variant="body2">
        We are now going to create your 2 new SMS lists and change one of them
        so that it is single opt-in enabled.
      </Typography>
    </Box>
  );

  const renderCreateListsButton = () => {
    const isDisabled =
      !smsOnboardingData?.complianceChecks?.klaviyoApiKey ||
      !!smsOnboardingData?.klaviyoLists?.lists;

    return (
      <LoadingButton
        disabled={isDisabled}
        loading={isCreateKlaviyoListsPending}
        variant="contained"
        color="secondary"
        startIcon={<PlaylistAddTwoTone />}
        loadingPosition="start"
        onClick={handleCreateKlaviyoLists}>
        {isDisabled ? "Lists already created" : "Create Lists"}
      </LoadingButton>
    );
  };

  const renderCreateKlaviyoListsButton = () => (
    <Box>
      <Instructions
        title="Create Klaviyo Lists (Customer Success only step)"
        instructions={[
          "Click the button below to create the Dataships SMS lists in Klaviyo for the customer.",
          <Stack direction="column" gap={3}>
            <strong>
              Ensure the Klaviyo API key from the previous step is active before
              completing this action.
            </strong>
            <Box>{renderCreateListsButton()}</Box>
          </Stack>,
        ]}
        customerSuccessOnly
      />
    </Box>
  );

  const renderListsTable = () => {
    const doubleOptInListId =
      smsOnboardingData?.klaviyoLists?.smsDatashipsDoubleOptInListId || "-";
    const singleOptInListId =
      smsOnboardingData?.klaviyoLists?.smsDatashipsSingleOptInListId || "-";
    const singleOptInListConfigured =
      checkKlaviyoListsData?.singleOptInListChecked ||
      smsOnboardingData?.klaviyoLists?.listTypeCheck ||
      false;

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Double Opt-in List ID</TableCell>
            <TableCell align="center">Single Opt-in List ID</TableCell>
            <TableCell align="center">Single Opt-in List Configured</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">{doubleOptInListId}</TableCell>
            <TableCell align="center">{singleOptInListId}</TableCell>
            <TableCell align="center">
              {isCheckKlaviyoListsLoading || isCheckKlaviyoListsRefetching ? (
                <ChangeCircleTwoTone sx={muiExtraStylesSpin} />
              ) : singleOptInListConfigured ? (
                <CheckCircleTwoTone sx={{ color: success.main }} />
              ) : (
                <HighlightOffTwoTone sx={{ color: error.main }} />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const renderListsInstructions = () => (
    <Box>
      <Instructions instructions={instructions} />
      <Box mt={3}>
        <Typography variant="subtitle2" color="secondary" mb={3}>
          Dataships Klaviyo Lists
        </Typography>
        {renderListsTable()}
      </Box>
    </Box>
  );

  const renderSecondOptInCheck = () => (
    <List>
      <OptInCheckProgress
        title="Second Opt-in Check"
        isLoading={
          isSecondOptInCheckLoading ||
          isTriggerSecondOptInSuccess ||
          isTriggerSecondOptInCheckPending
        }
        status={getSecondOptInCheckStatus()}
        buttonAction={handleSecondOptInCheckButtonAction}
        buttonText={"Perform Check"}
      />
    </List>
  );

  const renderStepButtons = () => (
    <SmsOnboardingProgressButtons
      currentUrl="create-klaviyo-lists"
      previousStepUrl="compliance-checks"
      nextStepUrl="edit-policies"
    />
  );

  return (
    <Paper elevation={1} sx={{ p: 3, minHeight: "100%"}}>
      <Stack direction="column" gap={3}>
        {renderHeading()}
        {renderCreateKlaviyoListsButton()}
        {renderSecondOptInCheck()}
        {renderListsInstructions()}
        {renderStepButtons()}
      </Stack>
    </Paper>
  );
};

export default KlaviyoLists;
