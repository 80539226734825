import { Outlet, createBrowserRouter } from "react-router-dom";
import { AppMiddleware } from "@/middleware";
import { AppLayout, CustomerLayout, SmsOnboardingLayout } from "@/layout";
import Login from "@/routes/Login";
import Home from "@/routes/Home";
import Overview from "@/routes/Overview";
import Timeline from "@/routes/Timeline";
import {
  CustomerStats,
  Timeline as CustomerTimeline,
  Billing,
  Cookiebot,
  WidgetSettings,
  IntegrationSettings
} from "@/routes/Customer";
import { Users, User } from "@/routes/Customer/Users";
import { AbTest, AbTestAll } from "@/routes/Customer/AbTest";
import {
  AddExtension,
  CompanyDetails,
  Complete,
  ComplianceChecks,
  DisableShopifySms,
  EditPolicies,
  KlaviyoLists,
  Questionnaire,
} from "@/routes/Customer/SmsOnboarding";
import Drilldown from "@/routes/Overview/Drilldown";

export default createBrowserRouter([
  {
    path: "/",
    element: (
      <AppMiddleware isProtected>
        <AppLayout hasSidebar={false}>
          <Outlet />
        </AppLayout>
      </AppMiddleware>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "overview",
        children: [
          {
            index: true,
            element: <Overview />,
          },
          {
            path: "drilldown/:metric",
            element: <Drilldown />,
          },
        ],
      },
      {
        path: "timeline",
        element: <Timeline />,
      },
    ],
  },
  {
    path: "customer/:id",
    element: (
      <AppMiddleware isProtected>
        <AppLayout hasSidebar={true}>
          <CustomerLayout />
        </AppLayout>
      </AppMiddleware>
    ),
    children: [
      {
        index: true,
        element: <CustomerStats />,
      },
      {
        path: "timeline",
        element: <CustomerTimeline />,
      },
      {
        path: "billing",
        element: <Billing />,
      },
      {
        path: "cookiebot",
        element: <Cookiebot />,
      },
      {
        path: "widget-settings",
        element: <WidgetSettings />,
      },
      {
        path: "integration-settings",
        element: <IntegrationSettings />,
      },
      {
        path: "users",
        children: [
          {
            index: true,
            element: <Users />,
          },
          {
            path: ":userId",
            element: <User />,
          },
        ],
      },
      {
        path: "drilldown/:metric",
        children: [
          {
            index: true,
            element: <Drilldown />,
          },
        ],
      },
      {
        path: "sms-onboarding",
        element: <SmsOnboardingLayout />,
        children: [
          {
            index: true,
            element: <Questionnaire />,
          },
          {
            path: "questionnaire",
            element: <Questionnaire />,
          },
          {
            path: "company-details",
            element: <CompanyDetails />,
          },
          {
            path: "compliance-checks",
            element: <ComplianceChecks />,
          },
          {
            path: "create-klaviyo-lists",
            element: <KlaviyoLists />,
          },
          {
            path: "edit-policies",
            element: <EditPolicies />,
          },
          {
            path: "add-extension",
            element: <AddExtension />,
          },
          {
            path: "disable-shopify-sms",
            element: <DisableShopifySms />,
          },
          {
            path: "complete",
            element: <Complete />,
          },
        ],
      },
      {
        path: "ab-testing",
        children: [
          {
            index: true,
            element: <AbTest />,
          },
          {
            path: "all",
            element: <AbTestAll />,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: (
      <AppMiddleware isProtected={false}>
        <Login />
      </AppMiddleware>
    ),
  },
]);
