import { CheckCircleTwoTone, InventoryTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { object, string, number, InferType } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateMarketableRate } from "@/requests/hooks/customer";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const updateMarketableRateFormSchema = object({
  rationale: string()
    .min(20, "Your rationale must be minimum of 20 characters")
    .required(
      "You must provide a rationale for updating the Pre-Dataships Marketable rate.",
    )
    .label("Rationale"),
  preDatashipsMarketableRate: number()
    .transform(value => (isNaN(value) ? undefined : value))
    .min(1)
    .max(100)
    .required()
    .label("Pre-Dataships Marketable Rate"),
});

type IUpdateMarketableRateFormData = InferType<
  typeof updateMarketableRateFormSchema
>;

interface IProps {
  open: boolean;
  onClose: () => void;
  refetchCustomer: () => void;
  marketableRateInfo: IPreDatashipsMarketableRate | null;
}

const UpdateMarketableRateModal = ({
  open,
  onClose,
  refetchCustomer,
  marketableRateInfo,
}: IProps) => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  const {
    mutate: updateMarketableRate,
    isPending: isUpdateMarketableRateLoading,
    isSuccess: isUpdateMarketableRateSuccess,
    isError: isUpdateMarketableRateError,
  } = useUpdateMarketableRate();

  useEffect(() => {
    if (isUpdateMarketableRateSuccess) {
      toast("Updated Pre-Dataships marketable rate successfully", {
        type: "success",
      });
      onClose();
      toggleConfirmationOpen();
      refetchCustomer();
      reset();
    }

    if (isUpdateMarketableRateError)
      toast("Unable to update Pre-Dataships marketable rate", {
        type: "error",
      });
  }, [isUpdateMarketableRateSuccess, isUpdateMarketableRateError]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    trigger: formTrigger,
    getValues,
    reset,
  } = useForm<IUpdateMarketableRateFormData>({
    resolver: yupResolver(updateMarketableRateFormSchema),
  });

  const onSubmit = ({
    rationale,
    preDatashipsMarketableRate,
  }: IUpdateMarketableRateFormData) => {
    updateMarketableRate({
      id,
      body: {
        id: marketableRateInfo?.id,
        version: marketableRateInfo?.version,
        rationale,
        preDatashipsMarketableRate,
      },
    });
  };

  const toggleConfirmationOpen = () =>
    setConfirmationOpen(confirmationOpen => !confirmationOpen);

  const validateAndOpenConfirmation = async () => {
    const validated = await formTrigger();
    if (validated) toggleConfirmationOpen();
  };

  const renderDialogTitle = () => (
    <DialogTitle>Update Pre-Dataships Marketable Rate</DialogTitle>
  );

  const renderConfirmationInfoRow = (label: string, value: string) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <span>{label}</span>
        <span>{value}</span>
      </Box>
    );
  };

  const renderConfirmationInformation = () => (
    <List>
      <ListItem>
        {renderConfirmationInfoRow("Rationale", getValues("rationale"))}
      </ListItem>
      <Divider />
      <ListItem>
        {renderConfirmationInfoRow(
          "Pre-Dataships Marketable Rate",
          `${getValues("preDatashipsMarketableRate")}%`,
        )}
      </ListItem>
    </List>
  );

  const renderConfirmationDialog = () => (
    <Dialog
      open={confirmationOpen}
      onClose={toggleConfirmationOpen}
      fullWidth
      maxWidth="xs">
      <DialogTitle>Confirm Pre-Dataships Marketable Rate</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          By clicking confirm this customers Pre-Dataships marketable rate will
          immediately update in their dashboard, changing their Unlocked
          Marketable Contacts and their Potential Unlocked Revenue.
        </DialogContentText>
        <DialogContentText>
          Clicking cancel will exit without any changes being made.
        </DialogContentText>
      </DialogContent>
      <Alert severity="warning">
        Please ensure you have included a strong rationale for making this
        change, with enough information so that it can relied upon in the
        future.
      </Alert>
      {renderConfirmationInformation()}
      <DialogActions>
        <Button color="error" onClick={toggleConfirmationOpen}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={isUpdateMarketableRateLoading}
          loadingPosition="start"
          startIcon={<CheckCircleTwoTone />}
          type="submit"
          form="update-marketable-rate-form">
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  const renderDialogDescription = () => (
    <DialogContentText mb={2}>
      The Pre-Dataships marketable rate is used to calculate how much value we
      provide to the customer. It is important that this number is defensible
      and reflects a strong rationale for why it is what it is.
    </DialogContentText>
  );

  const renderForm = () => (
    <form id="update-marketable-rate-form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} direction="column">
        <TextField
          type="number"
          variant="outlined"
          id="preDatashipsMarketableRate"
          label="Pre-Dataships Marketable Rate"
          fullWidth
          {...register("preDatashipsMarketableRate")}
          error={!!errors?.preDatashipsMarketableRate}
          helperText={errors?.preDatashipsMarketableRate?.message}
        />
        <TextField
          type="text"
          variant="outlined"
          id="rationale"
          label="Rationale"
          fullWidth
          {...register("rationale")}
          error={!!errors?.rationale}
          helperText={errors?.rationale?.message}
        />
      </Stack>
    </form>
  );

  const renderDialogButtons = () => (
    <DialogActions>
      <Button color="error" onClick={onClose}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<InventoryTwoTone />}
        type="button"
        onClick={validateAndOpenConfirmation}>
        Update
      </Button>
    </DialogActions>
  );

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        {renderDialogTitle()}
        <DialogContent>
          {renderDialogDescription()}
          {renderForm()}
        </DialogContent>
        {renderDialogButtons()}
      </Dialog>
      {renderConfirmationDialog()}
    </>
  );
};

export default UpdateMarketableRateModal;
