import SmsOnboardingProgressButtons from "@/components/Buttons/SmsOnboardingProgressButtons";
import { Instructions } from "@/components/Typography";
import { Box, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { HelpOutlineTwoTone } from "@mui/icons-material";

const DisableShopifySms = () => {
  const renderHeading = () => (
    <Box mb={3}>
      <Typography variant="h6" color="secondary">
        Disable Shopify SMS Marketing Collection
      </Typography>
      <Typography variant="body2">
        We are now going to disable the Shopify SMS consent collection in your
        Checkout settings. You may also need to optionally enable Shipping Phone
        Number collection.
      </Typography>
    </Box>
  );

  const disableShopifySmsInstructions = [
    "Please go back to your Shopify Checkout Settings page",
    "Find the 'Marketing Options' section",
    "Untick the SMS box so that Dataships is now the only collection method on your Checkout for SMS",
    "Click Save",
  ];

  const renderDisableShopifySmsInstructions = () => (
    <Box mb={3}>
      <Instructions
        title="Disable the Default Shopify SMS Marketing Collection"
        instructions={disableShopifySmsInstructions}
        ordered
      />
    </Box>
  );

  const enableShippingPhoneNumberInstructions = [
    <>
      <strong>Note to Customer Success:</strong> This applies only if during
      your pre-checks, you found that the customer did not have the Shipping
      Phone number field enabled in their Checkout. For more advice on this
      step, see the tooltip above.
    </>,
    "In Checkout Settings, scroll up and select Shipping address phone number 'optional' under Customer information > Shipping address phone number",
    "Click Save",
  ];

  const renderShippingPhoneNumberInstructions = () => (
    <Box mb={3} position="relative">
      <Instructions
        title="Enabling the Shipping Phone Number field in Checkout Settings"
        instructions={enableShippingPhoneNumberInstructions}
        ordered
      />
      <Box position="absolute" top={0} right={0}>
        <Tooltip
          placement="left"
          title={
            <Stack direction="column" gap={1}>
              <Box>
                Shipping Phone number should only be set as required if you{" "}
                <strong>need</strong> a phone number to fulfil the customer's
                order (i.e. ship the product successfully to them).
              </Box>
              <Box>
                If you don’t need a phone number to fulfil the order then this
                should be set to optional.
              </Box>
            </Stack>
          }>
          <HelpOutlineTwoTone />
        </Tooltip>
      </Box>
    </Box>
  );

  const disableTransactionalShopifyMessageInstructions = [
    "Please go back to your Dataships Support page ('Shopify Setup Instructions' step 7)",
    "On your active Theme, click the 3 dot menu",
    "Select 'Edit default theme content'",
    "In the 'Filter items' search box, type 'transactional'",
    "Find the 'Description transactional html' section",
    "Delete the text that is currently in the box and replace with ' ' (just a space)",
    "Click Save",
  ];

  const renderDisableTransactionalShopifyMessage = () => (
    <Box mb={3}>
      <Instructions
        title="Disabling the transactional SMS Shopify message"
        instructions={disableTransactionalShopifyMessageInstructions}
        ordered
      />
    </Box>
  );

  const renderStepButtons = () => (
    <SmsOnboardingProgressButtons
      currentUrl="disable-shopify-sms"
      previousStepUrl="add-extension"
      nextStepUrl="complete"
    />
  );

  return (
    <Paper elevation={1} sx={{ p: 3, minHeight: "100%" }}>
      {renderHeading()}
      {renderDisableShopifySmsInstructions()}
      {renderShippingPhoneNumberInstructions()}
      {renderDisableTransactionalShopifyMessage()}
      {renderStepButtons()}
    </Paper>
  );
};

export default DisableShopifySms;
