import { CancelTwoTone, CheckCircleTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
} from "@mui/material";

import { IIntegrationSettingsFormData } from "@/components/Forms/IntegrationSettingsForm";

interface IProps {
  open: boolean;
  integrationSettingsData: IIntegrationSettingsFormData;
  customer: ICustomer;
  isSaveIntegrationSettingsPending: boolean;
  onClose: () => void;
}

const IntegrationSettingsModal = ({
  integrationSettingsData,
  open,
  customer,
  isSaveIntegrationSettingsPending,
  onClose,
}: IProps) => {

  const renderConfirmationInfoRow = (
    label: string,
    value?: string | null | JSX.Element,
  ) => {
    const renderValue = () => {
      if (typeof value === "string" && value?.includes("https://")) {
        return (
          <a href={value} target="_blank">
            {value}
          </a>
        );
      }
      return (
        <span style={{ color: "black", display: "flex", gap: 5 }}>{value}</span>
      );
    };

    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <span>{label}</span>

        {renderValue()}
      </Box>
    );
  };

  const renderSyncToShopifyValue = (enable: boolean = false) => {
    if (enable) {
      return (
        <>
          Yes <CheckCircleTwoTone color="success" />
        </>
      );
    }

    return (
      <>
        No <CancelTwoTone color="error" />
      </>
    );
  };

  const renderSyncToShopifyRow = () => {
    return (
      <>
        <ListItem>
          {renderConfirmationInfoRow(
            "Enable The Sync to Shopiy",
            renderSyncToShopifyValue(integrationSettingsData?.syncToShopifyEnabled),
          )}
        </ListItem>
      </>
    );
  };

  const renderConfirmationInformation = () => (
    <List>
        {renderSyncToShopifyRow()}
    </List>
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Confirm Integration Settings Updates</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Clicking confirm will update {customer?.companyName}'s <b>LIVE </b>
          integration settings. Please check over the updates to make sure they are
          correct before making these changes.
        </DialogContentText>

        <DialogContentText>
          Clicking cancel will exit without any changes being made.
        </DialogContentText>
      </DialogContent>
      {renderConfirmationInformation()}
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={isSaveIntegrationSettingsPending}
          loadingPosition="start"
          startIcon={<CheckCircleTwoTone />}
          type="submit"
          form="integration-settings-form">
          Confirm Updates
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default IntegrationSettingsModal;
