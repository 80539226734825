import { useCountries } from "@/requests/hooks/app";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  lighten,
  styled,
} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { InferType, object, string } from "yup";
import { LoadingButton } from "@mui/lab";
import { AddCircleTwoTone, SaveTwoTone } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCreateSmsOnboardingCountrySetting,
  useUpdateSmsOnboardingCountrySetting,
} from "@/requests/hooks/customer";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { smsKlaviyoSupportedCountries } from "@/utils/helpers";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.contrastText,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const formSchema = object({
  countryAlpha2: string().required().label("Country"),
  phoneNumber: string().required().label("Phone Number"),
  phoneNumberType: string()
    .required()
    .oneOf(["BRANDED", "NON_BRANDED"])
    .label("Phone Number Type"),
});

type ISmsCountryFormData = InferType<typeof formSchema>;

interface IProps {
  activeNumber: ISmsCountrySetting | undefined;
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}

const SmsPhoneNumberModal = ({
  activeNumber,
  open,
  onClose,
  refetch,
}: IProps) => {
  const isCreating = !activeNumber;

  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;
  const [activeCountryAlpha2, setActiveCountryAlpha2] = useState<string>(
    activeNumber?.countryAlpha2 || "",
  );

  const {
    data: countriesData,
    isLoading: isCountriesDataLoading,
    isError: isCountriesDataError,
  } = useCountries();

  const {
    mutate: createCountrySetting,
    isPending: isCreateCountrySettingPending,
    isSuccess: isCreateCountrySettingSuccess,
    isError: isCreateCountrySettingError,
  } = useCreateSmsOnboardingCountrySetting();

  const {
    mutate: updateCountrySetting,
    isPending: isUpdateCountrySettingPending,
    isSuccess: isUpdateCountrySettingSuccess,
    isError: isUpdateCountrySettingError,
  } = useUpdateSmsOnboardingCountrySetting();

  useEffect(() => {
    if (isCreateCountrySettingSuccess) {
      toast("Created SMS country setting", { type: "success" });
      refetch();
      handleOnClose();
    }

    if (isCreateCountrySettingError) {
      toast("Failed to create SMS country setting", { type: "error" });
    }
  }, [isCreateCountrySettingSuccess, isCreateCountrySettingError]);

  useEffect(() => {
    if (isUpdateCountrySettingSuccess) {
      toast("Updated SMS country setting", { type: "success" });
      refetch();
      handleOnClose();
    }

    if (isUpdateCountrySettingError) {
      toast("Failed to update SMS country setting", { type: "error" });
    }
  }, [isUpdateCountrySettingSuccess, isUpdateCountrySettingError]);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ISmsCountryFormData>({
    defaultValues: {
      countryAlpha2: activeNumber?.countryAlpha2,
      phoneNumber: activeNumber?.phoneNumber,
    },
    resolver: yupResolver(formSchema),
  });

  const submitForm = (data: ISmsCountryFormData) => {
    if (isCreating) {
      createCountrySetting({
        id,
        body: { optInPreference: "DOUBLE_OPT_IN", ...data },
      });
      return;
    }

    updateCountrySetting({
      id,
      settingId: activeNumber.id!,
      body: {
        optInPreference: "DOUBLE_OPT_IN",
        version: activeNumber.version!,
        ...data,
      },
    });
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const getCountriesOptions = (): readonly ICountry[] => {
    const supportedKlaviyoCountries = smsKlaviyoSupportedCountries();

    const masterCountryList = [...supportedKlaviyoCountries, ...countriesData!];

    return masterCountryList as readonly ICountry[];
  };

  const getAutocompleteDefaultValue = () =>
    countriesData?.find(
      country => country?.alpha2 === activeNumber?.countryAlpha2,
    );

  const renderDialogTitle = () => (
    <DialogTitle>{isCreating ? "Create" : "Update"} Phone Number</DialogTitle>
  );

  const renderDialogDescription = () => (
    <DialogContentText mb={2}>
      You can {isCreating ? "create" : "update"} a customers SMS phone number
      below.
    </DialogContentText>
  );

  const renderAutocomplete = () => (
    <Controller
      control={control}
      name="countryAlpha2"
      defaultValue={getAutocompleteDefaultValue()?.alpha2}
      render={({ field: { onChange } }) => (
        <Autocomplete
          options={getCountriesOptions()}
          getOptionLabel={({ name }) => name}
          groupBy={option => option?.supportedInKlaviyo!}
          renderGroup={params => (
            <li key={params.key}>
              <GroupHeader>{params.group || "All Countries"}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          defaultValue={getAutocompleteDefaultValue()}
          renderOption={(props, { alpha2, name }) => (
            <Box
              component="li"
              display="flex"
              alignItems="center"
              gap={2}
              {...props}>
              <ReactCountryFlag countryCode={alpha2} />
              {name}
            </Box>
          )}
          onChange={(e, item) => {
            e.preventDefault();
            onChange(item?.alpha2);
            setActiveCountryAlpha2(item?.alpha2 || "");
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Country"
              InputProps={{
                ...params.InputProps,
                startAdornment: !!activeCountryAlpha2 && (
                  <Box mx={1}>
                    <ReactCountryFlag countryCode={activeCountryAlpha2} />
                  </Box>
                ),
              }}
              error={!!errors?.countryAlpha2}
              helperText={errors?.countryAlpha2?.message}
            />
          )}
        />
      )}
    />
  );

  const renderPhoneNumber = () => (
    <TextField
      id="phoneNumber"
      label="Phone Number"
      type="text"
      variant="outlined"
      fullWidth
      {...register("phoneNumber")}
      error={!!errors.phoneNumber}
      helperText={errors.phoneNumber?.message}
    />
  );

  const renderPhoneNumberTypeRadioGroup = ({
    field,
  }: {
    field: ControllerRenderProps<ISmsCountryFormData, "phoneNumberType">;
  }) => (
    <RadioGroup row {...field}>
      <FormControlLabel value="BRANDED" control={<Radio />} label="Branded" />
      <FormControlLabel
        value="NON_BRANDED"
        control={<Radio />}
        label="Non-branded"
      />
    </RadioGroup>
  );

  const renderBrandedRadio = () => (
    <FormControl>
      <FormLabel>Number Type</FormLabel>
      <Controller
        control={control}
        name="phoneNumberType"
        defaultValue={activeNumber?.phoneNumberType || "BRANDED"}
        render={renderPhoneNumberTypeRadioGroup}
      />
    </FormControl>
  );

  const renderDialogButtons = () => (
    <DialogActions>
      <Button color="error" onClick={handleOnClose}>
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        color={isCreating ? "success" : "secondary"}
        loading={isCreateCountrySettingPending || isUpdateCountrySettingPending}
        loadingPosition="start"
        startIcon={isCreating ? <AddCircleTwoTone /> : <SaveTwoTone />}
        type="submit"
        form="phone-number-form">
        {isCreating ? "Create" : "Update"} Number
      </LoadingButton>
    </DialogActions>
  );

  const renderForm = () =>
    isCountriesDataLoading ? (
      <LinearProgress color="primary" />
    ) : isCountriesDataError ? (
      <Box
        height={100}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Typography variant="h6" color="error">
          Unable to fetch countries.
        </Typography>
      </Box>
    ) : (
      <form id="phone-number-form" onSubmit={handleSubmit(submitForm)}>
        <Stack direction="column" gap={3}>
          {renderAutocomplete()}
          {renderPhoneNumber()}
          {renderBrandedRadio()}
        </Stack>
      </form>
    );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      {renderDialogTitle()}
      <DialogContent>
        {renderDialogDescription()}
        {renderForm()}
      </DialogContent>
      {renderDialogButtons()}
    </Dialog>
  );
};

export default SmsPhoneNumberModal;
