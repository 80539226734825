import { Alert, Box, Stack } from "@mui/material";
import { ReactNode } from "react";

interface ISmsOnboardingChecklistItemConfig {
  title: string;
  instructions: ReactNode[];
}

interface IProps {
  item: ISmsOnboardingChecklistItem;
  companyPrefix?: string | null;
}

export const getSmsOnboardingChecklistConfig = ({
  item,
  companyPrefix,
}: IProps): ISmsOnboardingChecklistItemConfig => {
  switch (item) {
    case "COMPANY_INFORMATION_AND_SMS_MESSAGE_ADD_ONS":
      return {
        title: "Company Information and SMS Message Add-ons",
        instructions: [
          "Staying on the same page in Klaviyo and scrolling slightly upwards, does all of the company information look correct to you? If not, let's update now.",
          "Scroll to the bottom of the page, in 'SMS Message Add-ons', and check that the opt-out language is “Text STOP to opt-out.” If it isn't, please update this.",
        ],
      };

    case "QUIET_HOURS":
      return {
        title: "Quiet Hours",
        instructions: [
          <>
            Scroll down on the Sender preferences tab to{" "}
            <strong>SMS Quiet Hours</strong>
          </>,
          "If you are only sending SMS to the US / Canada then your quiet hours should be set to 8pm - 11am",
          <Stack direction="column" gap={1}>
            If you are not sending SMS to the US / Canada, you can change it to
            something reasonable so that contacts aren't getting SMS messages in
            the middle of the night.
            <ul>
              <li>
                If you are sending to France, please ensure that you do not send
                SMS on Sundays or Public Holidays and make sure to build this
                into your flows.
              </li>
            </ul>
          </Stack>,
          <>
            Under Determine how quiet hours will be applied, select{" "}
            <strong>“Use phone number area codes”</strong>
          </>,
        ],
      };

    case "SMART_SENDING":
      return {
        title: "Smart Sending",
        instructions: [
          'On your Dataships Support page, click on "Access your sender preferences in Klaviyo" to check if Smart Sending is set to 24 hours.',
        ],
      };

    case "UNSUBSCRIBE_KNOWN_LITIGATORS":
      return {
        title: "Unsubscribe Known Litigators",
        instructions: [
          "Scrolling further down on the same page, check that the 'Unsubscribe known litigators' checkbox is checked.",
        ],
      };

    case "CONTENT_OF_SECOND_OPT_IN_CONFIRMATION_MESSAGE":
      return {
        title: "Content of Second Opt-in Confirmation SMS Message",
        instructions: [
          "Please go back to your Dataships Support page ('Klaviyo Setup Instructions' step 3 - Keyword Responses)",
          <Stack direction="column" gap={1}>
            <Box>
              Check that the messaging wording of “After signing up to receive
              SMS” matches the following:
            </Box>
            <Alert color="info" icon={false}>
              {companyPrefix || "{Company Prefix}"}: Please reply YES to receive
              updates. Msg & data rates may apply. Reply HELP for help, STOP to
              opt out
            </Alert>
          </Stack>,
          "If it doesn’t then update it now to match.",
        ],
      };

    case "MOBILE_TERMS_OF_SERVICE":
      return {
        title: "Mobile Terms of Service",
        instructions: [
          <Stack direction="column" gap={1}>
            <Box>
              Let’s check if you have an existing Mobile Terms of Service. On
              your Dataships Support page please click on ('Klaviyo Setup
              Instructions' step 4 - Mobile Terms of Service editor link) to
              find the Mobile Terms of Service settings.
            </Box>
            <Alert color="warning" icon={false}>
              <strong>Note to Customer Success:</strong> If the “Create mobile
              terms of service” button is available - Skip this step (Klaviyo
              will use the link to the Terms of Service provided in the SMS
              Opt-in Disclaimer below). Otherwise follow the steps below.
            </Alert>
          </Stack>,
          "Could you copy the Mobile Terms of Service from the Support page?",
          "Please paste it into the Mobile Terms of Service editor.",
        ],
      };

    case "SMS_DISCLAIMER_WORDING":
      return {
        title: "SMS Disclaimer Wording",
        instructions: [
          <Stack direction="column" gap={1}>
            <Box>
              Let's check if you have an existing SMS opt-in Disclaimer. Please
              scroll to the bottom of the page.
            </Box>
            <Alert color="warning" icon={false}>
              <strong>Note to Customer Success:</strong> Depending on how their
              SMS Disclaimer is configured in Klaviyo there are two alternative
              paths below
            </Alert>
          </Stack>,
          <Stack direction="column" gap={1}>
            <Box>
              <strong>
                If there is an option to “Configure opt-in disclosure”:
              </strong>
            </Box>
            <Stack direction="column" component="ul" gap={1}>
              <li>Select “Configure opt-in disclaimer”</li>
              <li>
                Enter the Privacy Policy and Terms of Service links from your
                Dataships Support page ('Klaviyo Setup Instructions' step 5a and
                5b)
              </li>
              <li>
                Tick that I have updated my Privacy Policy to include SMS
                sending information checkbox and submit. This will generate the
                SMS opt-in disclaimer.
              </li>
              <li>
                We want to update this generated text to make it more compliant.
                We will remove some of this, but we want to keep the two links
                at the end. Please delete the text generated{" "}
                <strong>as far as</strong> the 'full stop' before the
                hyperlinked Privacy Policy & Terms of Service.
              </li>
              <li>
                Please go back to your Dataships Support page ('Klaviyo Setup
                Instructions' step 5c)
              </li>
              <li>
                Could you copy the SMS Disclaimer Wording from the Support page?
              </li>
              <li>Please paste it in before the 'full stop'.</li>
            </Stack>
          </Stack>,
          <Stack direction="column" gap={1}>
            <Box>
              <strong>
                If the SMS Opt-in Disclaimer is already generated:
              </strong>
            </Box>
            <Stack direction="column" component="ul" gap={1}>
              <li>
                We want to update this generated text to make it more compliant.
                We will remove some of this, but we want to keep the two links
                at the end. Please delete the text generated{" "}
                <strong>as far as</strong> the 'full stop' before the
                hyperlinked Privacy Policy & Terms of Service.
              </li>
              <li>
                Please go back to your Dataships Support page ('Klaviyo Setup
                Instructions' step 5c)
              </li>
              <li>
                Could you copy the SMS Disclaimer Wording from the Support page?
              </li>
              <li>Please paste it in before the 'full stop'</li>
              <li>
                Could you also copy your Privacy Policy URL from the Support
                page?
              </li>
              <li>Please paste it into the Privacy Policy URL field</li>
              <li>
                Lastly, could you copy your Terms of Service URL from the
                Support page?
              </li>
              <li>Please paste it into the Terms of Service URL field</li>
            </Stack>
          </Stack>,
        ],
      };

    default:
      return {
        title: "Company Information and SMS Message Add-ons",
        instructions: [
          "Staying on the same page in Klaviyo and scrolling slightly upwards, does all of the company information look correct to you? If not, let's update now.",
          "Scroll to the bottom of the page, in 'SMS Message Add-ons', and check that the opt-out language is “Text STOP to opt-out.” If it isn't, please update this.",
        ],
      };
  }
};
