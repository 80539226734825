import { PageLoader } from "@/components/Loaders";
import { Error } from "@/components/Error";
import { useCustomer, useWidgetSettings } from "@/requests/hooks/customer";
import { useParams } from "react-router-dom";
import { WidgetSettingsForm } from "@/components/Forms";

const WidgetSettings = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const {
    data: customerData,
    isLoading: isCustomerLoading,
    error: customerError,
  } = useCustomer(id);

  const {
    data: widgetSettingsData,
    isLoading: isWidgetSettingsLoading,
    error: widgetSettingsError,
    refetch: refetchWidgetSettings,
  } = useWidgetSettings(id);

  if (isCustomerLoading || isWidgetSettingsLoading)
    return <PageLoader title="widget settings" />;
  if (customerError || widgetSettingsError || !customerData)
    return (
      <Error
        error={
          customerError || widgetSettingsError || "Customer data unavailable."
        }
      />
    );

  return (
    <WidgetSettingsForm
      customer={customerData}
      widgetSettingsData={widgetSettingsData}
      refetchWidgetSettings={refetchWidgetSettings}
    />
  );
};

export default WidgetSettings;
