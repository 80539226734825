import { CheckCircleTwoTone, ControlPointTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Alert,
  Box,
  Divider,
  List,
  ListItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import DiscountForm, {
  IDiscountFormData,
  discountFormSchema,
} from "@/components/Forms/DiscountForm";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDiscount } from "@/requests/hooks/customer";
import { toast } from "react-toastify";

interface IProps {
  open: boolean;
  onClose: () => void;
  customerId: string;
  reloadDiscounts: () => void;
}

const DiscountModal = ({
  open,
  onClose,
  customerId,
  reloadDiscounts,
}: IProps) => {
  const {
    mutate: discount,
    isPending: isDiscountPending,
    isSuccess: isDiscountSuccess,
    isError: isDiscountError,
  } = useDiscount();

  useEffect(() => {
    if (isDiscountSuccess) {
      toast("Created discount", {
        type: "success",
      });

      reloadDiscounts();
      handleOnClose();
      toggleConfirmationOpen();
    }

    if (isDiscountError) {
      toast("Unable to create discount", { type: "error" });
    }
  }, [isDiscountSuccess, isDiscountError]);

  const methods = useForm<IDiscountFormData>({
    defaultValues: {
      endOnSubscriptionChange: "false",
    },
    resolver: yupResolver(discountFormSchema),
  });

  const { handleSubmit, reset, trigger: formTrigger, getValues } = methods;

  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const validateAndOpenConfirmation = async () => {
    const validated = await formTrigger();
    if (validated) toggleConfirmationOpen();
  };

  const toggleConfirmationOpen = () => {
    setConfirmationOpen(confirmationOpen => !confirmationOpen);
  };

  const submitForm = (data: IDiscountFormData) => {
    const {
      discountNote,
      discountPercentage,
      billingCycles,
      endOnSubscriptionChange,
    } = data;

    const discountData: IDiscountRQ = {
      note: discountNote,
      percentage: discountPercentage,
      maxBillingCycles: billingCycles,
      endOnSubscriptionChange: endOnSubscriptionChange === "true",
      products: ["COLLECT"],
    };

    discount({
      id: customerId,
      body: discountData,
    });
  };

  const renderDialogTitle = () => (
    <DialogTitle>Create Subscription Discount</DialogTitle>
  );

  const renderDialogDescription = () => (
    <DialogContentText mb={2}>
      Only one subscription discount can be active at any time. If there is
      already an active discount, any new discount will be applied when the
      active discount period ends.
    </DialogContentText>
  );

  const renderDialogForm = () => (
    <FormProvider {...methods}>
      <form id="create-discount-form" onSubmit={handleSubmit(submitForm)}>
        <DiscountForm />
      </form>
    </FormProvider>
  );

  const renderDialogButtons = () => (
    <DialogActions>
      <Button color="error" onClick={handleOnClose}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<ControlPointTwoTone />}
        type="button"
        onClick={validateAndOpenConfirmation}>
        Create
      </Button>
    </DialogActions>
  );

  const renderConfirmationInfoRow = (
    label: string,
    value?: string | number,
  ) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <span>{label}</span>
        <span>{value}</span>
      </Box>
    );
  };

  const renderConfirmationInformation = () => (
    <List>
      <ListItem>
        {renderConfirmationInfoRow("Note", getValues("discountNote"))}
      </ListItem>
      <Divider />
      <ListItem>
        {renderConfirmationInfoRow(
          "Percentage",
          `${getValues("discountPercentage")}%`,
        )}
      </ListItem>
      <Divider />
      <ListItem>
        {renderConfirmationInfoRow(
          "Num. Billing Cycles",
          getValues("billingCycles"),
        )}
      </ListItem>
      <Divider />
      <ListItem>
        {renderConfirmationInfoRow(
          "End on Subscription Change",
          getValues("endOnSubscriptionChange") === "true" ? "Yes" : "No",
        )}
      </ListItem>
    </List>
  );

  const renderConfirmationDialog = () => (
    <Dialog
      open={confirmationOpen}
      onClose={toggleConfirmationOpen}
      fullWidth
      maxWidth="xs">
      <DialogTitle>Confirm Discount Creation</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Clicking confirm will create a new subscription discount. Only one
          subscription discount can be active at any time. New discounts will be
          applied when the current actice discount period ends.
        </DialogContentText>

        <DialogContentText>
          Clicking cancel will exit without any changes being made.
        </DialogContentText>
      </DialogContent>
      <Alert severity="warning">This action cannot be undone.</Alert>
      {renderConfirmationInformation()}
      <DialogActions>
        <Button color="error" onClick={toggleConfirmationOpen}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={isDiscountPending}
          loadingPosition="start"
          startIcon={<CheckCircleTwoTone />}
          type="submit"
          form="create-discount-form">
          Confirm Discount
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Dialog open={open} onClose={handleOnClose}>
        {renderDialogTitle()}
        <DialogContent>
          {renderDialogDescription()}
          {renderDialogForm()}
        </DialogContent>
        {renderDialogButtons()}
      </Dialog>
      {renderConfirmationDialog()}
    </>
  );
};

export default DiscountModal;
