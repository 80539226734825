import request from "@/requests/request";

export const getCustomers = ({
  params,
  signal,
}: {
  params: ICustomersRQ;
  signal: IGenericHookOptions["signal"];
}) =>
  request({
    url: "/v1/admin/customer",
    params,
    signal,
  }).then(res => res.data);

export const getCustomerPersonas = ({ signal }: IGenericHookOptions) =>
  request({
    url: "/v1/admin/user-customer/personas",
    signal,
  }).then(res => res.data);

export const postCustomer = (body: ICreateCustomerRQ) =>
  request({
    url: "/v1/admin/customer",
    method: "POST",
    body,
  }).then(res => res.data);

export const getCustomer = ({
  id,
  signal,
}: {
  id: string;
  signal: IGenericHookOptions["signal"];
}): Promise<ICustomer> =>
  request({
    url: `/v1/admin/customer/${id}`,
    signal,
  }).then(res => res.data);

export const getCustomerMetrics = ({
  id,
  signal,
}: {
  id: string;
  signal: IGenericHookOptions["signal"];
}): Promise<IMetricsRS> =>
  request({
    url: `/v1/admin/customer/${id}/metrics?page=1&pageSize=50`,
    method: "GET",
    signal,
    timeout: 10000,
  }).then(res => res.data);

export const postUpdateMarketableRate = ({
  id,
  body,
}: {
  id: string;
  body: IUpdateMarketableRateRQ;
}) =>
  request({
    url: `/v1/admin/customer/${id}/pre-dataships-marketable-rate`,
    method: "POST",
    body,
  }).then(res => res.data);

export const postExtendTrial = ({
  id,
  body,
}: {
  id: number;
  body: IExtendTrialRQ;
}) =>
  request({
    url: `/v1/trial/${id}/extend`,
    method: "POST",
    body,
  }).then(res => res.data);

export const getCustomerTimeline = ({
  customerId,
  params,
  signal,
}: {
  customerId: string;
  params: ITimelineRQ;
  signal: IGenericHookOptions["signal"];
}) =>
  request({
    url: `/v1/admin/customer/${customerId}/timeline`,
    params,
    signal,
  }).then(res => res.data);

export const postCustomPricing = ({
  id,
  body,
}: {
  id: string;
  body: ICustomPricingRQ;
}) =>
  request({
    url: `/v1/admin/customer/${id}/enterprise-tier`,
    method: "POST",
    body,
  }).then(res => res.data);

export const postDiscount = ({ id, body }: { id: string; body: IDiscountRQ }) =>
  request({
    url: `/v1/admin/customer/${id}/discount`,
    method: "POST",
    body,
  }).then(res => res.data);

export const postCookiebotDetails = (body: ICookiebotRQ) =>
  request({
    url: `/v1/customer/cookiebot`,
    method: "POST",
    body,
  }).then(res => res.data);

export const getWidgetSettings = ({
  id,
  signal,
}: {
  id: string;
  signal: IGenericHookOptions["signal"];
}) =>
  request({
    url: `/v1/admin/customer/${id}/widget-settings`,
    signal,
  }).then(res => res.data);

export const getIntegrationSettings = ({
  id,
  signal,
}: {
  id: string;
  signal: IGenericHookOptions["signal"];
}) =>
  request({
    url: `/v1/admin/customer/${id}/integration-settings`,
    signal,
  }).then(res => res.data);

export const postWidgetSettings = ({
  id,
  body,
}: {
  id: string;
  body: IWidgetSettingsRQ;
}) =>
  request({
    url: `/v1/admin/customer/${id}/widget-settings`,
    method: "POST",
    body,
  }).then(res => res.data);

export const postIntegrationSettings = ({
  id,
  body,
}: {
  id: string;
  body: IIntegrationSettingsRQ;
}) =>
  request({
    url: `/v1/admin/customer/${id}/integration-settings`,
    method: "POST",
    body,
  }).then(res => res.data);

export const postCreateUser = ({
  id,
  body,
}: {
  id: string;
  body: ICreateCustomerUserRQ;
}) =>
  request({
    url: `/v1/admin/customer/${id}/user`,
    method: "POST",
    body,
  }).then(res => res.data);

export const postUserPersonas = ({
  id,
  body,
}: {
  id: string;
  body: IUserPersonaRQ;
}) =>
  request({
    url: `/v1/admin/user-customer/${id}`,
    method: "POST",
    body,
  }).then(res => res.data);

export const getSmsOnboardingProgress = ({
  id,
  signal,
}: {
  id: string;
  signal: IGenericHookOptions["signal"];
}): Promise<IGetSmsOnboardingProgressRS> =>
  request({
    url: `/v1/admin/sms-onboarding/${id}`,
    method: "GET",
    signal,
  }).then(res => res.data);

export const postSmsOnboardingSetupEmail = ({ id }: { id: string }) =>
  request({
    url: `/v1/admin/sms-onboarding/${id}/send-setup-email`,
    method: "POST",
  }).then(res => res.data);

export const postSmsOnboardingQuestionnaire = ({
  id,
  body,
}: {
  id: string;
  body: ISmsOnboardingQuestionnaireRQ;
}) =>
  request({
    url: `/v1/admin/sms-onboarding/${id}/answers`,
    method: "POST",
    body,
  }).then(res => res.data);

export const postCreateSmsCountrySetting = ({
  id,
  body,
}: {
  id: string;
  body: ISmsCreateCountrySettingRQ;
}) =>
  request({
    url: `/v1/admin/sms-onboarding/${id}/country-settings`,
    method: "POST",
    body,
  }).then(res => res.data);

export const postUpdateSmsCountrySetting = ({
  id,
  settingId,
  body,
}: {
  id: string;
  settingId: number;
  body: ISmsUpdateCountrySettingRQ;
}) =>
  request({
    url: `/v1/admin/sms-onboarding/${id}/country-settings/${settingId}`,
    method: "POST",
    body,
  }).then(res => res.data);

export const deleteSmsCountrySetting = ({
  id,
  settingId,
  body,
}: {
  id: string;
  settingId: number;
  body: ISmsDeleteCountrySettingRQ;
}) =>
  request({
    url: `/v1/admin/sms-onboarding/${id}/delete-country-settings/${settingId}`,
    method: "POST",
    body,
  }).then(res => res.data);

export const postSmsCompanyPrefix = ({
  id,
  body,
}: {
  id: string;
  body: ISmsPostCompanyPrefixRQ;
}) =>
  request({
    url: `/v1/admin/sms-onboarding/${id}/company-prefix`,
    method: "POST",
    body,
  }).then(res => res.data);

export const postSmsOnboardingCompletionEmail = ({ id }: { id: string }) =>
  request({
    url: `/v1/admin/sms-onboarding/${id}/complete-onboarding`,
    method: "POST",
  }).then(res => res.data);

export const postSmsOnboardingFirstOptInCheck = ({ id }: { id: string }) =>
  request({
    url: `/v1/admin/sms-onboarding/${id}/first-opt-in-check`,
    method: "POST",
  }).then(res => res.data);

export const getSmsOnboardingFirstOptInCheck = ({
  id,
  signal,
}: {
  id: string;
  signal: IGenericHookOptions["signal"];
}) =>
  request({
    url: `/v1/admin/sms-onboarding/${id}/first-opt-in-check`,
    method: "GET",
    signal,
  }).then(res => res.data);

export const postSmsOnboardingSecondOptInCheck = ({ id }: { id: string }) =>
  request({
    url: `/v1/admin/sms-onboarding/${id}/second-opt-in-check`,
    method: "POST",
  }).then(res => res.data);

export const getSmsOnboardingSecondOptInCheck = ({
  id,
  signal,
}: {
  id: string;
  signal: IGenericHookOptions["signal"];
}) =>
  request({
    url: `/v1/admin/sms-onboarding/${id}/second-opt-in-check`,
    method: "GET",
    signal,
  }).then(res => res.data);

export const postDelinquentStatus = ({
  customerId,
  body,
}: {
  customerId: string;
  body: IPostCustomerDelinquentRQ;
}) =>
  request({
    url: `/v1/admin/customer/${customerId}/delinquent`,
    method: "POST",
    body,
  }).then(res => res.data);

export const postSmsOnboardingComplianceCheck = ({
  id,
  body,
}: {
  id: string;
  body: ISmsPostComplianceCheckRQ;
}) =>
  request({
    url: `/v1/admin/sms-onboarding/${id}/compliance-checks`,
    method: "POST",
    body,
  }).then(res => res.data);

export const postVerifyKlaviyoPrivateKey = async ({
  id,
  privateKey,
  signal,
}: {
  id: string;
  privateKey: string;
  signal: IGenericHookOptions["signal"];
}): Promise<IVerifyKlaviyoPrivateKeyRS> =>
  await request({
    url: `/v1/admin/sms-onboarding/${id}/klaviyo-organization-name`,
    method: "POST",
    body: {
      privateApiKey: privateKey,
    },
    signal,
  }).then(res => res.data);

export const postCreateKlaviyoLists = async ({
  id,
}: {
  id: string;
}): Promise<ICreateKlaviyoListsRS> =>
  await request({
    url: `/v1/admin/sms-onboarding/${id}/lists`,
    method: "POST",
  }).then(res => res.data);

export const postCheckKlaviyoLists = ({
  id,
  signal,
}: {
  id: string;
  signal: IGenericHookOptions["signal"];
}) =>
  request({
    url: `/v1/admin/sms-onboarding/${id}/lists/status`,
    method: "POST",
    signal,
  }).then(res => res.data);
