import { Alert, Box, Typography } from "@mui/material";
import { ReactNode } from "react";

interface IProps {
  title?: string;
  instructions: ReactNode[];
  ordered?: boolean;
  customerSuccessOnly?: boolean;
}

const Instructions = ({
  title = "Instructions",
  instructions,
  ordered = false,
  customerSuccessOnly = false,
}: IProps) => {
  const ListComponent = ordered ? "ol" : "ul";

  const renderInstructions = () => (
    <>
      <Typography variant="body1" fontWeight={600}>
        {title}
      </Typography>
      <ListComponent style={{ fontSize: "0.875rem" }}>
        {instructions.map((instruction, index) => (
          <Box key={index} component="li" mb={1} pl={1}>
            <Typography variant="body2" component="div">
              {instruction}
            </Typography>
          </Box>
        ))}
      </ListComponent>
    </>
  );

  const renderCustomerSuccessWrapperWithInstructions = () => {
    if (!customerSuccessOnly) return renderInstructions();

    return (
      <Alert color="warning" icon={false}>
        {renderInstructions()}
      </Alert>
    );
  };

  return renderCustomerSuccessWrapperWithInstructions();
};

export default Instructions;
