import { boolean, InferType, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { URL_REGEX, PERMALINK_URL_REGEX } from "@/utils/const";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Paper,
  Box,
  Divider,
  Link,
  Stack,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import { useSaveWidgetSettings } from "@/requests/hooks/customer";
import { toast } from "react-toastify";
import { WidgetSettingsModal } from "@/components/Modals";
import { useFlags } from "flagsmith/react";

const formSchema = object({
  privacyEmail: string().email().nullable().label("Privacy Email"),
  privacyPolicyUrl: string()
    .matches(URL_REGEX, {
      message: "Not a valid URL",
      excludeEmptyString: true,
    })
    .nullable()
    .label("Privacy Policy"),
  smsPrivacyPolicyUrl: string()
    .matches(URL_REGEX, {
      message: "Not a valid URL",
      excludeEmptyString: true,
    })
    .nullable()
    .label("SMS Privacy Policy"),
  mobileTermsOfServiceUrl: string()
    .matches(URL_REGEX, {
      message: "Not a valid URL",
      excludeEmptyString: true,
    })
    .nullable()
    .label("Terms of Service"),
  permalinkUrl: string()
    .matches(PERMALINK_URL_REGEX, {
      message: "Not a valid permalink URL",
      excludeEmptyString: true,
    })
    .nullable()
    .label("Permalink"),
  cinchEnabled: boolean(),
  shouldHideFooter: boolean(),
});

export type IWidgetSettingsFormData = InferType<typeof formSchema>;

interface IProps {
  customer: ICustomer;
  widgetSettingsData: IWidgetSettingsFormData & { version: number };
  refetchWidgetSettings: () => void;
}

const WidgetSettingsForm = ({
  customer,
  widgetSettingsData,
  refetchWidgetSettings,
}: IProps) => {
  const flags = useFlags(["admin-panel-toggle-cinch-enabled"]);
  const isToggleCinchEnabled =
    flags["admin-panel-toggle-cinch-enabled"]?.enabled;

  const {
    mutate: saveWidgetSettings,
    isPending: isSaveWidgetSettingsPending,
    isSuccess: isSaveWidgetSettingsSuccess,
    isError: isSaveWidgetSettingsError,
  } = useSaveWidgetSettings();

  useEffect(() => {
    if (isSaveWidgetSettingsSuccess) {
      refetchWidgetSettings();
      toggleConfirmationOpen();
      toast(`Updated widget settings for ${customer?.companyName}`, {
        type: "success",
      });
    }

    if (isSaveWidgetSettingsError) {
      toast("Unable to save widget settings", { type: "error" });
    }
  }, [isSaveWidgetSettingsSuccess, isSaveWidgetSettingsError]);

  const methods = useForm<IWidgetSettingsFormData>({
    defaultValues: widgetSettingsData,
    resolver: yupResolver(formSchema),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = methods;
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  const formatFormData = (data: IWidgetSettingsFormData) =>
    JSON.parse(JSON.stringify(data), (key, value) => {
      if (key === "version") return widgetSettingsData?.version;
      return value === "" ? null : value;
    });

  const submitForm = (data: IWidgetSettingsFormData) =>
    saveWidgetSettings({
      id: customer?.id.toString(),
      body: formatFormData(data),
    });

  const toggleConfirmationOpen = () => {
    setConfirmationOpen(confirmationOpen => !confirmationOpen);
  };

  const renderTextInput = ({
    name,
    label,
    disabled = false,
  }: {
    name:
      | "privacyEmail"
      | "privacyPolicyUrl"
      | "smsPrivacyPolicyUrl"
      | "mobileTermsOfServiceUrl"
      | "permalinkUrl";
    label: string;
    disabled?: boolean;
  }) => (
    <TextField
      margin="dense"
      id={name}
      label={label}
      type="text"
      fullWidth
      variant="outlined"
      multiline={false}
      {...register(name)}
      disabled={disabled}
      error={!!errors?.[name]}
      helperText={errors?.[name]?.message}
    />
  );

  const renderPolicyDetails = () => (
    <>
      <Box mb={3}>
        <Typography variant="h6" color="secondary">
          Policy Details
        </Typography>
        <Typography variant="body2" maxWidth={1024}>
          These policy URLs and contact email address will appear in the
          customer&apos;s widget and need to be accurate to ensure consent is
          collected compliantly.
        </Typography>
      </Box>
      <Stack direction="column" spacing={3}>
        {renderTextInput({ name: "privacyEmail", label: "Privacy Email" })}
        {renderTextInput({
          name: "privacyPolicyUrl",
          label: "Email Privacy Policy URL",
        })}
        {renderTextInput({
          name: "smsPrivacyPolicyUrl",
          label: "SMS Privacy Policy URL",
          disabled: true,
        })}
        {renderTextInput({
          name: "mobileTermsOfServiceUrl",
          label: "Mobile Terms of Service URL",
          disabled: true,
        })}
      </Stack>
    </>
  );

  const renderCartPermalink = () => (
    <>
      <Box mb={3}>
        <Typography variant="h6" color="secondary">
          Checkout Permalink
        </Typography>
        <Typography variant="body2" maxWidth={1024}>
          This creates a checkout session on a customer&apos;s online store.
          This allows us to run daily compliance checks on their policies and
          wording and to confirm that the extension is loading (if they are
          using it on their Checkout Information page).{" "}
          <Link
            target="_blank"
            href="https://team-1634040913267.atlassian.net/wiki/spaces/DEV/pages/90701832/Customer+Service+Playbook#Create-a-Checkout-Permalink">
            How to create a permalink
          </Link>
        </Typography>
      </Box>
      {renderTextInput({
        name: "permalinkUrl",
        label: "Checkout Permalink URL",
      })}
    </>
  );

  const renderCinchSwitch = () => (
    <Box mb={3}>
      <Typography variant="h6" color="secondary">
        Enable the Cinch
      </Typography>
      <Typography variant="body2" maxWidth={1024} mb={3}>
        Use the toggle below to turn the Cinch on or off for this customer. (The
        Cinch is the SMS double opt-in method on the checkout where we send a
        verification code to the contact's phone number and they enter that
        verification code into our extension to complete the double opt-in)
      </Typography>
      <Controller
        control={control}
        name="cinchEnabled"
        render={({ field }) => (
          <Typography variant="body1">
            Off{" "}
            <Switch
              {...register("cinchEnabled")}
              {...field}
              checked={field?.value}
              inputProps={{ "aria-label": "controlled" }}
              color="primary"
            />{" "}
            On
          </Typography>
        )}
      />
    </Box>
  );

  const renderShouldHideFooter = () => (
    <Box mb={3}>
      <Typography variant="h6" color="secondary">
        Hide ‘Powered by Dataships’ logo
      </Typography>
      <Typography variant="body2" maxWidth={1024} mb={3}>
        Use the toggle below to hide or show the “Powered by Dataships” logo which appears on our widget for this
        customer.
        By default, the logo is displayed; switching the toggle to 'Off' will hide it
      </Typography>
      <Controller
        control={control}
        name="shouldHideFooter"
        render={({field}) => (
          <Typography variant="body1">
            Off{" "}
            <Switch
              {...register("shouldHideFooter")}
              {...field}
              checked={(field?.value)}
              inputProps={{"aria-label": "controlled"}}
              color="primary"
            />{" "}
            On
          </Typography>
        )}
      />
    </Box>
  );

  const renderConfirmationDialog = () => (
    <WidgetSettingsModal
      widgetSettingsData={getValues()}
      open={confirmationOpen}
      onClose={toggleConfirmationOpen}
      customer={customer}
      isSaveWidgetSettingsPending={isSaveWidgetSettingsPending}
    />
  );

  return (
    <>
      <Paper elevation={1}>
        <Box p={3}>
          <FormProvider {...methods}>
            <form id="widget-settings-form" onSubmit={handleSubmit(submitForm)}>
              {renderPolicyDetails()}
              <Divider sx={{ my: 3 }} />
              {renderCartPermalink()}
              {isToggleCinchEnabled && (
                <>
                  <Divider sx={{ my: 3 }} />
                  {renderCinchSwitch()}
                </>
              )}
                <Divider sx={{ my: 3 }} />
                {renderShouldHideFooter()}
            </form>
          </FormProvider>
        </Box>
      </Paper>

      <Box display="flex" justifyContent="end" mt={3}>
        <LoadingButton
          variant="contained"
          color="secondary"
          loadingPosition="start"
          startIcon={<Save />}
          onClick={toggleConfirmationOpen}>
          Save Changes
        </LoadingButton>
      </Box>
      {renderConfirmationDialog()}
    </>
  );
};

export default WidgetSettingsForm;
