import { getAppConfig } from "@/utils/appManager";
import { DownloadTwoTone } from "@mui/icons-material";
import { Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useFlags } from "flagsmith/react";

const RenderAbTestDownload = (params: GridRenderCellParams) => {
  const flags = useFlags(["admin-panel-ab-test-download-enabled"]);
  const isAbTestDownloadEnabled =
    flags["admin-panel-ab-test-download-enabled"]?.enabled;

  const { coreApiHost } = getAppConfig();

  const {
    row: { id, startDate },
  } = params;

  return (
    <Button
      disabled={!startDate || !isAbTestDownloadEnabled}
      component="a"
      href={`${coreApiHost}/v1/admin/ab-test/${id}/download`}
      size="small"
      color="secondary"
      variant="contained"
      startIcon={<DownloadTwoTone />}>
      Download
    </Button>
  );
};

export default RenderAbTestDownload;
