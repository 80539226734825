import { LoginAsCustomerButton } from "@/components/Buttons";
import { BadgeTwoTone } from "@mui/icons-material";
import {
  Box,
  Typography,
  Stack,
  Tooltip,
  SvgIcon,
  Divider,
  Skeleton,
} from "@mui/material";

interface IProps {
  isLoading: boolean;
  customer?: ICustomer;
}

const Header = ({ isLoading, customer }: IProps) => {
  const renderCompanyName = () => (
    <Typography variant="h4" mr={3}>
      {isLoading ? (
        <Skeleton variant="text" width={200} />
      ) : (
        customer?.companyName
      )}
    </Typography>
  );

  const renderLoginButton = () =>
    isLoading ? (
      <Skeleton height={32} width={64} variant="rounded" />
    ) : (
      <LoginAsCustomerButton customerId={customer?.id!} />
    );

  const renderCustomerId = () => (
    <Tooltip title="Customer ID">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          minWidth: 63,
        }}>
        {isLoading ? (
          <Skeleton height={32} width={35} variant="rounded" />
        ) : (
          customer?.id
        )}
        <SvgIcon component={BadgeTwoTone} fontSize="small" sx={{ ml: 1 }} />
      </Box>
    </Tooltip>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {renderCompanyName()}
        </Box>
        <Typography component="span" variant="body2">
          <Stack direction="row" spacing={3}>
            {renderLoginButton()}
            {renderCustomerId()}
          </Stack>
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
    </>
  );
};

export default Header;
