import { Instructions } from "@/components/Typography";
import {
  usePostComplianceChecks,
  useSmsOnboardingProgress,
} from "@/requests/hooks/customer";
import { stringToColor } from "@/utils/helpers";
import { getSmsOnboardingChecklistConfig } from "@/utils/smsOnboarding/checklist";
import { Avatar, Box, Checkbox, Paper, Stack, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface IProps {
  checklistItem?: ISmsChecklistItem;
}

const ChecklistItem = ({ checklistItem }: IProps) => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;
  const [isChecked, setIsChecked] = useState<boolean>(
    checklistItem?.checked || false,
  );

  const {
    data: smsOnboardingData,
    refetch: refetchSmsOnboardingData,
    isLoading: isSmsOnboardingDataLoading,
    isRefetching: isSmsOnboardingDataRefetching,
  } = useSmsOnboardingProgress(id);

  const {
    mutate: sendComplianceCheck,
    isPending: isSendComplianceCheckPending,
    isSuccess: isSendComplianceCheckSuccess,
    isError: isSendComplianceCheckError,
  } = usePostComplianceChecks();

  useEffect(() => {
    if (isSendComplianceCheckSuccess) {
      toast("Saved compliance check", { type: "success" });
      refetchSmsOnboardingData();
      setIsChecked(isChecked => !isChecked);
    }

    if (isSendComplianceCheckError) {
      toast("Failed to save compliance check", { type: "error" });
    }
  }, [isSendComplianceCheckSuccess, isSendComplianceCheckError]);

  if (!checklistItem) return;

  const { item, updatedAt, updatedBy } = checklistItem;

  const { title, instructions } = getSmsOnboardingChecklistConfig({
    item,
    companyPrefix: smsOnboardingData?.companyDetails?.companyPrefix,
  });

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) =>
    sendComplianceCheck({
      id,
      body: {
        items: [{ item, checked: e.target.checked }],
      },
    });

  const renderInstructions = () => (
    <Box>
      <Instructions title={title} instructions={instructions} />
    </Box>
  );

  const renderCheckbox = () => {
    const avatarInitial = updatedBy?.slice(0, 1).toUpperCase() || null;
    const backgroundColor = !!updatedBy ? stringToColor(updatedBy) : "";
    const formattedUpdatedAt = !!updatedAt
      ? dayjs(updatedAt).format("YYYY-MM-DD HH:mm")
      : "Never";

    const tooltipValue = !!updatedBy
      ? `Updated by ${updatedBy} (${formattedUpdatedAt})`
      : "Never updated";

    return (
      <Box flexShrink={0} minWidth={100}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={1}>
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            disabled={
              isSendComplianceCheckPending ||
              isSmsOnboardingDataLoading ||
              isSmsOnboardingDataRefetching
            }
          />
          <Tooltip
            title={tooltipValue}
            componentsProps={{
              tooltip: {
                sx: {
                  textAlign: "center",
                },
              },
            }}>
            <Avatar sx={{ height: 30, width: 30, backgroundColor }}>
              {avatarInitial}
            </Avatar>
          </Tooltip>
        </Stack>
      </Box>
    );
  };

  return (
    <Paper variant="outlined" sx={{ p: 3, mb: 3 }}>
      <Stack
        direction="row"
        gap={3}
        alignItems="center"
        justifyContent="space-between">
        {renderInstructions()}
        {renderCheckbox()}
      </Stack>
    </Paper>
  );
};

export default ChecklistItem;
