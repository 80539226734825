import { getAppConfig } from "@/utils/appManager";
import useExternalScript from "@/utils/useExternalScript";
import { Box } from "@mui/material";

const Login = () => {
  const { coreApiHost, googleClientId } = getAppConfig();

  useExternalScript("https://accounts.google.com/gsi/client");

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background:
          "linear-gradient(to bottom right, #10A4FF 0%, #4ACE96 100%)",
      }}>
      <div
        id="g_id_onload"
        data-ux_mode="redirect"
        data-client_id={googleClientId}
        data-login_uri={`${coreApiHost}/v1/auth/admin-login`}
        data-auto_prompt="false"></div>
      <div
        className="g_id_signin"
        data-type="standard"
        data-size="large"
        data-theme="outline"
        data-text="sign_in_with"
        data-shape="rectangular"
        data-logo_alignment="left"
        data-testid="login-button"></div>
    </Box>
  );
};

export default Login;
