import request from "@/requests/request";

export const getUser = ({ signal }: IGenericHookOptions): Promise<IGetUserRS> =>
  request({
    url: "/v1/admin/auth-status",
    signal,
  }).then(res => res.data);

export const postLogout = () =>
  request({
    url: "/v1/auth/admin-logout",
    method: "POST",
  });

export const postLoginAsCustomer = (body: IPostLoginAsCustomerRQ) =>
  request({
    url: "/v1/auth/jita-login",
    method: "POST",
    body,
  }).then(res => res.data);
