import { Error } from "@/components/Error";
import { PageLoader } from "@/components/Loaders";
import { SmsOnboardingQuestionnaireModal } from "@/components/Modals";
import steps from "@/navigation/smsOnboardingSteps";
import {
  useCustomer,
  useSaveSmsOnboardingQuestionnaire,
  useSmsOnboardingProgress,
} from "@/requests/hooks/customer";
import {
  DEFAULT_PRIVACY_POLICY_PATH,
  DEFAULT_TERMS_OF_SERVICE_PATH,
  PERMALINK_URL_REGEX,
  URL_REGEX,
} from "@/utils/const";
import { questionnaireStepValidation } from "@/utils/smsOnboarding/validation";
import { HelpTwoTone, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { InferType, boolean, object, string } from "yup";

const formSchema = object({
  legalCompanyName: string().nullable().label("Legal Company Name"),
  tradingName: string().nullable().label("Trading Name"),
  privacyEmail: string().email().nullable().label("Privacy Email"),
  storeDescription: string().nullable().label("Store Description"),
  marketsToUsContacts: boolean().label("US SMS Marketing"),
  storeLocation: string().nullable().label("Store Location"),
  smsPrivacyPolicyUrl: string()
    .matches(URL_REGEX, {
      message: "Not a valid URL",
      excludeEmptyString: true,
    })
    .nullable()
    .label("SMS Privacy Policy URL"),
  mobileTermsOfServiceUrl: string()
    .matches(URL_REGEX, {
      message: "Not a valid URL",
      excludeEmptyString: true,
    })
    .nullable()
    .label("Terms of Service URL"),
  permalinkUrl: string()
    .matches(PERMALINK_URL_REGEX, {
      message: "Not a valid permalink URL",
      excludeEmptyString: true,
    })
    .nullable()
    .label("Checkout Permalink URL"),
});

type ISmsOnboardingQuestionnaire = InferType<typeof formSchema>;

const Questionnaire = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;
  const navigate = useNavigate();
  const [
    isQuestionnaireProgressionModalOpen,
    setIsQuestionnaireProgressionModalOpen,
  ] = useState<boolean>(false);

  const { data: smsOnboardingData, refetch: refetchSmsOnboardingData } =
    useSmsOnboardingProgress(id);

  const {
    mutate: saveQuestionnaire,
    isPending: isSaveQuestionnairePending,
    isSuccess: isSaveQuestionnaireSuccess,
    isError: isSaveQuestionnaireError,
  } = useSaveSmsOnboardingQuestionnaire();

  useEffect(() => {
    if (isSaveQuestionnaireSuccess) {
      toast("Successfully saved answers", { type: "success" });
      refetchSmsOnboardingData();
      handleStepProgression();
    }

    if (isSaveQuestionnaireError)
      toast("Failed to save answers", { type: "error" });
  }, [isSaveQuestionnaireSuccess, isSaveQuestionnaireError]);

  const {
    data: customerData,
    isLoading: isCustomerLoading,
    error: customerError,
  } = useCustomer(id);

  const getDefaultUrl = (
    type: "smsPrivacyPolicyUrl" | "mobileTermsOfServiceUrl",
  ) => {
    let url: string | null | undefined = smsOnboardingData?.questionnaire[type];

    if (!url) {
      url = customerData?.companyWebsiteUrl
        ? `${customerData.companyWebsiteUrl}${
            type === "mobileTermsOfServiceUrl"
              ? DEFAULT_TERMS_OF_SERVICE_PATH
              : DEFAULT_PRIVACY_POLICY_PATH
          }`
        : null;
    }

    if (!url?.startsWith("https://")) url = "https://" + url;

    return url;
  };

  const {
    register,
    formState: { errors },
    control,
    watch,
    handleSubmit,
    reset,
    getValues,
  } = useForm<ISmsOnboardingQuestionnaire>({
    defaultValues: {
      legalCompanyName: smsOnboardingData?.questionnaire?.legalCompanyName,
      tradingName: smsOnboardingData?.questionnaire?.tradingName,
      privacyEmail: smsOnboardingData?.questionnaire?.privacyEmail,
      storeDescription: smsOnboardingData?.questionnaire?.storeDescription,
      marketsToUsContacts:
        smsOnboardingData?.questionnaire?.marketsToUsContacts,
      storeLocation: smsOnboardingData?.questionnaire?.storeLocation,
      smsPrivacyPolicyUrl: getDefaultUrl("smsPrivacyPolicyUrl"),
      mobileTermsOfServiceUrl: getDefaultUrl("mobileTermsOfServiceUrl"),
      permalinkUrl: smsOnboardingData?.questionnaire?.permalinkUrl,
    },
  });

  if (isCustomerLoading) return <PageLoader title="Questionnaire" />;
  if (customerError || !customerData)
    return <Error error={customerError || "Customer data unavailable."} />;

  const handleReset = () => reset();

  const submitForm = (body: ISmsOnboardingQuestionnaire) => {
    const payload = {
      customerVersion: smsOnboardingData?.customerVersion!,
      merchantIntegrationId: smsOnboardingData?.merchantIntegrationId!,
      merchantIntegrationVersion:
        smsOnboardingData?.merchantIntegrationVersion!,
      ...body,
    };

    saveQuestionnaire({
      id,
      body: payload,
    });
  };

  const toggleModal = () =>
    setIsQuestionnaireProgressionModalOpen(isModalOpen => !isModalOpen);

  const handleStepProgression = () => {
    const data = getValues();
    const step = steps.find(step => step.identifier === "questionnaire")!;

    const { calculatedStatus } = questionnaireStepValidation({
      step,
      data,
    });

    const isComplete = calculatedStatus === "PASS";

    if (!isComplete) {
      toggleModal();
      return;
    }

    toast("Questionnaire complete, progressing to next step", {
      type: "info",
    });
    setTimeout(() => {
      navigate(`/customer/${id}/sms-onboarding/company-details`);
    }, 3000);
  };

  const renderHeading = () => (
    <Box mb={3}>
      <Typography variant="h6" color="secondary">
        Initial Questionnaire
      </Typography>
      <Typography variant="body2">
        Please fill out the answers to the below questions with the customer.
      </Typography>
    </Box>
  );

  const renderHeadingBeforeCall = () => (
    <>
      <Typography variant="h6" color="secondary">
        Fill in before the call
      </Typography>
      <Divider />
    </>
  );

  const renderQuestion = ({
    title,
    description,
    formElement,
  }: {
    title: ReactNode;
    description: ReactNode;
    formElement: ReactNode;
  }) => (
    <>
      <Box>
        <Typography variant="subtitle2" color="secondary">
          {title}
        </Typography>
        <Typography variant="body2" maxWidth={1024} sx={{ mb: 1 }}>
          {description}
        </Typography>
        {formElement}
      </Box>
      <Divider />
    </>
  );

  const renderTextInput = ({
    name,
    label,
    multiline = false,
    isDisabled = false,
  }: {
    name:
      | "legalCompanyName"
      | "tradingName"
      | "privacyEmail"
      | "storeDescription"
      | "storeLocation"
      | "smsPrivacyPolicyUrl"
      | "mobileTermsOfServiceUrl"
      | "permalinkUrl";
    label: string;
    multiline?: boolean;
    isDisabled?: boolean;
  }) => (
    <TextField
      margin="dense"
      id={name}
      label={label}
      type="text"
      fullWidth
      variant="outlined"
      multiline={multiline}
      {...register(name)}
      error={!!errors?.[name]}
      helperText={errors?.[name]?.message}
      sx={{ mb: 1 }}
      disabled={isDisabled}
    />
  );

  const renderRadioInput = ({
    field,
  }: {
    field: ControllerRenderProps<
      ISmsOnboardingQuestionnaire,
      "marketsToUsContacts"
    >;
  }) => (
    <RadioGroup
      row
      {...field}
      onChange={e => field.onChange(e.target.value === "true")}>
      <FormControlLabel value="true" control={<Radio />} label="Yes" />
      <FormControlLabel value="false" control={<Radio />} label="No" />
    </RadioGroup>
  );

  const renderMarketsToUSContacts = () => (
    <Controller
      control={control}
      name="marketsToUsContacts"
      defaultValue={false}
      render={renderRadioInput}
    />
  );

  const renderSaveAndReset = () => (
    <Box display="flex" justifyContent="space-between" mt={3}>
      <Button color="error" onClick={handleReset}>
        Clear Form
      </Button>
      <LoadingButton
        variant="contained"
        color="secondary"
        loading={isSaveQuestionnairePending}
        disabled={isSaveQuestionnairePending}
        loadingPosition="start"
        startIcon={<Save />}
        type="submit"
        form="sms-onboarding-questionnaire-form">
        Save Answers
      </LoadingButton>
    </Box>
  );

  const renderQuestionnaireProgressionModal = () => (
    <SmsOnboardingQuestionnaireModal
      open={isQuestionnaireProgressionModalOpen}
      onClose={toggleModal}
    />
  );

  return (
    <>
      <form
        id="sms-onboarding-questionnaire-form"
        onSubmit={handleSubmit(submitForm)}>
        <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
          <Stack direction="column" gap={3}>
            {renderHeadingBeforeCall()}
            {renderQuestion({
              title: "1. Checkout Permalink URL",
              description: (
                <span>
                  What is your store's Checkout Permalink?{" "}
                  <Link
                    target="_blank"
                    href="https://team-1634040913267.atlassian.net/wiki/spaces/DEV/pages/90701832/Customer+Service+Playbook#Create-a-Checkout-Permalink">
                    Instructions
                  </Link>
                </span>
              ),
              formElement: renderTextInput({
                name: "permalinkUrl",
                label: "Checkout Permalink URL",
              }),
            })}

            {renderQuestion({
              title: "2. Privacy Policy URL (SMS)",
              description: "What is your store's Privacy Policy URL?",
              formElement: renderTextInput({
                name: "smsPrivacyPolicyUrl",
                label: "SMS Privacy Policy URL",
                isDisabled: true,
              }),
            })}

            {renderQuestion({
              title: "3. Mobile Terms of Service URL",
              description: "What is your store's Terms of Service URL?",
              formElement: renderTextInput({
                name: "mobileTermsOfServiceUrl",
                label: "Mobile Terms of Service URL",
                isDisabled: true,
              }),
            })}
          </Stack>
        </Paper>

        <Paper elevation={1} sx={{ p: 3 }}>
          <Stack direction="column" gap={3}>
            {renderHeading()}
            {renderQuestion({
              title: "4. Legal Company Name",
              description: "What is your store's Legal Company Name?",
              formElement: renderTextInput({
                name: "legalCompanyName",
                label: "Legal Company Name",
              }),
            })}

            {renderQuestion({
              title: "5. Trading Name",
              description: "What is your store's Trading Name?",
              formElement: renderTextInput({
                name: "tradingName",
                label: "Trading Name",
              }),
            })}

            {renderQuestion({
              title: "6. Privacy Email Address",
              description:
                "What is your store's Privacy/Marketing contact email address?",
              formElement: renderTextInput({
                name: "privacyEmail",
                label: "Privacy Email",
              }),
            })}

            {renderQuestion({
              title: (
                <Box display="flex" alignItems="center" gap={1}>
                  7. Store's Goods / Services{" "}
                  <Tooltip title="E.g. Louis Copeland is 'clothing, footwear, accessories, and gift vouchers'">
                    <HelpTwoTone fontSize="inherit" />
                  </Tooltip>
                </Box>
              ),
              description:
                "Describe your store's goods and services offering in a couple of words.",
              formElement: renderTextInput({
                name: "storeDescription",
                label: "Store's Goods / Services",
                multiline: true,
              }),
            })}

            {renderQuestion({
              title: "8. US SMS Marketing",
              description:
                "Will your store be sending SMS marketing to US contacts?",
              formElement: renderMarketsToUSContacts(),
            })}

            {!!watch("marketsToUsContacts") &&
              renderQuestion({
                title: (
                  <Box display="flex" alignItems="center" gap={1}>
                    5 (a). Arbitration Location{" "}
                    <Tooltip title="We need this information to populate their Mobile Terms of Service. In the case of any dispute with an SMS recipient in the US, arbitration would take place in the location specified.">
                      <HelpTwoTone fontSize="inherit" />
                    </Tooltip>
                  </Box>
                ),
                description: (
                  <span>
                    If you have a base in the US, can you provide the city and
                    state where it is located? If you don't have a base in the
                    US, please provide the city and state where your HQ is
                    located.{" "}
                    <strong>
                      It must be in the format of "City, State" (e.g. San
                      Francisco, California).
                    </strong>
                  </span>
                ),
                formElement: renderTextInput({
                  name: "storeLocation",
                  label: "Arbitration Location",
                }),
              })}
          </Stack>
          {renderSaveAndReset()}
          {renderQuestionnaireProgressionModal()}
        </Paper>
      </form>
    </>
  );
};

export default Questionnaire;
