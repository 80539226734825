import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { Stack, Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";

interface IProps {
  params: GridRenderCellParams;
  toggleEditModal: (param: ISmsCountrySetting | undefined) => void;
  toggleDeleteModal: (param: ISmsCountrySetting) => void;
}

const RenderSmsPhoneNumberActions = ({
  params,
  toggleEditModal,
  toggleDeleteModal,
}: IProps) => {
  const handleToggleEditModal = () => toggleEditModal(params.row);
  const handleToggleDeleteModal = () => toggleDeleteModal(params.row);

  return (
    <Stack direction="row" height="100%" gap={1} alignItems="center">
      <Button
        variant="contained"
        color="error"
        size="medium"
        onClick={handleToggleDeleteModal}>
        <DeleteTwoTone />
      </Button>
      <Button variant="contained" color="info" onClick={handleToggleEditModal}>
        <EditTwoTone />
      </Button>
    </Stack>
  );
};

export default RenderSmsPhoneNumberActions;
