import { Box, LinearProgress, Typography } from "@mui/material";

const PageLoader = ({ title }: { title: string }) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Typography variant="h5" mb={1}>
        Loading {title}...
      </Typography>
      <Box
        sx={{
          width: "25%",
        }}>
        <LinearProgress color="primary" />
      </Box>
    </Box>
  );
};

export default PageLoader;
