import { Box, Skeleton } from "@mui/material";

const SmsOnboardingLoader = () => {
  return (
    <>
      <Skeleton variant="rounded" height={48} sx={{ mb: 3 }} />
      <Box display="flex" alignItems="stretch" gap={3}>
        <Box flexShrink={0} minWidth={300}>
          <Skeleton variant="rounded" height={576} />
        </Box>
        <Box flexGrow={1} sx={{ height: "100%" }}>
          <Skeleton variant="rounded" height={1500} />
        </Box>
      </Box>
    </>
  );
};

export default SmsOnboardingLoader;
