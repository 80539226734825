import { useTimeline } from "@/requests/hooks/timeline";
import { ArrowLeftTwoTone, ArrowRightTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Timeline as TimelineComponent } from "@/components/Timeline";
import { Error } from "@/components/Error";
import { PageLoader } from "@/components/Loaders";

const Timeline = () => {
  const [page, setPage] = useState<number>(1);

  const {
    data: timelineData,
    isLoading: isTimelineLoading,
    error: timelineError,
  } = useTimeline({
    page,
    pageSize: 50,
  });

  const handlePrevious = () => {
    setPage(page => page - 1);
  };

  const handleNext = () => {
    setPage(page => page + 1);
  };

  const renderTimeline = () => (
    <TimelineComponent global events={timelineData?.results} />
  );

  const renderPagination = () => (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <LoadingButton
        disabled={page === 1}
        loading={isTimelineLoading}
        loadingPosition="start"
        variant="contained"
        color="secondary"
        startIcon={<ArrowLeftTwoTone />}
        onClick={handlePrevious}>
        Previous
      </LoadingButton>
      <LoadingButton
        disabled={!timelineData?.results?.length}
        loading={isTimelineLoading}
        loadingPosition="end"
        variant="contained"
        color="secondary"
        endIcon={<ArrowRightTwoTone />}
        onClick={handleNext}>
        Next
      </LoadingButton>
    </Box>
  );

  if (isTimelineLoading) return <PageLoader title="timeline" />;
  if (!!timelineError) return <Error error={timelineError} />;

  return (
    <>
      <Box>
        <Typography variant="h4">Timeline</Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <Paper elevation={1} sx={{ p: 3 }}>
        <Stack spacing={3}>
          {renderPagination()}
          {renderTimeline()}
          {renderPagination()}
        </Stack>
      </Paper>
    </>
  );
};

export default Timeline;
