import { Header } from "@/components/Customer";
import { useCustomer } from "@/requests/hooks/customer";
import {
  FormatListBulletedTwoTone,
  PaymentsTwoTone,
  CookieTwoTone,
  Settings,
  SettingsInputComponent,
  SupervisedUserCircleTwoTone,
  MobileFriendly,
  InsightsTwoTone,
  ScienceTwoTone,
} from "@mui/icons-material";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  SvgIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { useFlags } from "flagsmith/react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";

const CustomerLayout = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;
  const { pathname } = useLocation();
  const flags = useFlags([
    "sms-onboarding-enabled",
    "admin-panel-ab-test-enabled",
  ]);

  const isSmsOnboardingEnabled = flags["sms-onboarding-enabled"]?.enabled;
  const isAbTestEnabled = flags["admin-panel-ab-test-enabled"]?.enabled;

  const { data: customerData, isLoading: isCustomerLoading } = useCustomer(id);

  const drawerWidth = 240;

  const pages: IPage[] = [
    {
      text: "Overview",
      href: "",
      icon: InsightsTwoTone,
      isActive: () =>
        new RegExp("\\d+$").test(pathname) || pathname.includes("/drilldown/"),
    },
    {
      text: "Timeline",
      href: "timeline",
      icon: FormatListBulletedTwoTone,
    },
    {
      text: "Billing",
      href: "billing",
      icon: PaymentsTwoTone,
    },
    {
      text: "Cookiebot",
      href: "cookiebot",
      icon: CookieTwoTone,
    },
    {
      text: "Widget Settings",
      href: "widget-settings",
      icon: Settings,
    },
    {
      text: "Integration Settings",
      href: "integration-settings",
      icon: SettingsInputComponent,
    },
    {
      text: "Users",
      href: "users",
      icon: SupervisedUserCircleTwoTone,
      isActive: () => pathname.includes("/users"),
    },
    {
      text: "SMS Onboarding",
      href: "sms-onboarding/questionnaire",
      icon: MobileFriendly,
      isActive: () => pathname.includes("/sms-onboarding/"),
      hidden: !isSmsOnboardingEnabled,
    },
    {
      text: "A/B Testing",
      href: "ab-testing",
      icon: ScienceTwoTone,
      isActive: () => pathname.includes("/ab-testing/"),
      hidden: !isAbTestEnabled,
    },
  ];

  const checkIsActive = ({
    href,
    isActive,
  }: {
    href: IPage["href"];
    isActive: IPage["isActive"];
  }) => {
    if (!!isActive) {
      return isActive();
    }

    return pathname.endsWith(href);
  };

  const renderSidebarLinks = () =>
    pages
      .filter(({ hidden }) => !hidden)
      .map(({ href, icon, text, isActive }, index) => (
        <ListItem key={index} disablePadding>
          <ListItemButton
            component={Link}
            to={href}
            selected={checkIsActive({ href, isActive })}>
            <ListItemIcon>
              <SvgIcon component={icon} />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton>
        </ListItem>
      ));

  const renderCustomerHeader = () => {
    return <Header isLoading={isCustomerLoading} customer={customerData} />;
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
      }}>
      <Box
        sx={{
          width: drawerWidth,
          display: "flex",
          flexDirection: "column",
          flexShrink: 0,
          backgroundColor: "white",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
          paddingTop: "8px",
          paddingBottom: "8px",
          minHeight: "calc(100vh - 64px)",
        }}>
        <Box sx={{ position: "fixed", width: drawerWidth }}>
          {renderSidebarLinks()}
        </Box>
      </Box>
      <Box p={3} flexGrow={1}>
        {renderCustomerHeader()}
        <Outlet />
      </Box>
    </Box>
  );
};

export default CustomerLayout;
