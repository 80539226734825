import {
  AppBar,
  Box,
  Drawer,
  Toolbar,
  Typography,
  Tooltip,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  List,
  Divider,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import {
  MeetingRoomTwoTone,
  PeopleAltTwoTone,
  QueryStatsTwoTone,
  ViewTimelineTwoTone,
} from "@mui/icons-material";
import { useLogout } from "@/requests/hooks/user";

import datashipsLogo from "@/assets/svg/logo/mark_color.svg";
import { ScrollToTop } from "@/components/Navigation";
import { useFlags } from "flagsmith/react";

interface IProps {
  hasSidebar?: boolean;
  children: React.ReactNode;
}

const AppLayout = ({ hasSidebar = false, children }: IProps) => {
  const { pathname } = useLocation();
  const { mutate: logout, isPending: isLogoutPending } = useLogout();
  const flags = useFlags(["admin-panel-stats-enabled"]);

  const isAdminPanelStatsEnabled = flags["admin-panel-stats-enabled"]?.enabled;

  const drawerWidth = hasSidebar ? 56 : 240;

  const pages: IPage[] = [
    {
      text: "Customers",
      href: "/",
      icon: PeopleAltTwoTone,
    },
    {
      text: "Overview",
      href: "/overview",
      icon: QueryStatsTwoTone,
      hidden: !isAdminPanelStatsEnabled,
      isActive: () => pathname.startsWith("/overview"),
    },
    {
      text: "Timeline",
      href: "/timeline",
      icon: ViewTimelineTwoTone,
    },
  ];

  const handleLogout = () => logout();

  const checkIsActive = ({
    href,
    isActive,
  }: {
    href: IPage["href"];
    isActive: IPage["isActive"];
  }) => {
    if (!!isActive) {
      return isActive();
    }

    return pathname.endsWith(href);
  };

  const wrapTooltip = ({
    index,
    element,
    title,
  }: {
    index: number;
    element: JSX.Element;
    title: string;
  }) => (
    <Tooltip key={index} title={title} placement="right">
      {element}
    </Tooltip>
  );

  const renderSidebarLinks = () => {
    return pages
      .filter(({ hidden }) => !hidden)
      .map(({ href, icon, text, isActive }, index) => {
        const listItemElement = (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={Link}
              to={href}
              selected={checkIsActive({ href, isActive })}>
              <ListItemIcon>
                <SvgIcon component={icon} />
              </ListItemIcon>
              <ListItemText primary={hasSidebar ? "-" : text} />
            </ListItemButton>
          </ListItem>
        );

        if (hasSidebar)
          return wrapTooltip({
            index,
            element: listItemElement,
            title: text,
          });

        return listItemElement;
      });
  };

  const renderLogoutButton = () => {
    const logoutElement = (
      <ListItem disablePadding onClick={handleLogout}>
        <ListItemButton disabled={isLogoutPending}>
          <ListItemIcon>
            <SvgIcon component={MeetingRoomTwoTone} />
          </ListItemIcon>
          {!hasSidebar && (
            <ListItemText
              primary={isLogoutPending ? "Logging out..." : "Log Out"}
            />
          )}
        </ListItemButton>
      </ListItem>
    );

    if (hasSidebar)
      return wrapTooltip({
        index: 0,
        element: logoutElement,
        title: "Log out",
      });

    return logoutElement;
  };

  return (
    <>
      <ScrollToTop />
      <Box display="flex" bgcolor="#F5F5F5">
        <AppBar
          position="fixed"
          color="secondary"
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
          <Toolbar component={Link} to="/" sx={{ textDecoration: "none" }}>
            <img src={datashipsLogo} height={35} />
            <Typography
              variant="h6"
              noWrap
              component="div"
              color="#FFFFFF"
              sx={{ ml: 3 }}>
              Dataships Admin Panel
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              overflowX: "hidden",
              msOverflowX: "-moz-hidden-unscrollable",
            },
          }}>
          <Toolbar />
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              justifyContent: "space-between",
            }}>
            <Box
              sx={{
                overflowX: "hidden",
                msOverflowX: "-moz-hidden-unscrollable",
              }}>
              <List>{renderSidebarLinks()}</List>
            </Box>

            <Box>
              <Divider />
              <List>{renderLogoutButton()}</List>
            </Box>
          </Box>
        </Drawer>
        <Box
          sx={{
            flexGrow: 1,
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}>
          <Toolbar />
          <Box component="main" sx={{ p: hasSidebar ? 0 : 3, flexGrow: 1 }}>
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AppLayout;
