import {
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Error } from "@/components/Error";
import { PageLoader } from "@/components/Loaders";
import { useCookiebot, useCustomer } from "@/requests/hooks/customer";
import { useParams } from "react-router-dom";
import { InferType, object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useEffect } from "react";

const cookiebotFormSchema = object({
  domain: string().required().label("Domain"),
  domainGroupId: string().required().label("Domain Group ID"),
  apiKey: string().required().label("API Key"),
});

type ICookiebotFormData = InferType<typeof cookiebotFormSchema>;

const Cookiebot = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const {
    data: customerData,
    isLoading: isCustomerLoading,
    error: customerError,
  } = useCustomer(id);

  const methods = useForm<ICookiebotFormData>({
    resolver: yupResolver(cookiebotFormSchema),
    defaultValues: {
      domain: customerData?.cookiebot?.domain,
      domainGroupId: customerData?.cookiebot?.domainGroupId,
      apiKey: customerData?.cookiebot?.apiKey,
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const {
    mutate: saveCookiebot,
    isPending: isSaveCookiebotPending,
    isSuccess: isSaveCookiebotSuccess,
    isError: isSaveCookiebotError,
  } = useCookiebot();

  useEffect(() => {
    if (isSaveCookiebotSuccess) {
      toast("Saved Cookiebot details", { type: "success" });
    }

    if (isSaveCookiebotError)
      toast("Unable to save Cookiebot details", { type: "error" });
  }, [isSaveCookiebotSuccess, isSaveCookiebotError]);

  const submitForm = (data: ICookiebotFormData) =>
    saveCookiebot({ customerId: parseInt(id), ...data });

  if (customerError) return <Error error={customerError} />;
  if (isCustomerLoading) return <PageLoader title="customer" />;

  const renderForm = () => (
    <form id="cookiebot-form" onSubmit={handleSubmit(submitForm)}>
      <Stack spacing={2} direction="column">
        <TextField
          autoFocus
          id="domain"
          label="Domain"
          type="text"
          variant="outlined"
          fullWidth
          {...register("domain")}
          error={!!errors.domain}
          helperText={errors.domain?.message}
        />

        <TextField
          id="domainGroupId"
          label="Domain Group ID"
          type="text"
          variant="outlined"
          fullWidth
          {...register("domainGroupId")}
          error={!!errors.domainGroupId}
          helperText={errors.domainGroupId?.message}
        />

        <TextField
          id="apiKey"
          label="API Key"
          type="text"
          variant="outlined"
          fullWidth
          {...register("apiKey")}
          error={!!errors.apiKey}
          helperText={errors.apiKey?.message}
        />
      </Stack>
    </form>
  );

  const renderExampleForm = () => (
    <Stack spacing={2} direction="column">
      <TextField
        disabled
        label="Domain"
        type="text"
        variant="outlined"
        fullWidth
        defaultValue="nasa.gov"
      />

      <TextField
        disabled
        label="Domain Group ID"
        type="text"
        variant="outlined"
        fullWidth
        defaultValue="63data02-600c-40af-87c7-93b248a5hip5"
      />

      <TextField
        disabled
        label="API Key"
        type="text"
        variant="outlined"
        fullWidth
        defaultValue="NSs5ZWR0RUxaUHhCVmZ4UGVha3pDZ1M4ZGM4MXcwWG1ldDBYUkV3amRGTjJ5c2phs0m3Ap1K3y"
      />
    </Stack>
  );

  const renderButtons = () => (
    <Stack direction="row" justifyContent="space-between" mt={3}>
      <Button color="error" onClick={() => reset()}>
        Clear
      </Button>
      <LoadingButton
        variant="contained"
        color="secondary"
        loading={isSaveCookiebotPending}
        loadingPosition="start"
        startIcon={<Save />}
        type="submit"
        form="cookiebot-form">
        Save
      </LoadingButton>
    </Stack>
  );

  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item xs={6}>
        <Paper elevation={1} sx={{ p: 3, height: "100%" }}>
          <Typography variant="h6" mb={3}>
            Cookiebot Details
          </Typography>
          {renderForm()}
          {renderButtons()}
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper elevation={1} sx={{ p: 3, height: "100%" }}>
          <Typography variant="h6" mb={3}>
            Example
          </Typography>
          {renderExampleForm()}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Cookiebot;
