import { DomainAddTwoTone } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import CreateCustomerForm, {
  ICreateCustomerFormData,
  createCustomerFormSchema,
} from "@/components/Forms/CreateCustomerForm";
import { CreateUserForm } from "@/components/Forms";
import { toast } from "react-toastify";
import { useCreateCustomer } from "@/requests/hooks/customer";

interface IProps {
  open: boolean;
  onClose: () => void;
  refetchCustomers: any;
}

const CreateCustomerModal = ({ open, onClose, refetchCustomers }: IProps) => {
  const methods = useForm<ICreateCustomerFormData>({
    resolver: yupResolver(createCustomerFormSchema),
  });

  const { handleSubmit, reset } = methods;

  const {
    mutate: createCustomer,
    isPending: isCreateCustomerPending,
    isSuccess: isCreateCustomerSuccess,
    isError: isCreateCustomerError,
  } = useCreateCustomer();

  useEffect(() => {
    if (isCreateCustomerSuccess) {
      toast("Created customer", { type: "success" });
      refetchCustomers();
      handleOnClose();
    }

    if (isCreateCustomerError)
      toast("Unable to create customer", { type: "error" });
  }, [isCreateCustomerSuccess, isCreateCustomerError]);

  const submitForm = (data: ICreateCustomerFormData) => createCustomer(data);

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const renderDialogTitle = () => <DialogTitle>Create Customer</DialogTitle>;

  const renderDialogDescription = () => (
    <DialogContentText mb={2}>
      This will create a customer, place them in onboarding and create the Owner
      user for the customer.
    </DialogContentText>
  );

  const renderDialogForm = () => (
    <FormProvider {...methods}>
      <form id="create-customer-form" onSubmit={handleSubmit(submitForm)}>
        <Stack spacing={2}>
          <CreateCustomerForm />

          <DialogContentText sx={{ fontWeight: "bold" }}>
            Customer Owner details
          </DialogContentText>

          <CreateUserForm showGroupAndPersonas={false} />
        </Stack>
      </form>
    </FormProvider>
  );

  const renderDialogButtons = () => (
    <DialogActions>
      <Button color="error" onClick={handleOnClose}>
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        color="secondary"
        loading={isCreateCustomerPending}
        loadingPosition="start"
        startIcon={<DomainAddTwoTone />}
        type="submit"
        form="create-customer-form">
        Create Customer
      </LoadingButton>
    </DialogActions>
  );

  return (
    <Dialog open={open} onClose={handleOnClose}>
      {renderDialogTitle()}
      <DialogContent>
        {renderDialogDescription()}
        {renderDialogForm()}
      </DialogContent>
      {renderDialogButtons()}
    </Dialog>
  );
};

export default CreateCustomerModal;
