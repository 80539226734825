import { useUser } from "@/requests/hooks/user";
import {
  CancelTwoTone,
  CheckCircleTwoTone,
  PaymentTwoTone,
  StopCircleTwoTone,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { useFlags } from "flagsmith/react";

interface IProps {
  toggleDelinquentModal: () => void;
  delinquentStatus?: IPreference;
}

const OverallStatus = ({ toggleDelinquentModal, delinquentStatus }: IProps) => {
  const { data: user } = useUser();
  const {
    palette: { success, error },
  } = useTheme();
  const flags = useFlags(["admin-panel-customer-delinquent-enabled"]);
  const isCustomerDelinquentEnabled =
    flags?.["admin-panel-customer-delinquent-enabled"]?.enabled;

  if (!isCustomerDelinquentEnabled) return null;

  const delinquent = delinquentStatus?.booleanValue;
  const updatedBy = delinquentStatus?.updatedBy;
  const updatedAt = delinquentStatus?.updatedAt;
  const createdAt = delinquentStatus?.createdAt;
  const createdBy = delinquentStatus?.createdBy;

  const renderCustomerStatus = () => (
    <Box display="flex" alignItems="center" flexGrow="1" gap={2}>
      {delinquent ? (
        <CancelTwoTone
          sx={{
            color: error.main,
          }}
        />
      ) : (
        <CheckCircleTwoTone
          sx={{
            color: success.main,
          }}
        />
      )}
      {delinquent ? "Delinquent" : "Engaged"}
    </Box>
  );

  const renderGrid = () => (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell align="center">Updated By</TableCell>
            <TableCell align="center">Last Updated At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">{renderCustomerStatus()}</TableCell>
            <TableCell align="center">
              {updatedBy || createdBy || "-"}
            </TableCell>
            <TableCell align="center">
              {!!(updatedAt || createdAt)
                ? dayjs(updatedAt || createdAt).format("YYYY-MM-DD HH:mm")
                : "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );

  const renderHeaderAndCTA = () => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      mb={1}>
      <Typography variant="h5">Overall Status</Typography>
      {!user?.scopes?.includes("UPDATE_DELINQUENT_STATUS") && (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={delinquent ? <PaymentTwoTone /> : <StopCircleTwoTone />}
          onClick={toggleDelinquentModal}>
          Mark as {delinquent ? "Engaged" : "Delinquent"}
        </Button>
      )}
    </Box>
  );

  return (
    <Box mb={4}>
      {renderHeaderAndCTA()}
      {renderGrid()}
    </Box>
  );
};

export default OverallStatus;
