import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import ReactCountryFlag from "react-country-flag";

const RenderSmsPhoneNumberCountry = (params: GridRenderCellParams) => {
  const {
    row: { countryName, countryAlpha2 },
  } = params;

  return (
    <Box display="flex" alignItems="center" gap={1} height="100%">
      <ReactCountryFlag countryCode={countryAlpha2} />
      <span>
        {countryName} ({countryAlpha2})
      </span>
    </Box>
  );
};

export default RenderSmsPhoneNumberCountry;
