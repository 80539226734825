import { SplashScreen } from "@/components/Loaders";
import { useUser } from "@/requests/hooks/user";
import flagsmith from "flagsmith";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

interface IProps {
  children: React.ReactNode;
}

const UnprotectedRoute = ({ children }: IProps) => {
  const {
    data: user,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
  } = useUser();

  useEffect(() => {
    if (isUserSuccess && flagsmith.identity !== user?.email) {
      flagsmith.identify(user.email);
    }
  }, [isUserSuccess]);

  if (isUserLoading) return <SplashScreen />;

  if (user) return <Navigate to="/" replace />;

  return children;
};

export default UnprotectedRoute;
