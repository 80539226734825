import { GridRenderCellParams } from "@mui/x-data-grid";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import { Box } from "@mui/material";

const RenderBooleanIcon = (params: GridRenderCellParams) => {
  const { value } = params;

  const renderIcon = () => {
    if (value) {
      return <CheckCircleTwoToneIcon color="success" />;
    }

    return <CancelTwoToneIcon color="error" />;
  };

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center">
      {renderIcon()}
    </Box>
  );
};

export default RenderBooleanIcon;
