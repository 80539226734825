import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

interface IProps {
  open: boolean;
  onClose: () => void;
}

const SmsOnboardingQuestionnaireModal = ({ open, onClose }: IProps) => {
  const { pathname } = useLocation();

  const renderDialogTitle = () => (
    <DialogTitle>Questionnaire incomplete</DialogTitle>
  );

  const renderDialogDescription = () => (
    <DialogContentText mb={2}>
      Your answers were saved successfully but the questionnaire is not
      complete. Do you want to stay on this step, or progress to the next step?
    </DialogContentText>
  );

  const renderDialogButtons = () => (
    <DialogActions>
      <Button color="error" onClick={onClose}>
        Stay here
      </Button>
      <Button
        variant="contained"
        color="secondary"
        component={Link}
        to={pathname.replace("questionnaire", "company-details")}>
        Next Step
      </Button>
    </DialogActions>
  );

  return (
    <Dialog open={open} onClose={onClose}>
      {renderDialogTitle()}
      <DialogContent>{renderDialogDescription()}</DialogContent>
      {renderDialogButtons()}
    </Dialog>
  );
};

export default SmsOnboardingQuestionnaireModal;
