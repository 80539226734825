import request from "@/requests/request";

export const getLatestAbTest = ({ customerId, signal }: IGetLatestAbTestRQ) =>
  request({
    url: `/v1/admin/ab-test/${customerId}/latest`,
    signal,
  }).then(res => res.data);

export const postCreateOrUpdateAbTest = ({
  customerId,
  id,
  version,
  abTestType,
  preTickedCountries,
  widgetConfigText,
  listType,
  privacyPolicyLinkPresent,
}: IPostCreateOrUpdateAbTestRQ) =>
  request({
    url: `/v1/admin/ab-test/${customerId}`,
    method: "POST",
    body: {
      id,
      version,
      abTestType,
      preTickedCountries,
      widgetConfigText,
      listType,
      privacyPolicyLinkPresent,
    },
  }).then(res => res.data);

export const postStartAbTest = ({
  abTestId,
  version,
}: IPostStartStopAbTestRQ) =>
  request({
    url: `/v1/admin/ab-test/${abTestId}/start`,
    method: "POST",
    body: {
      version,
    },
  }).then(res => res.data);

export const postStopAbTest = ({ abTestId, version }: IPostStartStopAbTestRQ) =>
  request({
    url: `/v1/admin/ab-test/${abTestId}/stop`,
    method: "POST",
    body: {
      version,
    },
  }).then(res => res.data);

export const getAllAbTests = ({
  params,
  signal,
}: {
  params: IGetAllAbTestsRQ;
  signal: IGenericHookOptions["signal"];
}) =>
  request({
    url: `/v1/admin/ab-test/${params.customerId}/all`,
    method: "GET",
    params,
    signal,
  }).then(res => res.data);
