import SmsOnboardingProgressButtons from "@/components/Buttons/SmsOnboardingProgressButtons";
import { Instructions } from "@/components/Typography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useFlags } from "flagsmith/react";
import InfoIcon from "@mui/icons-material/Info";

const AddExtension = () => {
  const flags = useFlags<"snippet-sms-enabled">(["snippet-sms-enabled"]);
  const isSmsSnippetEnabled = flags["snippet-sms-enabled"]?.enabled;

  const checkInfoEmailInstructions = isSmsSnippetEnabled
    ? [
        <Alert color="warning" icon={false}>
          If the customer <strong>doesn't</strong> have the{" "}
          <strong>Email Extension</strong> placed on the{" "}
          <strong>Checkout Information</strong> page then continue with the
          steps below. If they do have the Email Extension placed on the
          Checkout Information page then move down and follow the steps to add
          the tool to the Thank You page below.
        </Alert>,
        <>
          Under the <strong>Contact</strong> section on the Checkout Information
          page, Click on the <strong>Add app block</strong>
        </>,
        <>
          Within the Add app block section, click on the{" "}
          <strong>Dataships Email Collection</strong> block.
        </>,
        <>
          Expand the <strong>Checkout behavior</strong> section then tick the{" "}
          <strong>Include app block in Shop Pay</strong> option.
        </>,
        <>
          Click <strong>Save</strong> and refresh the page. You can now see the
          new Email widget preview on the checkout page.
        </>,
      ]
    : [];

  const checkoutInformationInstructions = [
    "Please go back to your Dataships Support page and find the Checkout settings URL ('Shopify Setup Instructions' step 6)",
    <>
      Go to{" "}
      <strong>
        Settings &gt; Checkout &gt; Customise your checkout section &gt;
        Customise button
      </strong>
      . This will open the Checkout page customisation where we need to add the
      Dataships SMS block.
    </>,
    <>
      Under the <strong>Delivery</strong> section on the Checkout Information
      page, Click on the <strong>Add app block</strong>
    </>,
    <>
      Within the Add app block section, click on the{" "}
      <strong>Dataships SMS Collection</strong> block
    </>,
    <>
      Expand the <strong>Checkout behavior</strong> section then tick the{" "}
      <strong>Include app block in Shop Pay</strong> option.
    </>,
    <>
      Tick the <strong>Automatically expand sections to show app</strong>{" "}
      option.
    </>,
    "When you go back to Checkout customisation, you can see the Dataships SMS Collection block added to the checkout step.",
    <>
      Click <strong>Save</strong> and refresh the page. You can now see the new
      SMS widget preview on the checkout page.
    </>,
    ...checkInfoEmailInstructions,
  ];

  const thankYouInstructions = [
    <>
      Click on the top center of the page that says <strong>Information</strong>
      , click on the <strong>Thank You</strong> page option from the drop down.
      This will open the Thank you page customisation page.
    </>,
    <>
      Click on the <strong>Add app block</strong> on the customisation page
    </>,
    <>
      Within the Add app block section, click on the{" "}
      <strong>Dataships SMS Collection</strong> block
    </>,
    "When you go back to Thank You customisation, you can see the Dataships SMS Collection block added to the thank you page step.",
    <>
      Click <strong>Save</strong> and refresh the page. You can now see the new
      SMS widget preview on the Thank You page.
    </>,
    <Alert color="warning" icon={false}>
      If the customer <strong>doesn't</strong> have the{" "}
      <strong>Email Extension</strong> placed on the <strong>Thank You</strong>{" "}
      page then continue with the steps below. If they do have the Email
      Extension placed on the Thank You page then you can complete this step.
    </Alert>,
    <>
      Under the <strong>Order Details</strong> section on the Thank You page,
      Click on the <strong>Add app block</strong>
    </>,
    <>
      Within the Add app block section, click on the{" "}
      <strong>Dataships Email Collection</strong> block.
    </>,
    <>
      Click <strong>Save</strong> and refresh the page. You can now see the new
      Email widget preview on the Thank You page.
    </>,
  ];

  const snippetInstructions = [
    <>
      Exit out of the extension editor, you should be back in your checkout
      settings in <strong>Shopify Admin</strong>
    </>,
    <>
      Scroll down to the <strong>Additional Scripts</strong> box in the Order
      Status page section
    </>,
    <>
      Please go back to your <strong>Dataships Support page</strong> and copy
      the <strong>“Dataships Snippet”</strong> in Step 5 of the Shopify Setup
      Instructions.
    </>,
    <>
      <Alert color="warning" icon={false}>
        <strong>
          IF THE DATASHIPS SNIPPET IS ALREADY IN THE ADDITIONAL SCRIPTS BOX FROM
          EMAIL ONBOARDING
        </strong>{" "}
        please delete the existing Dataships script and replace it with the one
        from the Dataships Support Page.
      </Alert>
      <p>
        Paste that into your <strong>Additional Scripts</strong> box as the very
        first script in that box.
      </p>
    </>,
    <>
      Click <strong>Save</strong>
    </>,
  ];

  const renderHeading = () => (
    <Box mb={3}>
      <Typography variant="h6" color="secondary">
        Adding the Dataships SMS Extension to your Checkout
      </Typography>
      <Typography variant="body2">
        We are now going to add the Dataships SMS consent collection extension
        to your Checkout Information and Thank You pages.
      </Typography>
    </Box>
  );

  const renderCheckoutInformationInstructions = () => (
    <Box mb={3}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
          Adding the extension to the Checkout Information page
        </AccordionSummary>
        <AccordionDetails>
          <Instructions
            title=""
            instructions={checkoutInformationInstructions}
            ordered
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  const renderThankYouInstructions = () => (
    <Box mb={3}>
      <Accordion defaultExpanded={!isSmsSnippetEnabled}>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
          <Box>
            Adding the <strong>extension</strong> to the Thank You page
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Instructions title="" instructions={thankYouInstructions} ordered />
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  const renderSnippetInstructions = () =>
    isSmsSnippetEnabled && (
      <Box mb={3}>
        <Accordion>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Box>
              Adding the <strong>snippet</strong> to the Thank You page
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Instructions title="" instructions={snippetInstructions} ordered />
          </AccordionDetails>
        </Accordion>
      </Box>
    );

  const renderNote = () =>
    isSmsSnippetEnabled && (
      <Box mb={3}>
        <Alert color="warning" icon={<InfoIcon />}>
          <strong>Note to Customer Success: </strong> If the customer has Thank
          you page extensibility, follow the instructions under the "Adding the
          extension" dropdown. If the customer is using Additional Scripts, the
          follow the instructions under "Adding the snippet" dropdown.{" "}
          <strong>Only one of the below dropdowns should be used.</strong>
        </Alert>
      </Box>
    );

  const renderStepButtons = () => (
    <SmsOnboardingProgressButtons
      currentUrl="add-extension"
      previousStepUrl="edit-policies"
      nextStepUrl="disable-shopify-sms"
    />
  );

  return (
    <Paper elevation={1} sx={{ p: 3, minHeight: "100%" }}>
      {renderHeading()}
      {renderCheckoutInformationInstructions()}
      {renderNote()}
      {renderThankYouInstructions()}
      {renderSnippetInstructions()}
      {renderStepButtons()}
    </Paper>
  );
};

export default AddExtension;
