import { CancelTwoTone, CheckCircleTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
} from "@mui/material";

import { IWidgetSettingsFormData } from "@/components/Forms/WidgetSettingsForm";
import { useFlags } from "flagsmith/react";

interface IProps {
  open: boolean;
  widgetSettingsData: IWidgetSettingsFormData;
  customer: ICustomer;
  isSaveWidgetSettingsPending: boolean;
  onClose: () => void;
}

const WidgetSettingsModal = ({
  widgetSettingsData,
  open,
  customer,
  isSaveWidgetSettingsPending,
  onClose,
}: IProps) => {
  const flags = useFlags(["admin-panel-toggle-cinch-enabled"]);
  const isToggleCinchEnabled =
    flags["admin-panel-toggle-cinch-enabled"]?.enabled;

  const renderConfirmationInfoRow = (
    label: string,
    value?: string | null | JSX.Element,
  ) => {
    const renderValue = () => {
      if (typeof value === "string" && value?.includes("https://")) {
        return (
          <a href={value} target="_blank">
            {value}
          </a>
        );
      }
      return (
        <span style={{ color: "black", display: "flex", gap: 5 }}>{value}</span>
      );
    };

    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <span>{label}</span>

        {renderValue()}
      </Box>
    );
  };

  const renderCinchValue = (enable: boolean = false) => {
    if (enable) {
      return (
        <>
          Yes <CheckCircleTwoTone color="success" />
        </>
      );
    }

    return (
      <>
        No <CancelTwoTone color="error" />
      </>
    );
  };

  const renderHideFooterValue = (enable: boolean = false) => {
    if (enable) {
      return (
        <>
          Yes <CheckCircleTwoTone color="success"/>
        </>
      );
    }

    return (
      <>
        No <CancelTwoTone color="error"/>
      </>
    );
  };

  const renderCinchRow = () => {
    if (!isToggleCinchEnabled) return null;

    return (
      <>
        <Divider />
        <ListItem>
          {renderConfirmationInfoRow(
            "Enable The Cinch",
            renderCinchValue(widgetSettingsData?.cinchEnabled),
          )}
        </ListItem>
      </>
    );
  };

  const renderHideFooterRow = () => {
    return (
      <>
        <Divider/>
        <ListItem>
          {renderConfirmationInfoRow(
            "Hide ‘Powered by Dataships’ logo",
            renderHideFooterValue(widgetSettingsData?.shouldHideFooter),
          )}
        </ListItem>
      </>
    );
  };

  const renderConfirmationInformation = () => (
    <List>
      <ListItem>
        {renderConfirmationInfoRow(
          "Privacy Email",
          widgetSettingsData?.privacyEmail,
        )}
      </ListItem>
      <Divider />
      <ListItem>
        {renderConfirmationInfoRow(
          "Billing Privacy Policy Url",
          widgetSettingsData?.privacyPolicyUrl,
        )}
      </ListItem>
      <Divider />
      <ListItem>
        {renderConfirmationInfoRow(
          "Mobile Terms of Service Url",
          widgetSettingsData?.mobileTermsOfServiceUrl,
        )}
      </ListItem>

      <>
        <Divider />
        <ListItem>
          {renderConfirmationInfoRow(
            "Permalink Url",
            widgetSettingsData?.permalinkUrl,
          )}
        </ListItem>
        {renderCinchRow()}
        {renderHideFooterRow()}
      </>
    </List>
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Confirm Widget Settings Updates</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Clicking confirm will update {customer?.companyName}'s <b>LIVE </b>
          widget settings. Please check over the updates to make sure they are
          correct before making these changes.
        </DialogContentText>

        <DialogContentText>
          Clicking cancel will exit without any changes being made.
        </DialogContentText>
      </DialogContent>
      {renderConfirmationInformation()}
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={isSaveWidgetSettingsPending}
          loadingPosition="start"
          startIcon={<CheckCircleTwoTone />}
          type="submit"
          form="widget-settings-form">
          Confirm Updates
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default WidgetSettingsModal;
