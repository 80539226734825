import {
  getAllAbTests,
  getLatestAbTest,
  postCreateOrUpdateAbTest,
  postStartAbTest,
  postStopAbTest,
} from "@/requests/api/abTest";
import { UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

export const useLatestAbTest = (
  customerId: IGetLatestAbTestRQ["customerId"],
): UseQueryResult<IGetLatestAbTestRS> =>
  useQuery({
    queryKey: ["ab-test", customerId, "active"],
    queryFn: ({ signal }) => getLatestAbTest({ customerId, signal }),
  });

export const useCreateOrUpdateAbTest = () =>
  useMutation({
    mutationFn: postCreateOrUpdateAbTest,
  });

export const useStartAbTest = () =>
  useMutation({
    mutationFn: postStartAbTest,
  });

export const useStopAbTest = () =>
  useMutation({
    mutationFn: postStopAbTest,
  });

export const useAllAbTest = (
  params: IGetAllAbTestsRQ,
): UseQueryResult<IGetAllAbTestsRS> =>
  useQuery({
    queryKey: ["ab-test", params.customerId, "all", JSON.stringify(params)],
    queryFn: ({ signal }) => getAllAbTests({ params, signal }),
  });
