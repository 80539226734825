import { IOnboardingStep } from "@/navigation/smsOnboardingSteps";
import { muiExtraStylesSpin } from "@/utils/muiExtraStyles";
import { CheckCircleOutlineTwoTone, SyncTwoTone } from "@mui/icons-material";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface ISmsOnboardingStepStatus {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  color: "primary" | "error" | "success" | "warning";
  sx?: {};
  calculatedStatus: ISmsOnboardingStatus | "NEEDS_ATTENTION" | "NOT_STARTED";
}

interface ICheckStepStatusProps {
  data: IGetSmsOnboardingProgressRS;
  step: IOnboardingStep;
  isRefetching: boolean;
  activePath: string;
}

const getReturnBlock = ({
  icon,
  isStarted,
  isError = false,
  isComplete = false,
}: {
  icon: ISmsOnboardingStepStatus["icon"];
  isStarted: boolean;
  isError?: boolean;
  isComplete?: boolean;
}): ISmsOnboardingStepStatus => ({
  icon,
  color: isComplete
    ? "success"
    : isError
    ? "error"
    : isStarted
    ? "warning"
    : "primary",
  calculatedStatus: isComplete
    ? "PASS"
    : isError
    ? "FAIL"
    : isStarted
    ? "NEEDS_ATTENTION"
    : "NOT_STARTED",
});

export const questionnaireStepValidation = ({
  step,
  data,
}: {
  step: IOnboardingStep;
  data: Omit<IGetSmsOnboardingProgressRS["questionnaire"], "status" | "index">;
}): ISmsOnboardingStepStatus => {
  const { storeLocation, marketsToUsContacts, ...rest } = data;
  const { icon } = step;

  const isStarted = Object.values(rest).some(Boolean);
  const isComplete = Object.values(rest).every(Boolean);

  return getReturnBlock({ icon, isStarted, isComplete });
};

export const companyDetailsStepValidation = ({
  step,
  data,
}: {
  step: IOnboardingStep;
  data: IGetSmsOnboardingProgressRS["companyDetails"];
}): ISmsOnboardingStepStatus => {
  const { smsCountrySettings, companyPrefix } = data;
  const { icon } = step;

  const isStarted = !!smsCountrySettings?.length || !!companyPrefix;

  return getReturnBlock({ icon, isStarted });
};

export const complianceChecksStepValidation = ({
  step,
  data,
}: {
  step: IOnboardingStep;
  data: IGetSmsOnboardingProgressRS["complianceChecks"];
}): ISmsOnboardingStepStatus => {
  const { items, klaviyoApiKey } = data;
  const { icon } = step;

  const isStarted = items?.some(item => !!item?.checked) || !!klaviyoApiKey;

  return getReturnBlock({ icon, isStarted });
};

export const klaviyoListsStepValidation = ({
  step,
  data,
}: {
  step: IOnboardingStep;
  data: IGetSmsOnboardingProgressRS["klaviyoLists"];
}): ISmsOnboardingStepStatus => {
  const { lists, listTypeCheck, secondOptInCheck } = data;
  const { icon } = step;

  const isError = lists && (!listTypeCheck || secondOptInCheck === "FAIL");
  const isStarted = lists && (!listTypeCheck || !secondOptInCheck);

  return getReturnBlock({ icon, isStarted, isError });
};

/**
 * These steps are solely PASS/FAIL and FAIL is just because
 * the step hasn't been marked as completed, so this is
 * really a case of FAIL === NOT_STARTED
 */
export const genericStepValidation = ({
  step: { icon },
}: {
  step: IOnboardingStep;
}): ISmsOnboardingStepStatus => getReturnBlock({ icon, isStarted: false });

export const checkStepStatus = ({
  data,
  step,
  isRefetching,
  activePath,
}: ICheckStepStatusProps): ISmsOnboardingStepStatus => {
  const passResponse: ISmsOnboardingStepStatus = {
    icon: CheckCircleOutlineTwoTone,
    color: "success",
    calculatedStatus: "PASS",
  };

  const pendingResponse: ISmsOnboardingStepStatus = {
    icon: SyncTwoTone,
    color: "primary",
    calculatedStatus: "PENDING",
    sx: muiExtraStylesSpin,
  };

  const { identifier, icon, url } = step;
  const { status } = data?.[identifier];

  if (status === "PASS") return passResponse;
  if (status === "PENDING") return pendingResponse;
  if (isRefetching && activePath.endsWith(url)) return pendingResponse;

  switch (identifier) {
    case "questionnaire":
      return questionnaireStepValidation({ step, data: data?.questionnaire });

    case "companyDetails":
      return companyDetailsStepValidation({ step, data: data?.companyDetails });

    case "complianceChecks":
      return complianceChecksStepValidation({
        step,
        data: data?.complianceChecks,
      });

    case "klaviyoLists":
      return klaviyoListsStepValidation({
        step,
        data: data?.klaviyoLists,
      });

    case "shopifyPolicies":
      return genericStepValidation({ step });

    case "shopifyExtension":
      return genericStepValidation({ step });

    case "shopifySms":
      return genericStepValidation({ step });

    case "complete":
      return genericStepValidation({ step });

    default:
      return getReturnBlock({ icon, isStarted: false });
  }
};
