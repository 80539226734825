import { GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Tooltip } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";
import LockIcon from "@mui/icons-material/Lock";

const RenderProducts = (params: GridRenderCellParams) => {
  const { value } = params;
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="row"
      gap={1}
      justifyContent="start"
      alignItems="center">
      <Tooltip title="Email">
        <AlternateEmailIcon
          color={value.includes("COLLECT_EMAIL") ? "primary" : "secondary"}
          sx={{ opacity: value.includes("COLLECT_EMAIL") ? 1 : 0.35 }}
        />
      </Tooltip>
      <Tooltip title="Collect SMS">
        <SmartphoneIcon
          color={
            value.includes("COLLECT_SMS_DATASHIPS") ? "primary" : "secondary"
          }
          sx={{ opacity: value.includes("COLLECT_SMS_DATASHIPS") ? 1 : 0.35 }}
        />
      </Tooltip>
      <Tooltip title="SMS Easy Opt-in">
        <MobileFriendlyIcon
          color={
            value.includes("COLLECT_SMS_EASY_OPT_IN") ? "primary" : "secondary"
          }
          sx={{ opacity: value.includes("COLLECT_SMS_EASY_OPT_IN") ? 1 : 0.35 }}
        />
      </Tooltip>
      <Tooltip title="SMS Comply">
        <PhonelinkLockIcon
          color={
            value.includes("COLLECT_SMS_SHOPIFY") ? "primary" : "secondary"
          }
          sx={{ opacity: value.includes("COLLECT_SMS_SHOPIFY") ? 1 : 0.35 }}
        />
      </Tooltip>
      <Tooltip title="Comply">
        <LockIcon
          color={value.includes("COMPLY") ? "primary" : "secondary"}
          sx={{ opacity: value.includes("COMPLY") ? 1 : 0.35 }}
        />
      </Tooltip>
    </Box>
  );
};

export default RenderProducts;
