import {
  Timeline as MuiTimeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import { Box, Icon, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

interface IProps {
  global?: boolean;
  events: IEvent[];
}

const Timeline = ({ global = false, events }: IProps) => {
  const renderTimelineItem = (event: IEvent, index: number): JSX.Element => {
    const { companyId, companyName, name, description, datetime, icon, color } =
      event;

    const renderTime = () => (
      <TimelineOppositeContent
        sx={{ m: "auto 0", whiteSpace: "pre" }}
        variant="body2"
        color="text.secondary">
        {dayjs(datetime).format("MMMM D[\r\n]HH:mm")}
      </TimelineOppositeContent>
    );

    const renderIcon = () => (
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color={color}>
          <Icon className="material-icons-two-tone">{icon}</Icon>
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
    );

    const renderContent = () => (
      <TimelineContent sx={{ py: "12px", px: 2 }}>
        {global && (
          <Link
            variant="body2"
            color="primary"
            sx={{ display: "block" }}
            underline="none"
            component={RouterLink}
            to={`/customer/${companyId}/timeline`}>
            {companyName}
          </Link>
        )}
        <Typography variant="h6" component="span">
          {name}
        </Typography>
        {!!description && <Typography>{description}</Typography>}
      </TimelineContent>
    );

    return (
      <TimelineItem
        key={index}
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.25,
          },
        }}>
        {renderTime()}
        {renderIcon()}
        {renderContent()}
      </TimelineItem>
    );
  };

  const renderTimeline = (): JSX.Element[] | JSX.Element => {
    if (!!events) {
      return events.map((event, i) => renderTimelineItem(event, i));
    }

    return (
      <Box sx={{ p: 3, display: "flex", justifyContent: "center" }}>
        No events returned from API.
      </Box>
    );
  };

  return <MuiTimeline>{renderTimeline()}</MuiTimeline>;
};

export default Timeline;
