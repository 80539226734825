import { Box, Chip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { GridRenderCellParams } from "@mui/x-data-grid";

const RenderPrice = (params: GridRenderCellParams) => {
  const {
    row: { discountPercentage, listPrice, price },
  } = params;

  const renderListPrice = () => (
    <Typography variant="body2" sx={{ textDecoration: "line-through" }}>
      ${listPrice}
    </Typography>
  );

  const renderDiscountChip = () => (
    <Chip label={`-${discountPercentage}%`} color="success" />
  );

  const renderPrice = () => (
    <Typography variant="body2" fontWeight="bold">
      ${price}
    </Typography>
  );

  if (discountPercentage > 0) {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        {renderListPrice()}
        {renderDiscountChip()}
        {renderPrice()}
      </Stack>
    );
  }

  return (
    <Box height="100%" display="flex" alignItems="center" justifyContent="end">
      {renderPrice()}
    </Box>
  );
};

export default RenderPrice;
