import { useCustomerPersonas } from "@/requests/hooks/customer";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from "react-hook-form";
import {
  replaceUnderScoresWithSpaces,
  stringToTitleCase,
} from "@/utils/helpers";
import { object, string, array, InferType } from "yup";

export const createUserFormSchema = object({
  email: string().email().required().label("Email Address"),
  firstName: string().required().label("First Name"),
  lastName: string().required().label("Last Name"),
  group: string().optional(),
  personas: array().of(string().required()),
});

export type ICreateUserFormData = InferType<typeof createUserFormSchema>;

interface IProps {
  showGroupAndPersonas?: boolean;
  errorCodes?: ErrorCode[];
}

const CreateUserForm = ({
  showGroupAndPersonas = true,
  errorCodes,
}: IProps) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ICreateUserFormData>();

  const { data: personasData, isLoading: isPersonasLoading } =
    useCustomerPersonas();

  const prettifyPersonaName = (persona: string) =>
    stringToTitleCase(replaceUnderScoresWithSpaces(persona));

  const getEmailInputHelperText = () => {
    if (errors.email) return errors?.email?.message;

    return null;
  };

  const renderPersonaNames = (selected: string[]) =>
    selected.map(item => prettifyPersonaName(item)).join(", ");

  const renderRadioGroup = ({
    field,
  }: {
    field: ControllerRenderProps<ICreateUserFormData, "group">;
  }) => (
    <RadioGroup row {...field}>
      <FormControlLabel value="ADMIN" control={<Radio />} label="Admin" />
      <FormControlLabel value="USER" control={<Radio />} label="User" />
    </RadioGroup>
  );

  const renderSelectField = ({
    field,
  }: {
    field: ControllerRenderProps<ICreateUserFormData, "personas">;
  }) => (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="personas-label">Roles</InputLabel>
      <Select
        {...field}
        labelId="personas-label"
        id="personas-select"
        multiple
        defaultValue={[]}
        input={<OutlinedInput label="Roles" />}
        renderValue={renderPersonaNames}>
        {personasData.map((persona: string) => (
          <MenuItem key={persona} value={persona}>
            {prettifyPersonaName(persona)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  if (isPersonasLoading) return <LinearProgress />;

  return (
    <Stack spacing={2} direction="column">
      <TextField
        autoFocus={showGroupAndPersonas}
        id="email"
        label="Email Address"
        type="text"
        variant="outlined"
        fullWidth
        {...register("email")}
        error={!!errors.email || !!errorCodes}
        helperText={getEmailInputHelperText()}
      />

      <TextField
        id="firstName"
        label="First Name"
        type="text"
        variant="outlined"
        fullWidth
        {...register("firstName")}
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
      />

      <TextField
        id="lastName"
        label="Last Name"
        type="text"
        variant="outlined"
        fullWidth
        {...register("lastName")}
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
      />

      {showGroupAndPersonas && (
        <>
          <FormControl>
            <FormLabel>Dataships Role</FormLabel>
            <Controller
              rules={{ required: true }}
              control={control}
              name="group"
              defaultValue="USER"
              render={renderRadioGroup}
            />
          </FormControl>

          <Controller
            name="personas"
            control={control}
            defaultValue={[]}
            render={renderSelectField}
          />
        </>
      )}
    </Stack>
  );
};

export default CreateUserForm;
