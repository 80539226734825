import React from "react";
import ReactDOM from "react-dom/client";
import { getAppConfig } from "@/utils/appManager.ts";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider } from "react-router-dom";
import { router } from "@/navigation";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import "@mui/x-data-grid/themeAugmentation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import handleUnauthorized from "@/utils/handleUnauthorized";

const { flagsmithId, flagsmithHost } = getAppConfig();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 3,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      handleUnauthorized(error, query);
    },
  }),
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#10A4FF",
    },
    secondary: {
      main: "#00243A",
    },
    error: {
      main: "#FF3A34",
    },
    warning: {
      main: "#ff5c01",
    },
    info: {
      main: "#1991EB",
    },
    success: {
      main: "#4ACE96",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "Inter",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
        },
      },
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={{
        environmentID: flagsmithId,
        api: flagsmithHost,
        cacheFlags: true,
      }}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterProvider router={router} />
          <ToastContainer
            hideProgressBar
            closeOnClick
            closeButton={false}
            pauseOnFocusLoss={false}
            autoClose={3000}
          />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </FlagsmithProvider>
  </React.StrictMode>,
);
