import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

interface IProps {
  date: string | null;
  user: string | null;
}

const RenderAuditableDate = ({ date, user }: IProps) => {
  if (!date && !user) return "";

  return (
    <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
      <Typography>{dayjs(date).format("YYYY-MM-DD HH:mm")}</Typography>
      <Typography color="textSecondary" sx={{ fontSize: 12 }}>
        {user || "-"}
      </Typography>
    </Box>
  );
};

export default RenderAuditableDate;
