import { ArrowLeftTwoTone, ArrowRightTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Paper, Stack } from "@mui/material";
import { useState } from "react";
import { Timeline as TimelineComponent } from "@/components/Timeline";
import { Error } from "@/components/Error";
import { PageLoader } from "@/components/Loaders";
import { useCustomerTimeline } from "@/requests/hooks/customer";
import { useParams } from "react-router-dom";

const Timeline = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const [page, setPage] = useState<number>(1);

  const {
    data: timelineData,
    isLoading: isTimelineLoading,
    error: timelineError,
  } = useCustomerTimeline({
    customerId: id,
    params: {
      page,
      pageSize: 50,
    },
  });

  const handlePrevious = () => {
    setPage(page => page - 1);
  };

  const handleNext = () => {
    setPage(page => page + 1);
  };

  const renderTimeline = () => (
    <TimelineComponent events={timelineData?.results} />
  );

  const renderPagination = () => (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <LoadingButton
        disabled={page === 1}
        loading={isTimelineLoading}
        loadingPosition="start"
        variant="contained"
        color="secondary"
        startIcon={<ArrowLeftTwoTone />}
        onClick={handlePrevious}>
        Previous
      </LoadingButton>
      <LoadingButton
        disabled={!timelineData?.results?.length || timelineData?.results?.length <= 50}
        loading={isTimelineLoading}
        loadingPosition="end"
        variant="contained"
        color="secondary"
        endIcon={<ArrowRightTwoTone />}
        onClick={handleNext}>
        Next
      </LoadingButton>
    </Box>
  );

  if (isTimelineLoading) return <PageLoader title="timeline" />;
  if (!!timelineError) return <Error error={timelineError} />;

  return (
    <Paper elevation={1} sx={{ p: 3 }}>
      <Stack spacing={3}>
        {renderPagination()}
        {renderTimeline()}
        {renderPagination()}
      </Stack>
    </Paper>
  );
};

export default Timeline;
