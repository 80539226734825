import { getAppConfig } from "@/utils/appManager";
import getCookie from "@/utils/getCookie";
import axios, { GenericAbortSignal } from "axios";

interface IRequest {
  url: string;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  body?: object;
  params?: object;
  signal?: GenericAbortSignal;
  timeout?: number;
}

const request = ({
  url,
  method = "GET",
  body,
  params,
  signal,
  timeout,
}: IRequest) => {
  const { coreApiHost } = getAppConfig();

  const apiInstance = axios.create({
    baseURL: coreApiHost,
    withCredentials: true,
  });

  const methodsToSendCsrfCookie = ["POST", "PUT"];

  const headers = methodsToSendCsrfCookie.includes(method)
    ? {
        headers: {
          "x-dataships-csrf-token": getCookie("ds-csrf"),
        },
      }
    : {};

  return apiInstance({
    url,
    method,
    data: body,
    params,
    signal,
    timeout,
    ...headers,
  });
};

export default request;
