import { formatValue } from "@/utils/helpers";
import { Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link as RouterLink } from "react-router-dom";

interface IProps {
  params: GridRenderCellParams;
  format: IMetricFormat;
}

const RenderFormattedValue = ({ params, format }: IProps) => {
  const value = params?.formattedValue;
  const customerId = params?.row?.customer_id;

  /**
   * We handle the CUSTOMER_LINK format differently to the rest,
   * because it relies on other properties in the row other than
   * the value being rendered.
   */
  if (format === "CUSTOMER_LINK" && !!customerId) {
    return (
      <Link component={RouterLink} to={`/customer/${customerId}`}>
        {value}
      </Link>
    );
  }

  /**
   * Otherwise, we can use the top level formatter to handle the
   * rest of the formatting for us.
   */
  return formatValue({ value, format });
};

export default RenderFormattedValue;
