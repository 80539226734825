import { GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";

const RenderDate = (params: GridRenderCellParams) => {
  const { value } = params;

  if (!value) {
    return "";
  }

  return dayjs(value).format("YYYY-MM-DD HH:mm");
};

export default RenderDate;
