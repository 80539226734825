import SmsOnboardingProgressButtons from "@/components/Buttons/SmsOnboardingProgressButtons";
import { Error } from "@/components/Error";
import CompanyPrefixForm from "@/components/Forms/CompanyPrefixForm";
import { PageLoader } from "@/components/Loaders";
import {
  SmsDeletePhoneNumberModal,
  SmsPhoneNumberModal,
} from "@/components/Modals";
import {
  RenderSmsPhoneNumberActions,
  RenderSmsPhoneNumberCountry,
} from "@/components/Table";
import { Instructions } from "@/components/Typography";
import { useSmsOnboardingProgress } from "@/requests/hooks/customer";
import { translateEnumToPrettyPrint } from "@/utils/translationMapping";
import { AddCircleTwoTone, PhoneInTalkTwoTone } from "@mui/icons-material";
import { Alert, Box, Button, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";

const CompanyDetails = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const [isCreateOrUpdateModalOpen, setIsCreateOrUpdateModalOpen] =
    useState<boolean>(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [activeNumber, setActiveNumber] = useState<
    ISmsCountrySetting | undefined
  >(undefined);

  const {
    data: smsOnboardingData,
    error: smsOnboardingError,
    isError: isSmsOnboardingError,
    isLoading: isSmsOnboardingLoading,
    refetch: refetchSmsOnboardingData,
    isRefetching: isSmsOnboardingRefetching,
  } = useSmsOnboardingProgress(id);

  if (isSmsOnboardingLoading) return <PageLoader title="company details" />;
  if (isSmsOnboardingError || !smsOnboardingData)
    return <Error error={smsOnboardingError} />;

  const setActiveNumberAndToggleModal = (number?: ISmsCountrySetting) => {
    setActiveNumber(number);
    setIsCreateOrUpdateModalOpen(isModalOpen => !isModalOpen);
  };

  const setActiveNumberAndToggleDeleteModal = (number: ISmsCountrySetting) => {
    setActiveNumber(number);
    setIsDeleteModalOpen(isModalOpen => !isModalOpen);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(isModalOpen => !isModalOpen);
  };

  const phoneNumberColumns: GridColDef[] = [
    {
      field: "countryName",
      headerName: "Country",
      flex: 1,
      renderCell: RenderSmsPhoneNumberCountry,
    },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
    {
      field: "phoneNumberType",
      headerName: "Type",
      flex: 1,
      renderCell: ({ value }) => translateEnumToPrettyPrint(value),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: params => (
        <RenderSmsPhoneNumberActions
          params={params}
          toggleEditModal={setActiveNumberAndToggleModal}
          toggleDeleteModal={setActiveNumberAndToggleDeleteModal}
        />
      ),
    },
  ];

  const instructions: ReactNode[] = [
    "Please go to your Dataships Support page ('Klaviyo Setup Instructions' step 1)",
    "Log in to your Klaviyo account",
    "Is SMS already enabled in your Klaviyo account? If not, go to Settings > SMS, and follow the on screen instructions",
    <Stack direction="column" gap={1}>
      For each active sender phone number, please provide the phone number,
      country, and number type
      <ul>
        <li>
          To view phone number type ask the customer to click the small drop
          down arrow beside the Country flag
        </li>
      </ul>
    </Stack>,
  ];

  const renderHeading = () => (
    <Box>
      <Typography variant="h6" color="secondary">
        Sender Information
      </Typography>
      <Typography variant="body2" mb={2}>
        We're going to save the phone numbers that your store will be using to
        send SMS marketing in Klaviyo.
      </Typography>
      <Instructions instructions={instructions} ordered />
    </Box>
  );

  const renderPhoneNumbers = () => {
    const { smsCountrySettings } = smsOnboardingData.companyDetails;

    if (!smsCountrySettings?.length)
      return (
        <Alert icon={<PhoneInTalkTwoTone fontSize="inherit" />} severity="info">
          No phone numbers saved yet. Click the button below to get started.
        </Alert>
      );

    return (
      <DataGrid
        loading={isSmsOnboardingLoading || isSmsOnboardingRefetching}
        rows={smsCountrySettings || []}
        columns={phoneNumberColumns}
        autoHeight
      />
    );
  };

  const renderPhoneNumberButton = () => (
    <Box display="flex" justifyContent="end">
      <Button
        variant="contained"
        color="success"
        startIcon={<AddCircleTwoTone />}
        onClick={() => setActiveNumberAndToggleModal()}>
        Create Number
      </Button>
    </Box>
  );

  const renderCompanyPrefixHeading = () => (
    <Box>
      <Typography variant="h6" color="secondary">
        Company Prefix
      </Typography>
      <Typography variant="body2">
        What is your Organizational prefix? You can find it under 'SMS message
        add-ons' at the bottom of the page.
      </Typography>
    </Box>
  );

  const renderCompanyPrefixForm = () => (
    <CompanyPrefixForm
      companyPrefix={smsOnboardingData?.companyDetails?.companyPrefix}
      customerVersion={smsOnboardingData?.customerVersion}
      refetch={refetchSmsOnboardingData}
    />
  );

  const renderCreateOrUpdateModal = () =>
    isCreateOrUpdateModalOpen && (
      <SmsPhoneNumberModal
        activeNumber={activeNumber}
        open={isCreateOrUpdateModalOpen}
        onClose={setActiveNumberAndToggleModal}
        refetch={refetchSmsOnboardingData}
      />
    );

  const renderDeleteModal = () =>
    isDeleteModalOpen && (
      <SmsDeletePhoneNumberModal
        activeNumber={activeNumber!}
        open={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        refetch={refetchSmsOnboardingData}
      />
    );

  const renderStepButtons = () => (
    <SmsOnboardingProgressButtons
      currentUrl="company-details"
      previousStepUrl="questionnaire"
      nextStepUrl="compliance-checks"
    />
  );

  return (
    <Paper elevation={1} sx={{ p: 3, minHeight: "100%"}}>
        <Stack direction="column" gap={3}>
          {renderHeading()}
          {renderPhoneNumbers()}
          {renderPhoneNumberButton()}
          {renderCompanyPrefixHeading()}
          {renderCompanyPrefixForm()}
          {renderCreateOrUpdateModal()}
          {renderDeleteModal()}
          {renderStepButtons()}
        </Stack>
    </Paper>
  );
};

export default CompanyDetails;
