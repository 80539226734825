import {
  CheckCircleTwoTone,
  HourglassFullTwoTone,
  SyncTwoTone,
} from "@mui/icons-material";
import { Chip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";

const RenderAbTestStatus = (params: GridRenderCellParams) => {
  const {
    row: { startDate, endDate },
  } = params;

  if (!!startDate && !!endDate) {
    return (
      <Chip icon={<CheckCircleTwoTone />} color="success" label="Complete" />
    );
  }

  if (!!startDate && !endDate) {
    return <Chip icon={<SyncTwoTone />} color="warning" label="In-progress" />;
  }

  return (
    <Chip icon={<HourglassFullTwoTone />} color="primary" label="Not started" />
  );
};

export default RenderAbTestStatus;
