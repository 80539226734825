import {
  CheckCircleTwoTone,
  HourglassFullTwoTone,
  CancelTwoTone,
  SyncTwoTone,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  ListItem,
  ListItemAvatar,
  CircularProgress,
  Avatar,
  ListItemText,
  useTheme,
} from "@mui/material";

interface IProps {
  title: string;
  status?: ISmsOnboardingStatus | null;
  isLoading: boolean;
  buttonAction: () => void;
  buttonText: string;
}

const OptInCheckProgress = ({
  title,
  status,
  isLoading,
  buttonAction,
  buttonText,
}: IProps) => {
  const {
    palette: { success, primary, error },
  } = useTheme();

  const getOptInCheckProgress = () => {
    const mapping = {
      PASS: {
        subtitle: "Check completely successfully",
        icon: <CheckCircleTwoTone />,
        color: success.main,
        showLoading: false,
        disabled: true,
      },
      PENDING: {
        subtitle: "Check is currently running, please wait...",
        icon: <HourglassFullTwoTone />,
        color: primary.light,
        showLoading: true,
        disabled: false,
      },
      FAIL: {
        subtitle: "Check failed",
        icon: <CancelTwoTone />,
        color: error.main,
        showLoading: false,
        disabled: false,
      },
      NOT_STARTED: {
        subtitle: "Check not yet started",
        icon: <CancelTwoTone />,
        color: error.main,
        showLoading: false,
        disabled: false,
      },
    };

    if (isLoading) return mapping.PENDING;

    if (!status) return mapping.NOT_STARTED;

    return mapping[status] || mapping.PENDING;
  };

  const { subtitle, icon, color, showLoading, disabled } =
    getOptInCheckProgress();

  return (
    <ListItem
      secondaryAction={
        <LoadingButton
          aria-label="refresh"
          variant="contained"
          color="secondary"
          onClick={buttonAction}
          disabled={showLoading || disabled}
          loading={showLoading}
          loadingPosition="end"
          endIcon={<SyncTwoTone />}>
          {buttonText}
        </LoadingButton>
      }>
      <ListItemAvatar>
        {showLoading && (
          <CircularProgress
            color="secondary"
            sx={{
              position: "absolute",
              top: 16,
              left: 16,
              zIndex: 1,
            }}
          />
        )}
        <Avatar
          sx={{
            backgroundColor: color,
          }}>
          {icon}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={title} secondary={subtitle} />
    </ListItem>
  );
};

export default OptInCheckProgress;
