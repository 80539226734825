import request from "@/requests/request";
import axios from "axios";

export const getCountries = ({
  signal,
}: {
  signal: IGenericHookOptions["signal"];
}): Promise<ICountriesRS> =>
  request({
    url: `/v1/admin/country`,
    method: "GET",
    signal,
  }).then(res => res?.data?.results);

export const getRandomDadJoke = ({
  signal,
}: {
  signal: IGenericHookOptions["signal"];
}): Promise<IDadJokeRS> =>
  axios
    .get("https://icanhazdadjoke.com/", {
      signal,
      headers: {
        Accept: "application/json",
      },
    })
    .then(res => res.data);
