import { GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Tooltip } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
const RenderExtensibilityStatus = (params: GridRenderCellParams) => {
  const { value } = params;
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="row"
      gap={1}
      justifyContent="start"
      alignItems="center">
      <Tooltip title="Checkout">
        <ShoppingCartIcon
          color={value.checkoutEnabled ? "primary" : "secondary"}
          sx={{ opacity: value.checkoutEnabled ? 1 : 0.35 }}
        />
      </Tooltip>
      <Tooltip title="Thank You Page">
        <ShoppingBagIcon
          color={value.thankYouPageEnabled ? "primary" : "secondary"}
          sx={{ opacity: value.thankYouPageEnabled ? 1 : 0.35 }}
        />
      </Tooltip>
    </Box>
  );
};

export default RenderExtensibilityStatus;
