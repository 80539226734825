import { StatisticsPanel } from "@/components/Customer";
import { Error } from "@/components/Error";
import { PageLoader } from "@/components/Loaders";
import { CustomerStatsGrid } from "@/components/StatsGrid";
import { useCustomer } from "@/requests/hooks/customer";
import { useFlags } from "flagsmith/react";
import { useParams } from "react-router-dom";

const CustomerStats = () => {
  const flags = useFlags(["admin-panel-customer-stats-enabled"]);
  const isCustomerStatsEnabled =
    flags?.["admin-panel-customer-stats-enabled"]?.enabled;

  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const {
    data: customerData,
    isLoading: isCustomerLoading,
    error: customerError,
    refetch: refetchCustomer,
  } = useCustomer(id);

  if (isCustomerLoading) return <PageLoader title="customer" />;
  if (customerError || !customerData)
    return <Error error={customerError || "Customer data unavailable."} />;

  const {
    users,
    numContacts,
    numContactsLast30Days,
    acceptanceRate,
    acceptanceRateLast30Days,
    preDatashipsMarketableRate,
  } = customerData;

  const renderBaseStatistics = () => (
    <StatisticsPanel
      numUsers={users?.length ?? 0}
      numContacts={numContacts}
      numContactsLast30Days={numContactsLast30Days}
      acceptanceRate={acceptanceRate * 100}
      acceptanceRateLast30Days={acceptanceRateLast30Days * 100}
      marketableRateInfo={preDatashipsMarketableRate}
      refetchCustomer={refetchCustomer}
    />
  );

  const renderDailyStatistics = () => true && <CustomerStatsGrid />;

  return (
    <>
      {renderBaseStatistics()}
      {isCustomerStatsEnabled && renderDailyStatistics()}
    </>
  );
};

export default CustomerStats;
