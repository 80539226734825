/**
 *
 * @param error This can be a core-api or an axios error
 */
const formatError = (error: Error | IApiError | null) => {
  error = error as IApiError;
  const errorData = error?.response?.data;

  if (errorData) {
    const { message, errors } = errorData;
    const codes = errorData?.errors?.map(error => error?.code);
    const errorMessage = message || "Oops, something went wrong.";

    return {
      message: errorMessage,
      errors,
      status: error?.response?.status,
      codes,
    };
  } else {
    return { error, status: error?.code };
  }
};

export default formatError;
