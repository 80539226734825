import request from "@/requests/request";

export const getTimeline = ({
  params,
  signal,
}: {
  params: ITimelineRQ;
  signal: IGenericHookOptions["signal"];
}) =>
  request({
    url: "/v1/admin/timeline",
    params,
    signal,
  }).then(res => res.data);
