import { ErrorJoke } from "@/components/Error";
import { PageLoader } from "@/components/Loaders";
import { RenderFormattedValue } from "@/components/Table";
import { useMetric } from "@/requests/hooks/metrics";
import { generateUuid, getContentAlignment } from "@/utils/helpers";
import { updateSearchParams } from "@/utils/updateSearchParams";
import {
  AccessTimeTwoTone,
  ArrowBackIos,
  FileDownload,
} from "@mui/icons-material";
import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortDirection,
  GridSortModel,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { getAppConfig } from "@/utils/appManager.ts";

const Drilldown = () => {
  const { metric, id } = useParams<
    keyof IDrilldownPageParams
  >() as IDrilldownPageParams;

  const { coreApiHost } = getAppConfig();

  const [searchParams, setSearchParams] = useSearchParams({});

  const [paginationModel, setPaginationModel] = useState({
    page: searchParams?.get("page") || "1",
    pageSize: searchParams?.get("pageSize") || "100",
  });

  const [sortModel, setSortModel] = useState({
    sortBy: searchParams.get("sortBy") || "",
    sortOrder: searchParams.get("sortOrder") || "",
  });

  const [rowCount, setRowCount] = useState<number>(0);

  const {
    data: metricData,
    isLoading: isMetricLoading,
    isError: isMetricError,
    error: metricError,
  } = useMetric({
    metric,
    page: parseInt(paginationModel.page),
    pageSize: parseInt(paginationModel.pageSize),
    customerId: id,
    ...(!!sortModel?.sortBy ? sortModel : {}),
  });

  const handlePagination = (model: { page: number; pageSize: number }) => {
    const page = (model.page + 1).toString();
    const pageSize = model.pageSize.toString();

    setPaginationModel({
      page,
      pageSize,
    });

    updateSearchParams(
      {
        page,
        pageSize,
      },
      setSearchParams,
    );
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (!sortModel.length) {
      setSortModel({
        sortBy: "",
        sortOrder: "",
      });
      return;
    }

    const sortFields = sortModel[0];
    if (sortFields) {
      setSortModel({
        sortBy: sortFields.field,
        sortOrder: sortFields.sort || "",
      });
    }
  };

  useEffect(() => {
    setRowCount(prevRowCountState =>
      !!metricData?.totalResults ? metricData?.totalResults : prevRowCountState,
    );
  }, [metricData]);

  useEffect(() => {
    updateSearchParams(
      {
        sortBy: sortModel.sortBy,
        sortOrder: sortModel.sortOrder,
      },
      setSearchParams,
    );
  }, [sortModel]);

  const getSortModel = () => {
    if (!sortModel?.sortBy || !sortModel?.sortOrder) return [];

    return [
      {
        field: sortModel?.sortBy,
        sort: sortModel?.sortOrder as GridSortDirection,
      },
    ];
  };

  const getColumns = (): readonly GridColDef<any>[] =>
    metricData?.columns
      ?.sort((a, b) => a.displayOrder - b.displayOrder)
      ?.map(({ property, title, format }) => ({
        field: property,
        headerName: title,

        renderCell: (params: GridRenderCellParams) =>
          RenderFormattedValue({ params, format }),

        // Default some column properties
        flex: 1,
        headerAlign: getContentAlignment(format),
        align: getContentAlignment(format),
      })) || [];

  const getRows = () =>
    metricData?.results?.map(row => ({
      id: generateUuid(),
      ...row,
    }));

  const navigateBackUrl = id ? `/customer/${id}` : `/overview`;
  const exportUrl = `${coreApiHost}/v1/admin/${
    id ? `${id}/` : ""
  }metrics/export/${metric}`;

  const renderHeading = () => (
    <Box
      display="flex"
      alignItems="start"
      justifyContent="space-between"
      gap={3}
      mb={1}>
      <Box>
        <Typography variant="h5" mb={1} display="flex" alignItems="center">
          <Box mr={1}>{metricData?.title}</Box>
          <Box component="span" display="flex" alignItems="center">
            (<AccessTimeTwoTone sx={{ mr: 1 }} />
            {metricData?.timeFrame})
          </Box>
        </Typography>
        <Typography variant="subtitle1">{metricData?.description}</Typography>
      </Box>
      <Stack direction="row" spacing={1}>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          startIcon={<ArrowBackIos />}
          to={navigateBackUrl}
          component={Link}>
          Back
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          startIcon={<FileDownload />}
          disabled={0 === rowCount}
          href={exportUrl}>
          Export CSV
        </Button>
      </Stack>
    </Box>
  );

  const renderTable = () => (
    <Paper elevation={1}>
      <DataGrid
        loading={isMetricLoading}
        columns={getColumns()}
        rows={getRows()}
        rowCount={rowCount}
        pagination
        paginationMode="server"
        paginationModel={{
          page: parseInt(paginationModel?.page) - 1,
          pageSize: parseInt(paginationModel?.pageSize),
        }}
        onPaginationModelChange={handlePagination}
        sortingMode="server"
        sortModel={getSortModel()}
        onSortModelChange={handleSortModelChange}
        pageSizeOptions={[10, 25, 50, 100]}
        autoHeight
      />
    </Paper>
  );

  if (isMetricLoading) return <PageLoader title="drilldown" />;
  if (isMetricError) return <ErrorJoke error={metricError} />;

  return (
    <>
      {renderHeading()}
      <Divider sx={{ my: 3 }} />
      {renderTable()}
    </>
  );
};

export default Drilldown;
