import { Query, QueryKey } from "@tanstack/react-query";
import { AxiosError } from "axios";

const handleUnauthorized = (
  error: Error,
  query: Query<unknown, unknown, unknown, QueryKey>,
) => {
  if (
    !query.queryKey.includes("user") &&
    (error as AxiosError)?.response?.status === 401
  ) {
    window.location.replace("/login");
  }
};
export default handleUnauthorized;
