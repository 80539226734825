import { getUser, postLoginAsCustomer, postLogout } from "@/requests/api/user";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useUser = () =>
  useQuery({
    queryKey: ["user"],
    queryFn: getUser,
  });

export const useLogout = () =>
  useMutation({
    mutationFn: postLogout,
    onSuccess: () => window.location.replace("/login"),
  });

export const useLoginAsCustomer = () =>
  useMutation({
    mutationFn: postLoginAsCustomer,
  });
