import { ExpandMoreTwoTone, Home, RefreshTwoTone } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";

const Error = ({ error }: { error: Error | string | null }) => {
  const navigate = useNavigate();

  const handleReload = () => navigate(0);

  const handleGoToHome = () => navigate("/");

  return (
    <Paper elevation={1} sx={{ p: 3 }}>
      <Stack direction="column" spacing={3}>
        <Alert
          severity="error"
          variant="filled"
          action={
            <>
              <Button
                color="inherit"
                size="small"
                startIcon={<Home />}
                onClick={handleGoToHome}>
                Go Home
              </Button>
              <Button
                color="inherit"
                size="small"
                startIcon={<RefreshTwoTone />}
                onClick={handleReload}>
                Refresh
              </Button>
            </>
          }>
          <AlertTitle>Error</AlertTitle>
          Whoops, something went wrong! Please try again.
        </Alert>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreTwoTone />}
            aria-controls="dev-error-content"
            id="dev-error-header">
            <Typography>View Error</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                p: 3,
                bgcolor: "secondary.main",
                color: "white",
                borderRadius: "6px",
              }}>
              <pre style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(error, null, 2)}
              </pre>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Paper>
  );
};

export default Error;
