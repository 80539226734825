import { AbTestForm } from "@/components/Forms";
import { PageLoader } from "@/components/Loaders";
import { useLatestAbTest } from "@/requests/hooks/abTest";
import { useCountries } from "@/requests/hooks/app";
import { Box, Link, Stack, Typography } from "@mui/material";
import { useParams, Link as RouterLink } from "react-router-dom";

const AbTest = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;

  const {
    data: abTestData,
    isLoading: isAbTestLoading,
    refetch: refetchLatestAbTest,
    isRefetching: isAbTestRefetching,
  } = useLatestAbTest(id);

  const { data: countriesData, isLoading: isCountriesDataLoading } =
    useCountries();

  const renderPageHeader = () => (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h6">A/B Testing</Typography>
      <Typography
        variant="h6"
        color="primary"
        display="flex"
        alignItems="center">
        <Link component={RouterLink} to="all">
          All A/B Tests
        </Link>
      </Typography>
    </Box>
  );

  const renderAbTestForm = () => (
    <AbTestForm
      data={abTestData!}
      refetchLatest={refetchLatestAbTest}
      isRefetching={isAbTestRefetching}
      countries={countriesData!}
    />
  );

  if (isAbTestLoading || isCountriesDataLoading)
    return <PageLoader title="A/B Test" />;

  return (
    <Stack direction="column" gap={3}>
      {renderPageHeader()}
      {renderAbTestForm()}
    </Stack>
  );
};

export default AbTest;
