import { PageLoader } from "@/components/Loaders";
import { Error } from "@/components/Error";
import { useCustomer } from "@/requests/hooks/customer";
import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  RenderDate,
  RenderExtendTrial,
  RenderPrice,
  RenderStatus,
  RenderTier,
} from "@/components/Table";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Button, Paper, Typography } from "@mui/material";
import { ControlPointTwoTone, InventoryTwoTone } from "@mui/icons-material";
import {
  CustomPricingModal,
  DelinquentModal,
  DiscountModal,
} from "@/components/Modals";
import OverallStatus from "@/components/Customer/OverallStatus";

const Billing = () => {
  const { id } = useParams<keyof ICustomerPageParams>() as ICustomerPageParams;
  const [customPricingModalOpen, setCustomPricingModalOpen] =
    useState<boolean>(false);
  const [discountModalOpen, setDiscountModalOpen] = useState<boolean>(false);
  const [delinquentModalOpen, setDelinquentModalOpen] =
    useState<boolean>(false);

  const {
    data: customerData,
    isLoading: isCustomerLoading,
    error: customerError,
    refetch: refetchCustomer,
  } = useCustomer(id);

  if (isCustomerLoading) return <PageLoader title="billing" />;
  if (customerError || !customerData)
    return <Error error={customerError || "Customer data unavailable."} />;

  const { trials, subscriptions, discounts, pendingTier, tier, preferences } =
    customerData;

  const delinquentStatus = preferences?.find(
    ({ preferenceName }) => preferenceName === "CUSTOMER_IS_DELINQUENT",
  );

  const toggleCustomPricingModal = () =>
    setCustomPricingModalOpen(
      customPricingModalOpen => !customPricingModalOpen,
    );

  const isEnterpriseCustomer =
    pendingTier === "ENTERPRISE" || tier === "ENTERPRISE";

  const toggleDiscountModal = () =>
    setDiscountModalOpen(discountModalOpen => !discountModalOpen);

  const toggleDelinquentModal = () =>
    setDelinquentModalOpen(modalOpen => !modalOpen);

  const columns: GridColDef[] = [
    { field: "product", headerName: "Product", flex: 1 },
    { field: "tier", headerName: "Tier", flex: 1, renderCell: RenderTier },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: RenderStatus,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: RenderDate,
    },
    {
      field: "currentPeriodEndDate",
      headerName: "Current Period End Date",
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: RenderDate,
    },
    {
      field: "endDate",
      headerName: "End Date",
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: RenderDate,
    },
    {
      field: "price",
      headerName: "Price",
      headerAlign: "right",
      align: "right",
      flex: 1,
      minWidth: 175,
      renderCell: RenderPrice,
    },
  ];

  const renderOverallStatus = () => (
    <OverallStatus
      toggleDelinquentModal={toggleDelinquentModal}
      delinquentStatus={delinquentStatus}
    />
  );

  const renderSubscriptionsTable = () => (
    <Box mb={4}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}>
        <Typography variant="h5">Subscriptions</Typography>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<InventoryTwoTone />}
          onClick={toggleCustomPricingModal}>
          {isEnterpriseCustomer ? "Update" : "Switch to"} Custom Pricing
        </Button>
      </Box>
      <Paper elevation={1}>
        <DataGrid
          loading={isCustomerLoading}
          rows={subscriptions || []}
          columns={columns}
          autoHeight
        />
      </Paper>
    </Box>
  );

  const renderCustomPricingModal = () => (
    <CustomPricingModal
      open={customPricingModalOpen}
      onClose={toggleCustomPricingModal}
      customerId={id}
      reloadSubscriptions={refetchCustomer}
      isEnterpriseCustomer={isEnterpriseCustomer}
    />
  );

  const discountColumns: GridColDef[] = [
    { field: "products", headerName: "Product(s)", flex: 1 },
    { field: "note", headerName: "Note", flex: 1 },
    { field: "percentage", headerName: "Discount (%)", flex: 1 },
    {
      field: "maxIntervals",
      headerName: "Discount Term",
      flex: 1,
      renderCell: params => {
        const {
          value,
          row: { interval },
        } = params;

        return (
          <span>
            {value} {interval === "ANNUAL" ? "year(s)" : "month(s)"}
          </span>
        );
      },
    },
    {
      field: "endOnSubscriptionChange",
      headerName: "End on Tier Change",
      flex: 1,
      valueFormatter: ({ value }) => (!!value ? `Yes` : "No"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: RenderStatus,
    },
    { field: "createdBy", headerName: "Created By", flex: 1 },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: RenderDate,
    },
  ];

  const renderDiscountsTable = () => (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}>
        <Typography variant="h5">Discounts</Typography>
        <Button
          variant="contained"
          color="success"
          size="small"
          startIcon={<ControlPointTwoTone />}
          onClick={toggleDiscountModal}>
          Create
        </Button>
      </Box>
      <Paper elevation={1}>
        <DataGrid
          loading={isCustomerLoading}
          rows={discounts || []}
          columns={discountColumns}
          autoHeight
        />
      </Paper>
    </>
  );

  const renderDiscountModal = () => (
    <DiscountModal
      open={discountModalOpen}
      onClose={toggleDiscountModal}
      customerId={id}
      reloadDiscounts={refetchCustomer}
    />
  );
  const renderDelinquentModal = () => (
    <DelinquentModal
      open={delinquentModalOpen}
      onClose={toggleDelinquentModal}
      customerId={id}
      delinquentStatus={delinquentStatus}
    />
  );

  const trialColumns: GridColDef[] = [
    { field: "product", headerName: "Product", flex: 1 },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      renderCell: RenderDate,
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      renderCell: RenderDate,
    },
    {
      field: "cancelled",
      headerName: "Cancelled",
      flex: 1,
      valueFormatter({ value }) {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: params => (
        <RenderExtendTrial params={params} triggerRefresh={refetchCustomer} />
      ),
    },
  ];

  const renderTrialsTable = () => (
    <>
      <Box mt={3}>
        <Typography variant="h5" mb={1}>
          Trials
        </Typography>
        <Paper elevation={1}>
          <DataGrid
            loading={isCustomerLoading}
            rows={trials || []}
            columns={trialColumns}
            autoHeight
          />
        </Paper>
      </Box>
    </>
  );

  return (
    <>
      {renderOverallStatus()}
      {renderSubscriptionsTable()}
      {renderCustomPricingModal()}
      {renderDiscountsTable()}
      {renderDiscountModal()}
      {renderDelinquentModal()}
      {renderTrialsTable()}
    </>
  );
};

export default Billing;
