import { getCountries, getRandomDadJoke } from "@/requests/api/app";
import { useQuery } from "@tanstack/react-query";

export const useCountries = () =>
  useQuery({
    queryKey: ["app", "supported-countries"],
    queryFn: ({ signal }) => getCountries({ signal }),
  });

export const useRandomDadJoke = () =>
  useQuery({
    queryKey: ["app", "dad-joke"],
    queryFn: getRandomDadJoke,
  });
