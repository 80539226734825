import { EditTwoTone } from "@mui/icons-material";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { UpdateMarketableRateModal } from "@/components/Modals";
import { StatTile } from "@/components/Tiles";
import dayjs from "dayjs";

const StatisticsPanel = ({
  numUsers,
  numContacts,
  numContactsLast30Days,
  acceptanceRate,
  acceptanceRateLast30Days,
  refetchCustomer,
  marketableRateInfo,
}: {
  numUsers: number;
  numContacts: number;
  numContactsLast30Days: number;
  acceptanceRate: number;
  acceptanceRateLast30Days: number;
  marketableRateInfo: IPreDatashipsMarketableRate | null;
  refetchCustomer: () => void;
}) => {
  const [showMarketableRateModal, setShowMarketableRateModal] =
    useState<boolean>(false);
  const roundNumber = (number: number) => number.toFixed(2);

  const toggleRenderUpdateMarketableRateModal = () => {
    setShowMarketableRateModal(showModal => !showModal);
  };

  const renderMarketableRateInfo = () => {
    const displayMarketableRate = !marketableRateInfo
      ? "Pending"
      : !!marketableRateInfo?.manualPreDatashipsMarketableRate
      ? `${marketableRateInfo?.manualPreDatashipsMarketableRate}%`
      : `${marketableRateInfo?.preDatashipsMarketableRate}%`;

    return (
      <Box
        display="flex"
        alignItems="end"
        justifyContent="space-between"
        width="100%">
        <Typography variant="body2" fontWeight={500} color="gray">
          <Tooltip
            title={
              !marketableRateInfo
                ? "Please wait while we generate the Pre-Dataships Marketable Rate"
                : !!marketableRateInfo?.rationale
                ? marketableRateInfo?.rationale
                : ""
            }
            componentsProps={{
              tooltip: {
                sx: {
                  textAlign: "center",
                  ...(!marketableRateInfo?.rationale && {
                    display: "none",
                  }),
                },
              },
            }}>
            <span>
              <b>
                Pre-Dataships {!!marketableRateInfo?.rationale && "(Manual)"}:
              </b>{" "}
              {displayMarketableRate}
            </span>
          </Tooltip>
        </Typography>
        {displayMarketableRate !== "Pending" && <EditTwoTone color="info" />}
      </Box>
    );
  };

  const renderUsersTile = () => (
    <Grid item sm={12} md={6} lg={4} key="Users">
      <StatTile
        stat={{
          title: "Users",
          value: numUsers.toString(),
          format: "INTEGER",
          description: "The number of users for this customer.",
          metricType: "",
          sortOrder: 0,
          drillDown: false,
          lastUpdatedAt: dayjs().format("YYYY-MM-DD HH:mm"),
          timeFrame: "All-time",
        }}
      />
    </Grid>
  );

  const renderVerifiedContactsTile = () => (
    <Grid item sm={12} md={6} lg={4} key="VerifiedContacts">
      <StatTile
        stat={{
          title: "Verified Contacts",
          value: numContacts.toString(),
          format: "INTEGER",
          description:
            "The number of Verified Contacts obtained by this customer.",
          metricType: "",
          sortOrder: 0,
          drillDown: false,
          lastUpdatedAt: dayjs().format("YYYY-MM-DD HH:mm"),
          timeFrame: "All-time",
        }}
        trendingValue={numContactsLast30Days}
        trendingTimeFrame="last 30 days"
      />
    </Grid>
  );

  const renderMarketableRateTile = () => (
    <Grid item sm={12} md={6} lg={4} key="MarketableRate">
      <StatTile
        stat={{
          title: "Marketable Rate",
          value: acceptanceRate.toString(),
          format: "PERCENTAGE",
          description: "The customers current Marketing Consent Rate.",
          metricType: "",
          sortOrder: 0,
          drillDown: false,
          lastUpdatedAt: "All-time",
        }}
        trendingValue={`${roundNumber(acceptanceRateLast30Days)}%`}
        trendingTimeFrame="last 30 days"
        onClickOverride={
          !!marketableRateInfo
            ? toggleRenderUpdateMarketableRateModal
            : undefined
        }
        timeFrameOverride={renderMarketableRateInfo()}
      />
    </Grid>
  );

  const renderUpdateMarketableRateModal = () => (
    <UpdateMarketableRateModal
      open={showMarketableRateModal}
      onClose={toggleRenderUpdateMarketableRateModal}
      refetchCustomer={refetchCustomer}
      marketableRateInfo={marketableRateInfo}
    />
  );

  return (
    <>
      <Grid container spacing={3} direction="row">
        {renderUsersTile()}
        {renderVerifiedContactsTile()}
        {renderMarketableRateTile()}
      </Grid>
      {renderUpdateMarketableRateModal()}
    </>
  );
};

export default StatisticsPanel;
