import {
  HelpCenterTwoTone,
  StorefrontTwoTone,
  ChecklistTwoTone,
  ListAltTwoTone,
  DescriptionTwoTone,
  PlaylistAddTwoTone,
  CommentsDisabledTwoTone,
  AssignmentTurnedInTwoTone,
} from "@mui/icons-material";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export interface IOnboardingStep {
  label: string;
  url: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  identifier:
    | "questionnaire"
    | "companyDetails"
    | "complianceChecks"
    | "klaviyoLists"
    | "shopifyPolicies"
    | "shopifyExtension"
    | "shopifySms"
    | "complete";
}

const steps: IOnboardingStep[] = [
  {
    label: "Questionnaire",
    url: "questionnaire",
    icon: HelpCenterTwoTone,
    identifier: "questionnaire",
  },
  {
    label: "Company Details",
    url: "company-details",
    icon: StorefrontTwoTone,
    identifier: "companyDetails",
  },
  {
    label: "Compliance Checks",
    url: "compliance-checks",
    icon: ChecklistTwoTone,
    identifier: "complianceChecks",
  },
  {
    label: "Create Klaviyo Lists",
    url: "create-klaviyo-lists",
    icon: ListAltTwoTone,
    identifier: "klaviyoLists",
  },
  {
    label: "Edit Policies",
    url: "edit-policies",
    icon: DescriptionTwoTone,
    identifier: "shopifyPolicies",
  },
  {
    label: "Add Extension",
    url: "add-extension",
    icon: PlaylistAddTwoTone,
    identifier: "shopifyExtension",
  },
  {
    label: "Disable Shopify SMS",
    url: "disable-shopify-sms",
    icon: CommentsDisabledTwoTone,
    identifier: "shopifySms",
  },
  {
    label: "Complete Onboarding",
    url: "complete",
    icon: AssignmentTurnedInTwoTone,
    identifier: "complete",
  },
];

export default steps;
