import { PageLoader } from "@/components/Loaders";
import { Error } from "@/components/Error";
import { useCustomer, useCustomerPersonas } from "@/requests/hooks/customer";
import { Box, Typography, Paper, Divider } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { UserRoles } from "@/components/Forms";

interface ICustomerUserPageParams {
  id: string;
  userId: string;
}

const User = () => {
  const { id, userId } = useParams<
    keyof ICustomerUserPageParams
  >() as ICustomerUserPageParams;

  const [selectedPersonas, setSelectedPersonas] = useState<string[]>([]);

  const {
    data: customerData,
    isLoading: isCustomerLoading,
    error: customerError,
    refetch: refetchCustomer,
  } = useCustomer(id);

  const {
    data: personasData,
    isLoading: isPersonasLoading,
    error: personasError,
  } = useCustomerPersonas();

  if (isCustomerLoading || isPersonasLoading)
    return <PageLoader title="user" />;
  if (customerError || personasError || !customerData)
    return (
      <Error
        error={customerError || personasError || "Customer data unavailable."}
      />
    );

  const { users } = customerData;
  const user = users.find((user: IUser) => user.userId === parseInt(userId));

  if (!user) return <Error error={"User doesn't exist."} />;

  const { email, firstName, lastName } = user;

  const renderUserHeading = () => (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">
        {firstName} {lastName}
      </Typography>
      <Typography variant="h5" color="text.secondary">
        {email}
      </Typography>
    </Box>
  );

  return (
    <Paper elevation={1} sx={{ p: 3 }}>
      {renderUserHeading()}
      <Divider sx={{ my: 3 }} />
      <UserRoles
        user={user}
        availablePersonas={personasData}
        selectedPersonas={selectedPersonas}
        setSelectedPersonas={setSelectedPersonas}
        refetchCustomer={refetchCustomer}
      />
    </Paper>
  );
};

export default User;
